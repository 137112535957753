import { ReloadOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { SubHeader } from '../../components/sub-header';
import { Tools } from '../../components/tools.components';
import {
    AccountAction,
    ModalAction,
    ListModel,
    Account,
    Pagination,
    Fetch,
    GET_ACCOUNTS,
    UPDATE_ACCOUNT_STATUS,
    State
} from '../../redux';
import { CREATE_ACCOUNT_MODAL, UPDATE_ACCOUNT_MODAL, UPDATE_ACCOUNT_PASSWORD_MODAL } from '../../redux/constants/modal.constants';
import { CreateAccountModal } from './create-account.modal';
import { UpdateAccountModal } from './update-account.modal';
import { PasswordModal } from './password.modal';
import { UpdatePasswordModal } from './update-password.modal';
import { RBAC } from '../../components/rbac.component';
import { Session } from '../../redux/interfaces/session.interface';

export const AccountsContainer = (props) => {
    const dispatch = useDispatch();

    const { results, entities } = useSelector<State, ListModel<Account>>(state => ({
        results: state.account.results,
        entities: state.account.entities,
    }));
    const accounts = results.map(id => entities[id]);
    const { pagination } = useSelector<State, { pagination: Pagination }>(state => ({
        pagination: state.pagination.account ?? {} as any,
    }));
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_ACCOUNTS] ?? {} as any);
    const { fetching: statusFetching } = useSelector<State, Fetch>(
        state => state.fetch[UPDATE_ACCOUNT_STATUS] ?? {} as any,
    );
    const { role } = useSelector<State, Session>(state => state.session);
    useMount(() => dispatch(AccountAction.getAccounts(pagination.page, pagination.size)));

    const refresh = (page, size) => {
        dispatch(AccountAction.getAccounts(page, size));
    };
    const deleteAccount = (record) => {
        dispatch(AccountAction.deleteAccount(record.id));
    }

    const onPageChange = (page: number, size: number) => refresh(page, size);

    return <div>
        <SubHeader title="账户管理"/>
        <Tools>
            <Button onClick={() => dispatch(ModalAction.open(CREATE_ACCOUNT_MODAL))} type="primary">添加账户</Button>
            <Button type="ghost" icon={<ReloadOutlined/>} onClick={() => refresh(pagination.page, pagination.size)}/>
        </Tools>
        <Table
            rowKey="id"
            loading={fetching}
            dataSource={accounts}
            pagination={{
                total: pagination.total,
                defaultPageSize: pagination.size,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
                current: pagination.page,
                onShowSizeChange: onPageChange,
                onChange: onPageChange,
            }}
        >
            <Table.Column title="账户" key="email" dataIndex="email" render={(text) => text ?? '--'}/>
            <Table.Column title="角色" key="role" render={(record) => record?.role?.name} />
            <Table.Column title="姓名" key="name" dataIndex="name" render={(text) => text ?? '--'}/>
            <Table.Column title="手机号" key="phone" dataIndex="phone" render={(text) => text ?? '--'}/>
            <Table.Column title="公司" key="company" dataIndex="company" render={(text) => text ?? '--'}/>
            <Table.Column title="地址" key="address" dataIndex="address" render={(text) => text ?? '--'}/>
            <Table.Column title="状态" key="status" render={(record) => record.status === 'enable' ? '启用' : '禁用'}/>
            <Table.Column title="操作" key="operation" render={(record) => {
                return <span className="table-operation">
                    <Button type="link" onClick={() => dispatch(ModalAction.open(UPDATE_ACCOUNT_MODAL, record))}>
                        修改
                    </Button>
                    <RBAC roles={['admin']} role={role}>
                        <Button type="link" onClick={() => dispatch(ModalAction.open(UPDATE_ACCOUNT_PASSWORD_MODAL, record))}>修改密码</Button>
                    </RBAC>
                    <Button
                        type="link"
                        onClick={() => dispatch(AccountAction.updateAccountStatus(
                            record.id,
                            record.status === 'enable' ? 'disable' : 'enable',
                        ))}
                        disabled={statusFetching}>
                        {record.status === 'enable' ? '禁用' : '启用'}
                    </Button>
                    <Popconfirm title={`确定删除账户${record.email}吗？`} onConfirm={() => deleteAccount(record)}>
                        <Button type="link" danger>删除</Button>
                    </Popconfirm>
                </span>
            }}/>
        </Table>

        <CreateAccountModal/>
        <UpdateAccountModal/>
        <UpdatePasswordModal />
        <PasswordModal/>
    </div>
}
