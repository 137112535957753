import * as React from 'react';
import { useMount } from "react-use";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Cluster, Fetch, GET_CLUSTERS, ListModel, State } from "../../redux";
import { Button, Table } from "antd";
import { Tools } from "../../components/tools.components";
import { ModalAction } from "../../redux";
import {
    CREATE_CLUSTER_MODAL,
    DELETE_CLUSTER_MODAL,
    UPDATE_CLUSTER_MODAL,
} from "../../redux/constants/modal.constants";
import { ReloadOutlined } from "@ant-design/icons";
import { ClusterAction } from "../../redux/actions/cluster.action";
import { CreateClusterModal } from "./create-cluster.modal";
import { SubHeader } from "../../components/sub-header";
import { UpdateClusterModal } from "./update-cluster.modal";
import { DeleteClusterModal } from "./delete-cluster.modal";

export const ClustersContainer = (props) => {
    const dispatch = useDispatch();
    const { results, entities } = useSelector<State, ListModel<Cluster>>(state => ({
        results: state.cluster.results,
        entities: state.cluster.entities,
    }));
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_CLUSTERS] ?? {} as any);
    const regions = results.map(id => entities[id]);
    const columns = [
        {
            title: '集群名称',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <Link to={`/console/management/clusters/${record.id}`}>{record.name}</Link>
        },
        {
            title: '所属区域',
            dataIndex: 'regions',
            key: 'regions',
            render: (text, record) => {
                return `${record?.region?.platformName} / ${record?.region?.name}`;
            }
        },
        {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'CIDR',
            dataIndex: 'cidr',
            key: 'cidr',
        },
        {
            title: 'Tenant ID',
            dataIndex: 'tenantId',
            key: 'tenantId',
        },
        {
            title: '权重',
            dataIndex: 'weight',
            key: 'weight',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: '版本',
            dataIndex: 'version',
            key: 'version',
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (text, record) => {
                return <>
                    <Button
                        type="link"
                        onClick={() => dispatch(ModalAction.open(UPDATE_CLUSTER_MODAL, record))}>
                        编辑
                    </Button>
                    <Button
                        type="link"
                        onClick={() => dispatch(ClusterAction.updateClusterStatus(
                            record.id,
                            record.status === 'disable' ? 'enable' : 'disable',
                        ))}>
                        {record.status === 'disable' ? '启用' : '禁用'}
                    </Button>
                    <Button
                        danger={true}
                        type="link"
                        onClick={() => dispatch(ModalAction.open(DELETE_CLUSTER_MODAL, record))}>
                        删除
                    </Button>
                </>
            }
        }
    ];
    const refresh = () => dispatch(ClusterAction.getClusters());
    useMount(() => dispatch(ClusterAction.getClusters()));

    return <div>
        <SubHeader title="集群管理"/>
        <Tools>
            <Button type="primary" onClick={() => dispatch(ModalAction.open(CREATE_CLUSTER_MODAL))}>创建集群</Button>
            <Button type="ghost" icon={<ReloadOutlined/>} onClick={() => refresh()}/>
        </Tools>
        <Table
            rowKey={record => record.id as string}
            loading={fetching}
            dataSource={regions} columns={columns}
            pagination={false}/>
        <CreateClusterModal/>
        <UpdateClusterModal/>
        <DeleteClusterModal/>
    </div>
}
