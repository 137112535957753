import * as React from 'react';
import { Route } from "react-router-dom";
import { RegionsContainer } from "./regions.container";
import { ClustersContainer } from "./clusters.container";
import { ClusterContainer } from "./cluster.container";

export const Management = (props) => {
    return <div>
        <Route path="/console/management/regions" exact={true} component={RegionsContainer}/>
        <Route path="/console/management/clusters" exact={true} component={ClustersContainer}/>
        <Route path="/console/management/clusters/:clusterId" exact={true} component={ClusterContainer}/>
    </div>
}
