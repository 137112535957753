import * as React from 'react';
import { useMount } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import {
    Fetch,
    GET_VOUCHERS,
    ListModel,
    Pagination,
    State,
    VoucherAction,
    ModalAction,
} from "../../redux";
import { Button, Radio, Table } from "antd";
import { Tools } from "../../components/tools.components";
import {
    CREATE_VOUCHER_MODAL, DELETE_VOUCHER_MODAL, UPDATE_VOUCHER_MODAL, VERIFYING_VOUCHER_MODAL,
} from "../../redux/constants/modal.constants";
import { ReloadOutlined } from "@ant-design/icons";
import { SubHeader } from "../../components/sub-header";
import { Link } from "react-router-dom";
import { Voucher } from "../../redux/interfaces/voucher.interface";
import { useState } from "react";
import { CreateVoucherModal } from "./create-voucher.modal";
import { DeleteVoucherModal } from "./delete-voucher.modal";
import { Session } from "../../redux/interfaces/session.interface";
import { VerifyingVoucherModal } from "./verifying-voucher.modal";
import { RBAC } from "../../components/rbac.component";
import { prettyDate } from "../../helpers/date.helper";
import { UpdateVoucherModal } from "./update-voucher.modal";

export const VoucherContainer = (props) => {
    const dispatch = useDispatch();
    const { results, entities } = useSelector<State, ListModel<Voucher>>(state => ({
        results: state.voucher.results,
        entities: state.voucher.entities,
    }));
    const { pagination } = useSelector<State, { pagination: Pagination }>(state => ({
        pagination: state.pagination.voucher ?? {} as any,
    }));
    const { accountId, role } = useSelector<State, Session>(state => state.session);
    const [status] = useState();
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_VOUCHERS] ?? {} as any);
    const vouchers = results.map(id => entities[id]);
    const refresh = () => {
        dispatch(VoucherAction.getVouchers(status, pagination.page, pagination.size));
    };
    const onPageChange = (page: number, size: number) => dispatch(VoucherAction.getVouchers(status, page, size));
    useMount(() => dispatch(VoucherAction.getVouchers(status, pagination.page, pagination.size)));

    return <div>
        <SubHeader title="代金券管理">
            <Radio.Group
                style={{ marginBottom: 8 }}
                value={'list'}
                onChange={e => window.location.hash = `#/console/vouchers/${e.target.value}`}>
                <Radio.Button value="list">代金券管理</Radio.Button>
                <Radio.Button value="statistic">代金券统计</Radio.Button>
            </Radio.Group>
        </SubHeader>
        <Tools>
            <RBAC roles={['admin', 'business']} role={role}>
                <Button type="primary" onClick={() => dispatch(ModalAction.open(CREATE_VOUCHER_MODAL))}>
                    创建代金券
                </Button>
            </RBAC>
            <Button type="ghost" icon={<ReloadOutlined/>} onClick={() => refresh()}/>
        </Tools>
        <div style={{ overflowY: 'auto' }}>
            <Table
                rowKey={record => record.id}
                loading={fetching}
                dataSource={vouchers}
                pagination={{
                    total: pagination.total,
                    defaultPageSize: pagination.size,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    current: pagination.page,
                    onChange: onPageChange as any,
                }}>
                <Table.Column<Voucher>
                    title="代金券名称"
                    dataIndex="name"
                    key="name"
                    render={(text, record) => <Link to={`/console/vouchers/list/${record.id}`}>{text}</Link>}/>
                <Table.Column title="状态" dataIndex="status" key="status"/>
                <Table.Column title="创建时间" dataIndex="createdAt" key="createdAt" render={text => prettyDate(text)}/>
                <Table.Column title="审核时间" dataIndex="verifiedAt" key="verifiedAt" render={text => prettyDate(text)}/>
                <Table.Column title="描述" dataIndex="description" key="description"/>
                <Table.Column<Voucher>
                    fixed="right"
                    title="操作"
                    dataIndex="operation"
                    key="operation"
                    render={(text, record) => {
                        return <>
                            <RBAC roles={['admin', 'business']} role={role}>
                                {accountId === record.author ? <>
                                    <Button
                                        disabled={!['disable', 'refused'].includes(record.status)}
                                        type="link"
                                        onClick={() => dispatch(ModalAction.open(UPDATE_VOUCHER_MODAL, record))}>
                                        编辑
                                    </Button>
                                    <Button
                                        disabled={!['disable', 'refused'].includes(record.status)}
                                        danger={true}
                                        type="link"
                                        onClick={() => dispatch(ModalAction.open(DELETE_VOUCHER_MODAL, record))}>
                                        删除
                                    </Button>
                                    <Button
                                        disabled={!['disable', 'refused'].includes(record.status)}
                                        danger={true}
                                        type="link"
                                        onClick={() => dispatch(ModalAction.open(VERIFYING_VOUCHER_MODAL, record))}>
                                        申请审核
                                    </Button>
                                </> : ''}
                            </RBAC>
                            <RBAC roles={['sale', 'admin']} role={role}>
                                <Button
                                    disabled={!['enable'].includes(record.status)}
                                    type="link"
                                    onClick={() => dispatch(VoucherAction.createVoucherInstance(record.id))}>
                                    申请代金券
                                </Button>
                            </RBAC>
                            <RBAC roles={['admin']} role={role}>
                                <Button
                                    type="link"
                                    disabled={record.status !== 'verifying'}
                                    onClick={() => dispatch(VoucherAction.verifiedVoucher(record.id))}>
                                    通过
                                </Button>
                                <Button
                                    type="link"
                                    disabled={record.status !== 'verifying'}
                                    onClick={() => dispatch(VoucherAction.refusedVoucher(record.id))}>
                                    拒绝
                                </Button>
                            </RBAC>
                        </>
                    }}/>
            </Table>
        </div>
        <CreateVoucherModal/>
        <UpdateVoucherModal/>
        <DeleteVoucherModal/>
        <VerifyingVoucherModal/>
    </div>
}
