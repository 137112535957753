import * as React from 'react';
import { useMount } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { Fetch, GET_VOUCHER_INSTANCES, ListModel, State, VoucherAction } from "../../redux";
import { Button, Table } from "antd";
import { Tools } from "../../components/tools.components";
import { ReloadOutlined } from "@ant-design/icons";
import { SubHeader } from "../../components/sub-header";
import { CreateVoucherModal } from "./create-voucher.modal";
import { DeleteVoucherModal } from "./delete-voucher.modal";
import { VerifyingVoucherModal } from "./verifying-voucher.modal";
import { VoucherInstance } from "../../redux/interfaces/voucher-instance.interface";
import { prettyDate } from "../../helpers/date.helper";

export const VoucherInstancesContainer = (props) => {
    const dispatch = useDispatch();
    const { results, entities } = useSelector<State, ListModel<VoucherInstance>>(state => ({
        results: state.voucherInstance.results,
        entities: state.voucherInstance.entities,
    }));
    // const { accountId } = useSelector<State, Session>(state => state.session);
    // const [status] = useState();
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_VOUCHER_INSTANCES] ?? {} as any);
    const vouchers = results.map(id => entities[id]);
    const refresh = () => {
        dispatch(VoucherAction.getVoucherInstances());
    };
    useMount(() => dispatch(VoucherAction.getVoucherInstances()));

    return <div>
        <SubHeader title="代金券实例"/>
        <Tools>
            <Button type="ghost" icon={<ReloadOutlined/>} onClick={() => refresh()}/>
        </Tools>
        <div style={{ overflowY: 'auto' }}>
            <Table style={{ minWidth: 960 }} loading={fetching} dataSource={vouchers} pagination={false}>
                <Table.Column title="代金券名称" dataIndex="name" key="name" />
                <Table.Column title="代金券" dataIndex="id" key="id"/>
                <Table.Column<VoucherInstance>
                    title="已领取"
                    dataIndex="active"
                    key="active"
                    render={(text, record) => record.accountId ? '是' : '否'}/>
                <Table.Column title="余额" dataIndex="balance" key="balance"/>
                <Table.Column title="额度" dataIndex="amount" key="amount"/>
                <Table.Column title="创建时间" dataIndex="createdAt" key="createdAt" render={text => prettyDate(text)}/>
                <Table.Column<VoucherInstance>
                    title="有效时间"
                    dataIndex="time"
                    key="time"
                    render={(text, record) => (`${prettyDate(record.startTime)} - ${prettyDate(record.endTime)}`)}/>
                <Table.Column title="描述" dataIndex="remark" key="remark"/>
                <Table.Column title="状态" dataIndex="status" key="status"/>
            </Table>
        </div>
        <CreateVoucherModal/>
        <DeleteVoucherModal/>
        <VerifyingVoucherModal/>
    </div>
}
