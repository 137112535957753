import { Client } from "../client";
import { Action } from "../action";
import { ACCOUNT, TICKET } from "../constants/action-types.constants";
import {
    CREATE_RESOLVER,
    CREATE_RESOLVER_GROUP,
    CREATE_TEMPLATE,
    CREATE_TICKET_CATEGORY, DELETE_RESOLVER,
    DELETE_RESOLVER_GROUP,
    DELETE_TEMPLATE,
    DELETE_TICKET_CATEGORY, DISABLE_RESOLVER,
    DISABLE_TEMPLATE, ENABLE_RESOLVER,
    ENABLE_TEMPLATE, GET_ACCOUNTS,
    GET_RESOLVER_GROUPS,
    GET_RESOLVERS, GET_TICKET,
    GET_TICKET_CATEGORIES,
    GET_TICKET_TEMPLATES, GET_TICKETS, REPLY_TICKET, UPDATE_TEMPLATE,
    GET_CURRENT_RESOLVERS,
} from "../constants/fetch.constants";
import { ModalAction } from "./modal.action";
import {
    CREATE_RESOLVER_GROUP_MODAL, CREATE_RESOLVER_MODAL, CREATE_TEMPLATE_MODAL,
    CREATE_TICKET_CATEGORY_MODAL, DELETE_RESOLVER_GROUP_MODAL, DELETE_RESOLVER_MODAL, DELETE_TEMPLATE_MODAL,
    DELETE_TICKET_CATEGORY_MODAL, UPDATE_TEMPLATE_MODAL
} from "../constants/modal.constants";
import { State } from "../interfaces";
import { PaginationAction } from "./pagination.action";

export class TicketAction {
    private static client = new Client();

    public static getGroups() {
        return async (dispatch, state) => {
            const { groups } = await this.client.get('/api/resolver-groups', {
                fetch: GET_RESOLVER_GROUPS,
                dispatch,
            });

            dispatch(new Action(TICKET).put('groups', groups));
        }
    }

    public static createGroup(name: string) {
        return async (dispatch, state) => {
            await this.client.post('/api/resolver-groups', { name }, {
                fetch: CREATE_RESOLVER_GROUP,
                showMessage: false,
                dispatch,
            });

            dispatch(ModalAction.close(CREATE_RESOLVER_GROUP_MODAL));
            dispatch(this.getGroups());
        }
    }

    public static deleteGroup(groupId: string) {
        return async (dispatch, state) => {
            await this.client.delete(`/api/resolver-groups/${groupId}`, {
                fetch: DELETE_RESOLVER_GROUP,
                showMessage: false,
                dispatch,
            });

            dispatch(ModalAction.close(DELETE_RESOLVER_GROUP_MODAL));
            dispatch(this.getGroups());
        }
    }

    public static createResolver(groupId: string, account: string) {
        return async (dispatch, state) => {
            await this.client.post('/api/resolvers', { groupId, account }, {
                fetch: CREATE_RESOLVER,
                dispatch,
                showMessage: false,
            });

            dispatch(ModalAction.close(CREATE_RESOLVER_MODAL));
            dispatch(this.getResolvers(groupId));
        }
    }

    public static getResolvers(groupId?: string) {
        return async (dispatch, state) => {
            const { resolvers } = await this.client.get('/api/resolvers', {
                fetch: GET_RESOLVERS,
                params: { groupId },
                dispatch,
            });

            dispatch(new Action(TICKET).put('resolvers', resolvers));
        }
    }

    public static getCurrentResolvers() {
        return async (dispatch, state) => {
            const { resolvers } = await this.client.get('/api/current-resolvers', {
                fetch: GET_CURRENT_RESOLVERS,
                dispatch,
            });
            dispatch(new Action(TICKET).put('currentResolvers', resolvers));
        }
    }

    public static enableResolver(resolverId: string) {
        return async (dispatch, state: () => State) => {
            await this.client.put(`/api/resolvers/${resolverId}/actions/enable`, null, {
                fetch: ENABLE_RESOLVER,
                dispatch,
            });

            const resolvers = state().ticket.resolvers ?? [];
            resolvers.forEach(resolver => {
                if (resolver.id === resolverId) {
                    resolver.status = 'enable';
                }
            });
            dispatch(new Action(TICKET).put('resolvers', [...resolvers]));
        }
    }

    public static disableResolver(resolverId: string) {
        return async (dispatch, state: () => State) => {
            await this.client.put(`/api/resolvers/${resolverId}/actions/disable`, null, {
                fetch: DISABLE_RESOLVER,
                dispatch,
            });

            const resolvers = state().ticket.resolvers ?? [];
            resolvers.forEach(resolver => {
                if (resolver.id === resolverId) {
                    resolver.status = 'disable';
                }
            });
            dispatch(new Action(TICKET).put('resolvers', [...resolvers]));
        }
    }

    public static deleteResolver(resolverId: string) {
        return async (dispatch, state: () => State) => {
            await this.client.delete(`/api/resolvers/${resolverId}`, {
                fetch: DELETE_RESOLVER,
                dispatch,
            });

            const resolvers = (state().ticket.resolvers ?? []).filter(resolver => resolver.id !== resolverId);
            dispatch(new Action(TICKET).put('resolvers', [...resolvers]));
            dispatch(ModalAction.close(DELETE_RESOLVER_MODAL));
        }
    }

    public static getCategories() {
        return async (dispatch, state) => {
            const { categories } = await this.client.get('/api/ticket-categories', {
                fetch: GET_TICKET_CATEGORIES,
                dispatch,
            });

            dispatch(new Action(TICKET).put('categories', categories));
        }
    }

    public static createCategory(name: string) {
        return async (dispatch, state) => {
            await this.client.post('/api/ticket-categories', { name }, {
                fetch: DELETE_TICKET_CATEGORY,
                showMessage: false,
                dispatch,
            });

            dispatch(ModalAction.close(CREATE_TICKET_CATEGORY_MODAL));
            dispatch(this.getCategories());
        }
    }

    public static deleteCategory(categoryId: string, callback: () => void) {
        return async (dispatch, state) => {
            await this.client.delete(`/api/ticket-categories/${categoryId}`, {
                fetch: CREATE_TICKET_CATEGORY,
                showMessage: false,
                dispatch,
            });

            dispatch(ModalAction.close(DELETE_TICKET_CATEGORY_MODAL));
            dispatch(this.getCategories());
            callback();
        }
    }

    public static getTemplates(categoryId?: string) {
        return async (dispatch, state) => {
            const { templates } = await this.client.get('/api/ticket-templates', {
                fetch: GET_TICKET_TEMPLATES,
                params: { categoryId },
                dispatch,
            });

            dispatch(new Action(TICKET).put('templates', templates));
        }
    }

    public static createTemplate(categoryId: string, data: any) {
        return async (dispatch, state) => {
            await this.client.post('/api/ticket-templates', { categoryId, ...data }, {
                fetch: CREATE_TEMPLATE,
                dispatch,
            });

            dispatch(ModalAction.close(CREATE_TEMPLATE_MODAL));
            dispatch(this.getTemplates(categoryId));
        }
    }

    public static updateTemplate(templateId: string, categoryId: string, data: any) {
        return async (dispatch, state) => {
            await this.client.put(`/api/ticket-templates/${templateId}`, { categoryId, ...data }, {
                fetch: UPDATE_TEMPLATE,
                dispatch,
            });

            dispatch(ModalAction.close(UPDATE_TEMPLATE_MODAL));
            dispatch(this.getTemplates(categoryId));
        }
    }

    public static enableTemplate(templateId: string) {
        return async (dispatch, state: () => State) => {
            await this.client.put(`/api/ticket-templates/${templateId}/actions/enable`, null, {
                fetch: ENABLE_TEMPLATE,
                dispatch,
            });

            const templates = state().ticket.templates ?? [];
            templates.forEach(tpl => {
                if (tpl.id === templateId) {
                    tpl.status = 'enable';
                }
            });
            dispatch(new Action(TICKET).put('templates', [...templates]));
        }
    }

    public static disableTemplate(templateId: string) {
        return async (dispatch, state: () => State) => {
            await this.client.put(`/api/ticket-templates/${templateId}/actions/disable`, null, {
                fetch: DISABLE_TEMPLATE,
                dispatch,
            });

            const templates = state().ticket.templates ?? [];
            templates.forEach(tpl => {
                if (tpl.id === templateId) {
                    tpl.status = 'disable';
                }
            });
            dispatch(new Action(TICKET).put('templates', [...templates]));
        }
    }

    public static deleteTemplate(templateId: string) {
        return async (dispatch, state: () => State) => {
            await this.client.delete(`/api/ticket-templates/${templateId}`, {
                fetch: DELETE_TEMPLATE,
                dispatch,
            });

            const templates = (state().ticket.templates ?? []).filter(tpl => tpl.id !== templateId);
            dispatch(new Action(TICKET).put('templates', [...templates]));
            dispatch(ModalAction.close(DELETE_TEMPLATE_MODAL));
        }
    }

    public static getTickets(query: {
        templateId?: string,
        status?: string,
        page?: number,
        size?: number,
        resolver?: string,
    }) {
        return async (dispatch, state: () => State) => {
            const { list, page, size, total } = await this.client.get(`/api/tickets`, {
                fetch: GET_TICKETS,
                params: query,
                dispatch,
            });
            dispatch(PaginationAction.update('ticket', page, size, total, list.length));
            dispatch(new Action(TICKET).put('tickets', list));
        }
    }

    public static getProcessingTickets(query: { status?: string, page?: number, size?: number, orderKey?: string, order?: string }) {
        return async (dispatch, state: () => State) => {
            const { list, page, size, total } = await this.client.get(`/api/processing-tickets`, {
                fetch: GET_TICKETS,
                params: query,
                dispatch,
            });
            dispatch(PaginationAction.update('ticket', page, size, total, list.length));
            dispatch(new Action(TICKET).put('tickets', list));
        }
    }

    public static getTicket(ticketId: string) {
        return async (dispatch, state: () => State) => {
            const { ticket } = await this.client.get(`/api/tickets/${ticketId}`, {
                fetch: GET_TICKET,
                dispatch,
            });
            dispatch(new Action(TICKET).put('ticket', ticket));
        }
    }

    public static refreshTicketReplies(e: any) {
        return async (dispatch, state: () => State) => {
            const ticket = state().ticket.ticket;
            if (!ticket || !ticket.replies) {
                return;
            }
            const replies = ticket.replies;
            const reply = e.params ?? {};
            if (window.location.hash !== `#/console/ticket/tickets/${reply?.ticket?.id}`) {
                return;
            }

            replies.push({
                id: reply.id,
                resolverId: reply.resolverId,
                createdAt: reply.createdAt,
                content: reply.content,
            });
            dispatch(new Action(TICKET).put('ticket', { ...ticket }));
            window.scrollTo(0, 99999);
        };
    }

    public static replyTicket(ticketId: string, resolverId: string, content: string, status: string, fn: Function) {
        return async (dispatch, state: () => State) => {
            await this.client.put(
                `/api/tickets/${ticketId}/actions/reply`,
                { status, content, resolverId },
                {
                    fetch: REPLY_TICKET,
                    dispatch,
                },
            );
            fn.call(null);
            dispatch(this.getTicket(ticketId));
        }
    }
}
