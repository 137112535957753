import { Client } from "../client";
import { Dispatch } from "redux";
import {
    Action,
    CREATE_VOUCHER, CREATE_VOUCHER_INSTANCE, DELETE_VOUCHER,
    GET_RULES,
    GET_VOUCHER, GET_VOUCHER_INSTANCES,
    GET_VOUCHERS,
    ModalAction,
    PaginationAction,
    SET_VOUCHER_RULES, UPDATE_VOUCHER, VERIFYING_VOUCHER
} from "..";
import { BILLING_ITEM, RULE, VOUCHER, VOUCHER_INSTANCE } from "../constants/action-types.constants";
import { getPagination } from "../helpers/pagination.helper";
import { CREATE_VOUCHER_MODAL, DELETE_VOUCHER_MODAL, UPDATE_VOUCHER_MODAL, VERIFYING_VOUCHER_MODAL } from "../constants/modal.constants";

export class VoucherAction {
    private static client = new Client();

    public static getVouchers(status: string, pages: number, sizes: number) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const pagination = getPagination(state(), 'voucher', pages, sizes);
            const { page, size, total, list } = await this.client.get('/api/vouchers', {
                params: {
                    status,
                    page: pagination.page,
                    size: pagination.size,
                },
                dispatch,
                fetch: GET_VOUCHERS,
            });
            dispatch(PaginationAction.update('voucher', page, size, total, list.length));
            dispatch(new Action(VOUCHER).init(list));
        }
    }

    public static createVoucher(name: string, description?: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.post(
                '/api/vouchers',
                { name, description },
                {
                    dispatch,
                    fetch: CREATE_VOUCHER,
                },
            );
            const { page, size } = state().pagination.voucher;
            dispatch(this.getVouchers(undefined, page, size));
            dispatch(ModalAction.close(CREATE_VOUCHER_MODAL));
        }
    }

    public static getVoucher(voucherId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { voucher } = await this.client.get(
                `/api/vouchers/${voucherId}`,
                {
                    dispatch,
                    fetch: GET_VOUCHER,
                },
            );
            dispatch(new Action(VOUCHER).put(voucherId, voucher));
        }
    }

    public static getRules() {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { rules } = await this.client.get(
                `/api/rules`,
                {
                    dispatch,
                    fetch: GET_RULES,
                },
            );
            dispatch(new Action(RULE).init(rules));
        }
    }

    public static setVoucherRules(voucherId: string, rules: any) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.post(
                `/api/vouchers/${voucherId}/rules`,
                { rules },
                {
                    dispatch,
                    fetch: SET_VOUCHER_RULES,
                },
            );
            dispatch(this.getVoucher(voucherId));
        }
    }

    public static deleteVoucher(voucherId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.delete(`/api/vouchers/${voucherId}`, {
                dispatch,
                fetch: DELETE_VOUCHER,
            });
            const { page, size } = state().pagination.voucher;
            dispatch(this.getVouchers(undefined, page, size));
            dispatch(ModalAction.close(DELETE_VOUCHER_MODAL))
        }
    }

    public static verifyingVoucher(voucherId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(`/api/vouchers/${voucherId}/actions/verifying`, null, {
                dispatch,
                fetch: VERIFYING_VOUCHER,
            });
            dispatch(new Action(VOUCHER).update(voucherId, 'status', 'verifying'));
            dispatch(ModalAction.close(VERIFYING_VOUCHER_MODAL));
        }
    }

    public static verifiedVoucher(voucherId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(`/api/vouchers/${voucherId}/actions/verified`, null, {
                dispatch,
                fetch: VERIFYING_VOUCHER,
            });
            dispatch(new Action(VOUCHER).update(voucherId, 'status', 'enable'));
        }
    }

    public static refusedVoucher(voucherId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(`/api/vouchers/${voucherId}/actions/refused`, null, {
                dispatch,
                fetch: VERIFYING_VOUCHER,
            });
            dispatch(new Action(VOUCHER).update(voucherId, 'status', 'refused'));
        }
    }

    public static createVoucherInstance(voucherId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.post(`/api/vouchers/${voucherId}/instances`, null, {
                dispatch,
                fetch: CREATE_VOUCHER_INSTANCE,
            });
            window.location.hash = '/console/voucher-instances';
        }
    }

    public static getVoucherInstances() {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { list } = await this.client.get(`/api/voucher-instances`, {
                params: {
                    size: 1000000000,
                },
                dispatch,
                fetch: GET_VOUCHER_INSTANCES,
            });
            dispatch(new Action(VOUCHER_INSTANCE).init(list));
        }
    }

    public static updateVoucher(voucherId: string, name: string, description?: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(`/api/vouchers/${voucherId}`, { name, description }, {
                dispatch,
                fetch: UPDATE_VOUCHER,
            });
            dispatch(new Action(VOUCHER).update(voucherId, 'name', name));
            if (description) {
                dispatch(new Action(VOUCHER).update(voucherId, 'description', description));
            }
            dispatch(ModalAction.close(UPDATE_VOUCHER_MODAL));
        }
    }
}
