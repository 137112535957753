import { EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { SubHeader } from '../../components/sub-header';
import { Tools } from '../../components/tools.components';
import { Fetch, GET_NOTIFICATION_TEMPLATES, ModalAction, NotificationAction, State } from '../../redux';
import { prettyDate } from "../../helpers/date.helper";
import {
    SEND_TEST_EMAIL_MODAL,
    SEND_TEST_SMS_MODAL,
    UPDATE_EMAIL_NOTIFY_TPL_MODAL,
    UPDATE_MESSAGE_NOTIFY_TPL_MODAL,
    UPDATE_NOTIFY_METHOD_MODAL,
    UPDATE_SMS_NOTIFY_TPL_MODAL
} from "../../redux/constants/modal.constants";
import { TestEmailModal } from "./test-email.modal";
import { TestSmsModal } from "./test-sms.modal";
import { UpdateEmailTemplateModal } from "./update-email-template.modal";
import { UpdateMessageTemplateModal } from "./update-message-template.modal";
import { UpdateSmsTemplateModal } from "./update-sms-template.modal";
import { UpdateNotifyMethodModal } from "./update-notify-method.modal";
import { UpdateStyleModal } from "./update-style.modal";

export const TemplatesContainer = (props) => {
    const dispatch = useDispatch();

    const templates = useSelector<State, any[]>(
        state => state.notification.templates ?? [],
    );
    const { fetching } = useSelector<State, Fetch>(state =>
        state.fetch[GET_NOTIFICATION_TEMPLATES] ?? {} as any,
    );

    useMount(() => dispatch(NotificationAction.getTemplates()));

    const refresh = () => dispatch(NotificationAction.getTemplates());

    return <div>
        <SubHeader title="模板管理"/>
        <Tools>
            <Button
                type="ghost"
                icon={<ReloadOutlined/>}
                onClick={() => refresh()}
            />
        </Tools>
        <Table
            loading={fetching}
            dataSource={templates}
            pagination={false}>
            <Table.Column title="ID" dataIndex="id" key="id"/>
            <Table.Column<any> title="邮件" render={record => <div>
                {record.emailTitle}
                <Button
                    icon={<EditOutlined/>}
                    type="link"
                    onClick={() => dispatch(ModalAction.open(UPDATE_EMAIL_NOTIFY_TPL_MODAL, record))}
                />
            </div>}/>
            <Table.Column<any> title="短信" render={record => <div>
                {record.smsTemplate}
                <Button
                    icon={<EditOutlined/>}
                    type="link"
                    onClick={() => dispatch(ModalAction.open(UPDATE_SMS_NOTIFY_TPL_MODAL, record))}
                />
            </div>}/>
            <Table.Column<any> title="消息" render={record => <div>
                {record.messageTitle}
                <Button
                    icon={<EditOutlined/>}
                    type="link"
                    onClick={() => dispatch(ModalAction.open(UPDATE_MESSAGE_NOTIFY_TPL_MODAL, record))}
                />
            </div>}/>
            <Table.Column title="状态" dataIndex="status" key="status"/>
            <Table.Column title="通知方式" render={record => {
                const methods = (record.notifyTypes ?? '').split(',');
                return <div>
                    {methods.map(method => {
                        switch (method) {
                            case 'email':
                                return <span style={{ marginRight: 4 }}>邮件</span>;
                            case 'sms':
                                return <span style={{ marginRight: 4 }}>短信</span>;
                            case 'message':
                                return <span style={{ marginRight: 4 }}>消息</span>;
                        }
                    })}
                    <Button
                        icon={<EditOutlined/>}
                        type="link"
                        onClick={() => dispatch(ModalAction.open(UPDATE_NOTIFY_METHOD_MODAL, record))}
                    />
                </div>
            }}/>
            <Table.Column
                title="创建时间"
                dataIndex="createdAt"
                key="createdAt"
                render={(text) => prettyDate(text)}
            />
            <Table.Column title="操作" key="operation" render={(record) => {
                return <>
                    <Button
                        type="link"
                        onClick={() => dispatch(record.status === 'enable' ?
                            NotificationAction.disableTemplate(record.id) :
                            NotificationAction.enableTemplate(record.id)
                        )}>
                        {record.status === 'enable' ? '禁用' : '启用'}
                    </Button>
                    <Button
                        type="link"
                        onClick={() => dispatch(ModalAction.open(SEND_TEST_EMAIL_MODAL, record))}>
                        测试邮件
                    </Button>
                    <Button
                        type="link"
                        onClick={() => dispatch(ModalAction.open(SEND_TEST_SMS_MODAL, record))}>
                        测试短信
                    </Button>
                </>
            }}/>
        </Table>
        <UpdateSmsTemplateModal/>
        <UpdateEmailTemplateModal/>
        <UpdateMessageTemplateModal/>
        <TestEmailModal/>
        <TestSmsModal/>
        <UpdateNotifyMethodModal/>
        <UpdateStyleModal/>
    </div>;
}
