import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Fetch, State, BillAction, GET_BILLS } from "../../redux";
import { Button, DatePicker, Input, Select, Table } from "antd";
import { Tools } from "../../components/tools.components";
import { ReloadOutlined } from "@ant-design/icons";
import { SubHeader } from "../../components/sub-header";
import { useEffect, useState } from "react";
import moment from 'moment';
import { BillingMode } from "../../enums/billing-mode.enum";

export const BillsContainer = (props) => {
    const dispatch = useDispatch();
    const bills = useSelector<State, any[]>(state => state.bill.bills);
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_BILLS] ?? {} as any);
    const [billingCycle, updateBillingCycle] = useState(moment().format('YYYY-MM'));
    const [item, updateItem] = useState('billingItem');
    const [type, updateType] = useState('hour');
    const [instanceId, updateInstanceId] = useState<string>();
    const [billId, updateBillId] = useState<string>();
    const [accountId, updateAccountId] = useState<string>();

    useEffect(() => {
        dispatch(BillAction.getBills({ billingCycle, item, type, instanceId, billId, accountId }));
    }, [billingCycle, item, type, instanceId, billId, accountId]);

    useEffect(() => {
        if (['product', 'account'].includes(item)) {
            updateInstanceId(undefined);
        }
        if (item !== 'billingItem') {
            updateBillId(undefined);
        }
    }, [item]);

    useEffect(() => {
        if (type !== 'hour') {
            updateBillId(undefined);
        }
    }, [type]);

    const refresh = () => dispatch(BillAction.getBills({
        billingCycle,
        item,
        type,
        instanceId,
        billId,
        accountId,
    }));

    return <div>
        <SubHeader title="账单管理"/>
        <Tools>
            <div>
                <Input.Search
                    defaultValue={accountId}
                    onSearch={val => updateAccountId(val)}
                    placeholder="账户ID"/>
                {type === 'hour' && item === 'billingItem' ?
                    <Input.Search
                        defaultValue={billId}
                        onSearch={val => updateBillId(val)}
                        placeholder="订单号/账单号"/> : ''
                }
                {!['product', 'account'].includes(item) ?
                    <Input.Search
                        defaultValue={instanceId}
                        onSearch={val => updateInstanceId(val)}
                        placeholder="实例ID"/> : ''
                }
            </div>
            <DatePicker
                value={moment(billingCycle)}
                onChange={val => updateBillingCycle(val.format('YYYY-MM'))} picker="month"/>
            <Select value={type} onChange={val => updateType(val)}>
                <Select.Option value="day">按天</Select.Option>
                <Select.Option value="month">账期</Select.Option>
                <Select.Option disabled={['product', 'account'].includes(item)} value="hour">明细</Select.Option>
            </Select>
            <Select value={item} onChange={val => updateItem(val)}>
                <Select.Option value="billingItem">计费项</Select.Option>
                <Select.Option value="instance">实例</Select.Option>
                <Select.Option disabled={type === 'hour'} value="product">产品</Select.Option>
                <Select.Option disabled={type === 'hour'} value="account">账号</Select.Option>
            </Select>
            <Button type="ghost" icon={<ReloadOutlined/>} onClick={() => refresh()}/>
        </Tools>
        <Table loading={fetching} dataSource={bills} scroll={{ x: 2800 }} rowKey={(record, index) => index}>
            <Table.Column title="账期" dataIndex="billingCycle" key="billingCycle"/>
            <Table.Column title="账号" dataIndex="email" key="email"/>
            {type === 'day' ? <Table.Column title="日期" dataIndex="day" key="day"/> : ''}
            {item !== 'account' ? <Table.Column<any>
                title="产品"
                dataIndex="productType"
                key="productType"
                render={(text, record) => record?.product?.description ?? text}/> : ''
            }
            {item !== 'account' ? <Table.Column<any>
                title="消费类型"
                dataIndex="billingMode"
                onFilter={(value, record) => record.billingMode.includes(value)}
                filters={[{ text: '后付费', value: 'afterpay' }, { text: '预付费', value: 'prepay' }]}
                key="billingMode"
                render={text => BillingMode[text]}/> : ''
            }
            {type === 'hour' ? <Table.Column<any>
                title="消费时间"
                dataIndex="time"
                key="time"
                render={(text, record) => {
                    if (record.billingMode === 'prepay') {
                        return moment(record.consumeTime).format('YYYY-MM-DD HH:mm');
                    }
                    return moment(record.startTime).format('YYYY-MM-DD HH:mm') +
                        ' - ' +
                        moment(record.endTime).format('YYYY-MM-DD HH:mm');
                }}/> : ''
            }
            {type === 'hour' && item === 'billingItem' ? <Table.Column
                title="订单号/账单号"
                dataIndex="billId"
                key="billId"/> : ''
            }
            {!['account', 'product'].includes(item) ? <Table.Column
                title="实例ID"
                dataIndex="instanceId"
                key="instanceId"/> : ''
            }
            {!['account', 'product'].includes(item) ? <Table.Column<any>
                title="区域"
                dataIndex="regionId"
                key="regionId"
                render={(text, record) => `${record?.region?.platformName}/${record?.region?.name}`}/> : ''
            }
            {item === 'billingItem' ? <Table.Column<any>
                title="计费项"
                dataIndex="billingItemType"
                key="billingItemType"
                render={(text, record) => record?.billingItemObj?.description ?? record.billingItemType}/> : ''
            }
            {type === 'hour' && item === 'billingItem' ? <Table.Column title="单价" dataIndex="price" key="price"/> : ''}
            {type === 'hour' && item === 'billingItem' ? <Table.Column title="单价单位" dataIndex="unit" key="unit"/> : ''}
            {type === 'hour' && item === 'billingItem' ? <Table.Column title="用量" dataIndex="usage" key="usage"/> : ''}
            <Table.Column title="原价" dataIndex="originalAmount" key="originalAmount"/>
            <Table.Column title="优惠金额" dataIndex="discountAmount" key="discountAmount"/>
            <Table.Column title="抹零金额" dataIndex="roundAmount" key="roundAmount"/>
            <Table.Column title="应付金额" dataIndex="amountDue" key="amountDue"/>
            <Table.Column title="现金支付" dataIndex="paymentAmount" key="paymentAmount"/>
            <Table.Column title="代金券抵扣" dataIndex="voucherAmount" key="voucherAmount"/>
            <Table.Column title="欠费金额" dataIndex="outstandingAmount" key="outstandingAmount"/>
        </Table>
    </div>
}
