import { Dispatch } from "redux";
import {
    Action, CREATE_BILLING_ITEM,
    CREATE_PRODUCT,
    DELETE_PRODUCT,
    GET_BILLING_ITEMS,
    GET_PRODUCT,
    GET_PRODUCTS,
    ModalAction, PaginationAction, State,
    UPDATE_PRODUCT
} from "..";
import { BILLING_ITEM, PRODUCT, SUPPORT } from "../constants/action-types.constants";
import { Client } from "../client";
import {
    CREATE_BILLING_ITEM_MODAL,
    CREATE_PRODUCT_MODAL,
    DELETE_PRODUCT_MODAL,
    UPDATE_PRODUCT_MODAL
} from "../constants/modal.constants";
import { getPagination } from "../helpers/pagination.helper";

export class ProductAction {
    private static client = new Client();

    public static getProducts() {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { products } = await this.client.get('/api/products', {
                dispatch,
                fetch: GET_PRODUCTS,
            });
            dispatch(new Action(PRODUCT).init(products));
        }
    }

    public static getSupportProducts() {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { supports } = await this.client.get('/api/support-products', {
                dispatch,
                fetch: GET_PRODUCTS,
            });
            dispatch(new Action(SUPPORT).put('products', supports));
        }
    }

    public static getSupportBillingItems(key: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { supports } = await this.client.get('/api/support-products', {
                params: { key },
                dispatch,
                fetch: GET_PRODUCTS,
            });
            dispatch(new Action(SUPPORT).put('billingItems', supports));
        }
    }

    public static getProduct(productId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { product } = await this.client.get(`/api/products/${productId}`, {
                dispatch,
                fetch: GET_PRODUCT,
            });
            dispatch(new Action(PRODUCT).put(product.id, product));
        }
    }

    public static createProduct(name: string, type: string, description?: string, charges?: number) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.post(
                '/api/products',
                { name, type, description, charges },
                {
                    dispatch,
                    fetch: CREATE_PRODUCT,
                },
            );
            dispatch(this.getProducts());
            dispatch(ModalAction.close(CREATE_PRODUCT_MODAL));
        }
    }

    public static updateProduct(productId: string, name: string, type: string, description?: string, charges?: number) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(
                `/api/products/${productId}`,
                { name, type, description, charges },
                {
                    dispatch,
                    fetch: UPDATE_PRODUCT,
                },
            );
            dispatch(this.getProducts());
            dispatch(ModalAction.close(UPDATE_PRODUCT_MODAL));
        }
    }

    public static deleteProduct(productId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.delete(`/api/products/${productId}`, {
                dispatch,
                fetch: DELETE_PRODUCT,
            });
            dispatch(this.getProducts());
            dispatch(ModalAction.close(DELETE_PRODUCT_MODAL));
        }
    }

    public static getBillingItems(productId: string, query: {
        regionId?: string,
        billingMode?: string,
        status?: string,
        page?: number,
        size?: number,
    }) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            const pagination = getPagination(state(), 'billingItem', query.page, query.size);
            const { list, total, page, size } = await this.client.get(
                `/api/products/${productId}/billing-items`,
                {
                    params: {
                        page: pagination.page,
                        size: pagination.size,
                        regionId: query.regionId,
                        billingMode: query.billingMode,
                        status: query.status
                    },
                    dispatch,
                    fetch: GET_BILLING_ITEMS,
                },
            );
            dispatch(new Action(BILLING_ITEM).init(list));
            dispatch(PaginationAction.update('billingItem', page, size, total, list.length));
        }
    }

    public static createBillingItem(
        productId: string,
        body: {
            name: string,
            type: string;
            description?: string;
            regionId: string;
            billingMode: string;
            price: number;
            unit: string;
            max: number;
            min: number;
            step: number;
        },
        callback: () => void,
    ) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            await this.client.post(`/api/products/${productId}/billing-items`, body, {
                dispatch,
                fetch: CREATE_BILLING_ITEM,
            });
            callback();
            dispatch(ModalAction.close(CREATE_BILLING_ITEM_MODAL));
        }
    }

    public static updateBillingItemStatus(productId: string, billingItemId: string, status: 'publish' | 'unpublish') {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            await this.client.put(`/api/products/${productId}/billing-items/${billingItemId}/actions/${status}`, null, {
                dispatch,
            });
            dispatch(new Action(BILLING_ITEM).update(billingItemId, 'status', status));
        }
    }

    public static updateBillingItemSoldOut(productId: string, billingItemId: string, soldOut: boolean) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            await this.client.put(`/api/products/${productId}/billing-items/${billingItemId}`, { soldOut }, {
                dispatch,
            });
            dispatch(new Action(BILLING_ITEM).update(billingItemId, 'soldOut', soldOut));
        }
    }

    public static deleteBillingItem(productId: string, billingItemId: string) {
        return async (dispatch: Dispatch<any>, state: () => State) => {
            await this.client.delete(`/api/products/${productId}/billing-items/${billingItemId}`, {
                dispatch,
            });
            dispatch(new Action(BILLING_ITEM).delete(billingItemId));
        }
    }
}
