export const CREATE_REGION_MODAL = 'create_region_modal';
export const UPDATE_REGION_MODAL = 'update_region_modal';
export const DELETE_REGION_MODAL = 'delete_region_modal';

export const CREATE_CLUSTER_MODAL = 'create_cluster_modal';
export const UPDATE_CLUSTER_MODAL = 'update_cluster_modal';
export const DELETE_CLUSTER_MODAL = 'delete_cluster_modal';
export const CREATE_CLUSTER_META_MODAL = 'create_cluster_meta_modal';
export const DELETE_CLUSTER_META_MODAL = 'delete_cluster_meta_modal';

export const CREATE_PRODUCT_MODAL = 'create_product_modal';
export const UPDATE_PRODUCT_MODAL = 'update_product_modal';
export const DELETE_PRODUCT_MODAL = 'delete_product_modal';

export const CREATE_BILLING_ITEM_MODAL = 'create_billing_item_modal';

export const CREATE_VOUCHER_MODAL = 'create_voucher_modal';
export const UPDATE_VOUCHER_MODAL = 'update_voucher_modal';
export const CREATE_VOUCHER_RULE_MODAL = 'create_voucher_rule_modal';
export const DELETE_VOUCHER_MODAL = 'delete_voucher_modal';
export const VERIFYING_VOUCHER_MODAL = 'verifying_voucher_modal';

export const CREATE_ACCOUNT_MODAL = 'create_account_modal';
export const UPDATE_ACCOUNT_MODAL = 'update_account_modal';
export const UPDATE_ACCOUNT_PASSWORD_MODAL = 'update_account_password_modal';
export const PASSWORD_MODAL = 'password_modal';

export const CREATE_TICKET_CATEGORY_MODAL = 'create_ticket_category_modal';
export const DELETE_TICKET_CATEGORY_MODAL = 'delete_ticket_category_modal';
export const CREATE_RESOLVER_GROUP_MODAL = 'create_resolver_group_modal';
export const DELETE_RESOLVER_GROUP_MODAL = 'delete_resolver_group_modal';
export const CREATE_RESOLVER_MODAL = 'create_resolver_modal';
export const DELETE_RESOLVER_MODAL = 'delete_resolver_modal';
export const CREATE_TEMPLATE_MODAL = 'create_template_modal';
export const DELETE_TEMPLATE_MODAL = 'delete_template_modal';
export const UPDATE_TEMPLATE_MODAL = 'update_template_modal';

export const UPDATE_NOTIFICATION_TEMPLATE_MODAL = 'update_notification_template_modal';
export const UPDATE_EMAIL_NOTIFY_TPL_MODAL = 'update_email_notify_tpl_modal';
export const UPDATE_SMS_NOTIFY_TPL_MODAL = 'update_sms_notify_tpl_modal';
export const UPDATE_MESSAGE_NOTIFY_TPL_MODAL = 'update_message_notify_tpl_modal';
export const UPDATE_NOTIFY_METHOD_MODAL = 'update_notify_method_modal';
export const UPDATE_STYLE_MODAL = 'update_style_modal';
export const SEND_TEST_EMAIL_MODAL = 'send_test_email_modal';
export const SEND_TEST_SMS_MODAL = 'send_test_sms_modal';

export const UPDATE_ARN_MODAL = 'update_arn_modal';
export const UPDATE_INSTANCE_MODAL = 'update_instance_modal';
