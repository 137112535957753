import * as React from 'react';
import { Alert, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Fetch, State } from "../../redux/interfaces";
import { VERIFYING_VOUCHER_MODAL } from "../../redux/constants/modal.constants";
import { ModalAction, VoucherAction } from "../../redux/actions";
import { FetchAction } from "../../redux/actions";
import { VERIFYING_VOUCHER } from "../../redux";

export const VerifyingVoucherModal = (props) => {
    const { visible, name, id } = useSelector<State, { [key: string]: any }>(
        state => state.modal[VERIFYING_VOUCHER_MODAL] ?? {},
    );
    const { fetching, error, message } = useSelector<State, Fetch>(
        state => state.fetch[VERIFYING_VOUCHER] ?? {} as any,
    );

    const errorMessage = error ? <Alert message={message} type="error" closable/> : null;
    const dispatch = useDispatch();

    const confirm = () => {
        dispatch(VoucherAction.verifyingVoucher(id));
    }
    return <Modal
        title="申请审核代金券"
        visible={visible}
        onOk={() => confirm()}
        confirmLoading={fetching}
        width={460}
        onCancel={() => {
            dispatch(ModalAction.close(VERIFYING_VOUCHER_MODAL));
            dispatch(FetchAction.clear(VERIFYING_VOUCHER));
        }}>
        {errorMessage}
        确认申请审核代金券 {name}?
    </Modal>;
}
