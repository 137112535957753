import * as React from 'react';
import { Route } from "react-router-dom";
import { InstancesContainer } from "./instances.container";
import { InstanceDetailContainer } from './instance-detail.container';

export const Instance = (props) => {
    return <div>
        <Route path="/console/instances" exact={true} component={InstancesContainer}/>
        <Route path="/console/instances/:instanceId" component={InstanceDetailContainer} />
    </div>
}
