import { useDispatch, useSelector } from "react-redux";
import { Fetch, GET_TICKET_CATEGORIES, GET_TICKET_TEMPLATES, ModalAction, State, TicketAction } from "../../redux";
import { SubHeader } from "../../components/sub-header";
import { Tools } from "../../components/tools.components";
import { Button, Card, Table } from "antd";
import {
    CREATE_TEMPLATE_MODAL,
    CREATE_TICKET_CATEGORY_MODAL, DELETE_TEMPLATE_MODAL,
    DELETE_TICKET_CATEGORY_MODAL, UPDATE_TEMPLATE_MODAL
} from "../../redux/constants/modal.constants";
import { DeleteOutlined, PlusSquareOutlined, ReloadOutlined } from "@ant-design/icons";
import * as React from "react";
import { prettyDate } from "../../helpers/date.helper";
import { useMount } from "react-use";
import styles from './templates.container.m.less';
import { useEffect, useState } from "react";
import { CreateCategoryModal } from "./create-category.modal";
import { DeleteCategoryModal } from "./delete-category.modal";
import { CreateTemplateModal } from "./create-template.modal";
import { DeleteTemplateModel } from "./delete-template.modal";
import { UpdateTemplateModal } from "./update-template.modal";
import { TicketTemplateStatus } from "../../enums/ticket-template-status.enum";

export const TemplatesContainer = (props) => {
    const dispatch = useDispatch();
    const categories = useSelector<State, any[]>(state => state.ticket.categories);
    const templates = useSelector<State, any[]>(state => state.ticket.templates);
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_TICKET_TEMPLATES] ?? {} as any);

    const [selected, updateSelected] = useState();

    const refresh = () => {
        dispatch(TicketAction.getTemplates(selected));
    };

    useMount(() => dispatch(TicketAction.getCategories()));

    useEffect(() => {
        if (categories && categories.length > 0 && !selected) {
            updateSelected(categories[0].id);
            dispatch(TicketAction.getTemplates(categories[0].id));
        }
        if (categories.length === 0) {
            updateSelected(undefined);
        }
    }, [categories]);

    useEffect(() => {
        if (selected) {
            dispatch(TicketAction.getTemplates(selected));
        }
    }, [selected])

    return <div style={{ height: '100%' }}>
        <SubHeader title="分类管理"/>
        <Tools>
            <Button 
            type="primary" 
            onClick={() => dispatch(ModalAction.open(CREATE_TEMPLATE_MODAL, {
                categoryId: selected,
            }))}
            disabled={categories.length === 0}
            >
                创建模板
            </Button>
            <Button type="ghost" icon={<ReloadOutlined/>} onClick={() => refresh()}/>
        </Tools>
        <div className={styles.content}>
            <Card title="分类"
                  bordered={false}
                  className={styles.category}
                  bodyStyle={{ padding: 0 }}
                  extra={<PlusSquareOutlined
                      className={styles.plus}
                      onClick={() => dispatch(ModalAction.open(CREATE_TICKET_CATEGORY_MODAL))}
                  />}>
                <ul>
                    {categories.map(({ name, id }) =>
                        <li key={id}
                            className={selected === id ? styles.active : ''}
                            onClick={() => updateSelected(id)}>
                            {name}
                            <DeleteOutlined
                                className={styles.delete}
                                onClick={(e) => {
                                    dispatch(ModalAction.open(DELETE_TICKET_CATEGORY_MODAL, { id, name }));
                                    e.stopPropagation();
                                }}/>
                        </li>
                    )}
                </ul>
            </Card>
            <Table
                loading={fetching}
                rowKey={record => record.id}
                dataSource={templates} pagination={false}
                className={styles.template}>
                <Table.Column title="模板名称" dataIndex="name" key="name"/>
                <Table.Column<any>
                    title="客服组"
                    dataIndex="group"
                    key="group"
                    render={(text, record) => record?.group?.name}/>
                <Table.Column title="优先级" dataIndex="priority" key="priority"/>
                <Table.Column title="状态" dataIndex="status" key="status" render={text => TicketTemplateStatus[text]}/>
                <Table.Column<any> title="自定义字段" dataIndex="fields" key="fields" render={(text, record) => <>
                    {record?.fields?.map(field => field.field).join(',')}
                </>}/>
                <Table.Column title="创建时间" dataIndex="createdAt" key="createdAt" render={text => prettyDate(text)}/>
                <Table.Column title="修改时间" dataIndex="updatedAt" key="updatedAt" render={text => prettyDate(text)}/>
                <Table.Column<any>
                    title="操作"
                    dataIndex="methods"
                    key="methods"
                    render={(text, record) => {
                        return <>
                            <Button
                                type="link"
                                onClick={() => dispatch(ModalAction.open(UPDATE_TEMPLATE_MODAL, record))}>
                                编辑
                            </Button>
                            <Button
                                type="link"
                                onClick={() => dispatch(record.status === 'disable' ?
                                    TicketAction.enableTemplate(record.id) :
                                    TicketAction.disableTemplate(record.id)
                                )}>
                                {record.status === 'disable' ? '启用' : '禁用'}
                            </Button>
                            <Button
                                danger={true}
                                type="link"
                                onClick={() => dispatch(ModalAction.open(DELETE_TEMPLATE_MODAL, record))}>
                                删除
                            </Button>
                        </>
                    }}/>
            </Table>
            <CreateCategoryModal/>
            <DeleteCategoryModal refresh={() => refresh()}/>
            <CreateTemplateModal/>
            <DeleteTemplateModel/>
            <UpdateTemplateModal/>
        </div>
    </div>

}
