import * as React from 'react';
import { useMount } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { ProductAction } from "../../redux/actions";
import { Fetch, GET_PRODUCTS, ListModel, Product, State } from "../../redux";
import { Button, Table } from "antd";
import { Tools } from "../../components/tools.components";
import { ModalAction } from "../../redux/actions";
import {
    CREATE_PRODUCT_MODAL, DELETE_PRODUCT_MODAL, UPDATE_PRODUCT_MODAL,
    UPDATE_REGION_MODAL
} from "../../redux/constants/modal.constants";
import { ReloadOutlined } from "@ant-design/icons";
import { SubHeader } from "../../components/sub-header";
import { CreateProductModal } from "./create-product.modal";
import { UpdateProductModal } from "./update-product.modal";
import { DeleteProductModal } from "./delete-product.modal";
import { Link } from "react-router-dom";
import { prettyDate } from "../../helpers/date.helper";

export const ProductsContainer = (props) => {
    const dispatch = useDispatch();
    const { results, entities } = useSelector<State, ListModel<Product>>(state => ({
        results: state.product.results,
        entities: state.product.entities,
    }));
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_PRODUCTS] ?? {} as any);
    const products = results.map(id => entities[id]);
    const columns = [
        {
            title: '产品名称',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return <Link to={`/console/products/${record.id}`}>{text}</Link>;
            }
        },
        {
            title: '产品类型',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '服务费',
            dataIndex: 'charges',
            key: 'charges',
        },
        {
            title: '描述',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: text => prettyDate(text)
        },
        {
            title: '修改时间',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: text => prettyDate(text)
        },
        // {
        //     title: '操作',
        //     dataIndex: 'operation',
        //     key: 'operation',
        //     render: (text, record) => {
        //         return <>
        //             <Button
        //                 type="link"
        //                 onClick={() => dispatch(ModalAction.open(UPDATE_PRODUCT_MODAL, record))}>
        //                 编辑
        //             </Button>
        //             <Button
        //                 danger={true}
        //                 type="link"
        //                 onClick={() => dispatch(ModalAction.open(DELETE_PRODUCT_MODAL, record))}>
        //                 删除
        //             </Button>
        //         </>
        //     }
        // }
    ];
    const refresh = () => dispatch(ProductAction.getProducts());
    useMount(() => dispatch(ProductAction.getProducts()));

    return <div>
        <SubHeader title="产品管理"/>
        <Tools>
            {/*<Button type="primary" onClick={() => dispatch(ModalAction.open(CREATE_PRODUCT_MODAL))}>创建产品</Button>*/}
            <Button type="ghost" icon={<ReloadOutlined/>} onClick={() => refresh()}/>
        </Tools>
        <Table loading={fetching} dataSource={products} columns={columns} pagination={false}/>
        <CreateProductModal/>
        <UpdateProductModal/>
        <DeleteProductModal/>
    </div>
}
