import * as React from 'react';
import { Alert, Form, Input, Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Fetch, State } from "../../redux/interfaces";
import { CREATE_REGION_MODAL } from "../../redux/constants/modal.constants";
import { ModalAction } from "../../redux/actions";
import { FetchAction, RegionAction } from "../../redux/actions";
import { CREATE_REGION } from "../../redux";
import { FormInstance } from "antd/lib/form";

export const CreateRegionModal = (props) => {
    const platforms = [
        { name: '阿里云', key: 'aliyun' },
        { name: 'AWS', key: 'aws' },
        { name: '腾讯云', key: 'tencent' },
    ];
    const { visible } = useSelector<State, { [key: string]: any }>(
        state => state.modal[CREATE_REGION_MODAL] ?? {} as any,
    );
    const { fetching, error, message } = useSelector<State, Fetch>(
        state => state.fetch[CREATE_REGION] ?? {} as any,
    );
    const errorMessage = error ? <Alert message={message} type="error" closable/> : null;
    const dispatch = useDispatch();

    const formRef = React.createRef<FormInstance>();
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    const submit = values => {
        const platform = platforms.filter(item => item.key === values.platform)[0];
        dispatch(RegionAction.createRegion({
            name: values.name,
            description: values.description,
            platform: values.platform,
            platformName: platform.name,
        }));
    }
    const confirm = () => {
        (formRef.current as any).submit();
    }
    return <Modal
        title="创建区域"
        visible={visible}
        onOk={() => confirm()}
        confirmLoading={fetching}
        width={460}
        onCancel={() => {
            dispatch(ModalAction.close(CREATE_REGION_MODAL));
            dispatch(FetchAction.clear(CREATE_REGION));
            (formRef.current as any).resetFields();
        }}>
        {errorMessage}
        <Form {...layout} ref={formRef} name="control-ref" onFinish={values => submit(values)}>
            <Form.Item name="name" label="区域名称" rules={[{ required: true }]}>
                <Input/>
            </Form.Item>
            <Form.Item name="platform" label="云平台" rules={[{ required: true }]}>
                <Select>
                    {platforms.map(platform => <Select.Option value={platform.key}>{platform.name}</Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item name="platformName" label="云平台别名">
                <Input/>
            </Form.Item>
            <Form.Item name="description" label="描述">
                <Input multiple={true} aria-rowcount={4}/>
            </Form.Item>
        </Form>
    </Modal>;
}
