import { Client } from "../client";
import { Dispatch } from "redux";
import { GET_INSTANCES, UPDATE_INSTANCE, GET_INSTANCE, UPDATE_INSTANCE_ITEM, UPDATE_INSTANCE_META, UPDATE_ARN } from "../constants/fetch.constants";
import { Action } from "../action";
import { INSTANCE, INSTANCE_DETAIL } from "../constants/action-types.constants";
import { getPagination } from "../helpers/pagination.helper";
import { PaginationAction } from "./pagination.action";
import { ModalAction } from "./modal.action";
import { UPDATE_ARN_MODAL, UPDATE_INSTANCE_MODAL } from "../constants/modal.constants";

export class InstanceAction {
    private static client = new Client();

    public static getInstances(query: any) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const pagination = getPagination(state(), 'instance', query.page, query.size);
            const params = {
                page: pagination.page,
                size: pagination.size,
                id: query.id,
                accountId: query.accountId,
                status: query.status,
            };
            console.log(params);
            const { page, size, total, list } = await this.client.get('/api/instances', {
                params: { ...params },
                dispatch,
                fetch: GET_INSTANCES,
            });

            dispatch(new Action(INSTANCE).init(list));
            dispatch(PaginationAction.update('instance', page, size, total, list.length));
        }
    }

    public static getInstance(id: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { instance } = await this.client.get(`/api/instances/${id}`, {
                fetch: GET_INSTANCE,
                dispatch,
            });
            dispatch(new Action(INSTANCE_DETAIL).put('instance', instance));
        }
    }

    public static updateInstance(instanceId: string, values: { status?: string, networkStatus?: string }) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(`/api/instances/${instanceId}`, {
                ...values,
            }, {
                dispatch,
                fetch: UPDATE_INSTANCE,
            });
            dispatch(this.getInstance(instanceId));
        }
    }

    public static updateInstanceItem(instanceId: string, type: string, values: any) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(`/api/instances/${instanceId}/item/${type}`, {
                ...values,
            }, {
                dispatch,
                fetch: UPDATE_INSTANCE_ITEM,
            });
            dispatch(this.getInstance(instanceId));
        }
    }

    public static updateInstanceMeta(instanceId: string, key: string, value: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(`/api/instances/${instanceId}/metas/${key}`, {
                value,
            }, {
                dispatch,
                fetch: UPDATE_INSTANCE_META,
            });
            dispatch(this.getInstance(instanceId));
        }
    }

    public static updateARN(instanceId: string, values: { value: string }) {
        return async (dispatch, state) => {
            await this.client.put(`/api/instances/${instanceId}/metas/arn`, {
                ...values,
            }, {
                dispatch,
                fetch: UPDATE_ARN,
            });
            const { page, size } = state().pagination.instance;
            dispatch(this.getInstances({ page, size }));
            dispatch(ModalAction.close(UPDATE_ARN_MODAL));
        }
    }
}
