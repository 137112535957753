import * as React from 'react';
import { Alert, Form, Input, InputNumber, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Fetch, State } from "../../redux/interfaces";
import { UPDATE_PRODUCT_MODAL } from "../../redux/constants/modal.constants";
import { ModalAction, ProductAction } from "../../redux/actions";
import { FetchAction } from "../../redux/actions";
import { UPDATE_PRODUCT } from "../../redux";
import { FormInstance } from "antd/lib/form";

export const UpdateProductModal = (props) => {
    const { visible, name, type, description, id, charges } = useSelector<State, { [key: string]: any }>(
        state => state.modal[UPDATE_PRODUCT_MODAL] ?? {},
    );
    const { fetching, error, message } = useSelector<State, Fetch>(
        state => state.fetch[UPDATE_PRODUCT] ?? {} as any,
    );
    const formRef = React.createRef<FormInstance>();
    const errorMessage = error ? <Alert message={message} type="error" closable/> : null;
    const dispatch = useDispatch();

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    const submit = values => {
        dispatch(ProductAction.updateProduct(id, values.name, type, values.description, values.charges));
    }
    const confirm = () => {
        (formRef.current as any).submit();
    }

    return <Modal
        title="编辑产品"
        visible={visible}
        onOk={() => confirm()}
        confirmLoading={fetching}
        destroyOnClose={true}
        width={460}
        onCancel={() => {
            dispatch(ModalAction.close(UPDATE_PRODUCT_MODAL));
            dispatch(FetchAction.clear(UPDATE_PRODUCT));
            (formRef.current as any).resetFields();
        }}>
        {errorMessage}
        <Form {...layout} ref={formRef} name="control-ref" onFinish={values => submit(values)}>
            <Form.Item name="name" label="产品名称" rules={[{ required: true }]} initialValue={name}>
                <Input/>
            </Form.Item>
            <Form.Item name="description" label="描述" rules={[{ required: true }]} initialValue={description}>
                <Input.TextArea rows={4}/>
            </Form.Item>
            <Form.Item name="charges" label="服务费" rules={[{ required: true }]} initialValue={charges ?? 0}>
                <InputNumber/>
            </Form.Item>
        </Form>
    </Modal>;
}
