import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout, Menu, Dropdown } from "antd";
import { CodepenOutlined } from "@ant-design/icons";
import { Management } from "./management";
import { Product } from "./product";
import { Voucher } from "./voucher";
import { LoginContainer } from "./login/login.container";
import { ConsoleContainer } from "./console.container";
import { useDispatch, useSelector } from "react-redux";
import { AuthAction, State } from "../redux";
import { Session } from "../redux/interfaces/session.interface";
import { VoucherInstancesContainer } from "./voucher/voucher-instances.container";
import { BillsContainer } from "./bill/bills.container";
import { OrdersContainer } from "./bill/orders.container";
import { OrderContainer } from "./bill/order.container";
import { AccountsContainer } from './account/accounts.container';
import { NotificationContainer } from './notification/notification.container';
import { Instance } from "./instance";
import { Ticket } from "./ticket";
import { RedirectContainer } from './redirect.container';

export const Routes = props => {
    const dispatch = useDispatch();
    const [collapsed, onCollapse] = useState(false);
    const { role, email } = useSelector<State, Session>(state => state.session)
    const pushState = (url: string) => {
        window.location.hash = url;
    }
    return <div>
        <Route path="/" exact={true}>
            <RedirectContainer />
        </Route>
        <Route path="/sign-in"><LoginContainer/></Route>
        <Route path="/console" exact={false}>
            <Layout style={{ minHeight: '100vh' }}>
                <Layout.Sider
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                    }}
                    collapsible collapsed={collapsed} onCollapse={onCollapse}>
                    <div className="logo">
                        <img src={`./logo.svg`} alt="YanRong Tech"/>
                    </div>
                    <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                        {['admin', 'operator'].includes(role) ? <Menu.Item
                            key="instance"
                            icon={<CodepenOutlined/>}
                            onClick={() => pushState('/console/instances')}>
                            实例管理
                        </Menu.Item> : ''}
                        {['admin'].includes(role) ? <Menu.Item
                            key="product"
                            icon={<CodepenOutlined/>}
                            onClick={() => pushState('/console/products')}>
                            产品管理
                        </Menu.Item> : ''}
                        {['admin', 'finance'].includes(role) ? <Menu.Item
                            key="bill"
                            icon={<CodepenOutlined/>}
                            onClick={() => pushState('/console/bills')}>
                            账单管理
                        </Menu.Item> : ''}
                        {['admin', 'finance'].includes(role) ? <Menu.Item
                            key="order"
                            icon={<CodepenOutlined/>}
                            onClick={() => pushState('/console/orders')}>
                            订单管理
                        </Menu.Item> : ''}
                        <Menu.SubMenu key="voucher-management" icon={<CodepenOutlined/>} title="代金券">
                            {['admin', 'business', 'sale'].includes(role) ? <Menu.Item
                                key="voucher"
                                icon={<CodepenOutlined/>}
                                onClick={() => pushState('/console/vouchers')}>
                                代金券
                            </Menu.Item> : ''}
                            {['admin', 'sale'].includes(role) ? <Menu.Item
                                key="voucher-instance"
                                icon={<CodepenOutlined/>}
                                onClick={() => pushState('/console/voucher-instances')}>
                                代金券实例
                            </Menu.Item> : ''}
                        </Menu.SubMenu>
                        {['admin'].includes(role) ?
                            <Menu.SubMenu key="management" icon={<CodepenOutlined/>} title="集群管理">
                                <Menu.Item
                                    key="region-management"
                                    onClick={() => pushState('/console/management/regions')}>
                                    区域管理
                                </Menu.Item>
                                <Menu.Item
                                    key="cluster-management"
                                    onClick={() => pushState('/console/management/clusters')}>
                                    集群管理
                                </Menu.Item>
                            </Menu.SubMenu> : ''}
                        {['admin'].includes(role) ?
                            <Menu.Item
                                key="account"
                                icon={<CodepenOutlined/>}
                                onClick={() => pushState('/console/accounts')}>
                                账户管理
                            </Menu.Item> : null
                        }
                        {['admin', 'business'].includes(role) ?
                            <Menu.SubMenu
                                key="notification"
                                icon={<CodepenOutlined/>}
                                title="消息管理">
                                <Menu.Item
                                    key="notification-message"
                                    onClick={() => pushState('/console/notification/messages')}>
                                    消息通知
                                </Menu.Item>
                                {['admin'].includes(role) ? <Menu.Item
                                    key="notification-template"
                                    onClick={() => pushState('/console/notification/templates')}>
                                    模板管理
                                </Menu.Item> : null}
                            </Menu.SubMenu> : null
                        }
                        {['admin', 'operator', 'finance', 'sale'].includes(role) ?
                            <Menu.SubMenu key="ticket" icon={<CodepenOutlined/>} title="工单">
                                {['admin'].includes(role) ? <Menu.Item
                                    key="ticket-resolver"
                                    onClick={() => pushState('/console/ticket/resolvers')}>
                                    客服管理
                                </Menu.Item> : null}
                                {['admin'].includes(role) ? <Menu.Item
                                    key="ticket-template"
                                    onClick={() => pushState('/console/ticket/templates')}>
                                    模板管理
                                </Menu.Item> : null}
                                {['admin', 'operator', 'finance', 'sale'].includes(role) ? <Menu.Item
                                    key="ticket"
                                    onClick={() => pushState('/console/ticket/tickets')}>
                                    工单管理
                                </Menu.Item> : null}
                            </Menu.SubMenu> : ''}
                        <Menu.Item key="undefined"/>
                    </Menu>
                </Layout.Sider>
                <Layout className="site-layout"
                        style={{ marginLeft: collapsed ? 80 : 200, transition: 'margin-left 0.2s' }}>
                    <Layout.Header className="site-layout-background" style={{ padding: 0 }}>
                        <Dropdown
                            trigger={['click']}
                            getPopupContainer={node => node.parentElement as HTMLElement}
                            overlay={<Menu onClick={() => dispatch(AuthAction.signOut())}>
                                <Menu.Item>退出控制台</Menu.Item>
                            </Menu>}>
                            <span style={{ color: 'white', float: 'right', marginRight: 20, cursor: 'pointer' }}>
                                {email}
                            </span>
                        </Dropdown>
                    </Layout.Header>
                    <Layout.Content style={{ margin: '0 20px' }}>
                        <div style={{ height: '100%' }}>
                            <ConsoleContainer>
                                <Route path="/" exact={true}><RedirectContainer /></Route>
                                <Route path="/console" exact={true}><RedirectContainer /></Route>
                                <Route path="/console/management" component={Management}/>
                                <Route path="/console/products" component={Product}/>
                                <Route path="/console/vouchers" component={Voucher}/>
                                <Route path="/console/instances" component={Instance}/>
                                <Route path="/console/voucher-instances" component={VoucherInstancesContainer}/>
                                <Route path="/console/bills" component={BillsContainer}/>
                                <Route path="/console/orders" exact={true} component={OrdersContainer}/>
                                <Route path="/console/orders/:orderId" exact={true} component={OrderContainer}/>
                                <Route path="/console/accounts" exact component={AccountsContainer}/>
                                <Route path="/console/notification" component={NotificationContainer}/>
                                <Route path="/console/ticket" component={Ticket}/>
                            </ConsoleContainer>
                        </div>
                    </Layout.Content>
                    <Layout.Footer style={{ textAlign: 'center' }}>YanRong Tech ©2020</Layout.Footer>
                </Layout>
            </Layout>
        </Route>
    </div>;
}
