import * as React from 'react';
import { Redirect, Route } from "react-router-dom";
import { VoucherContainer } from "./voucher.container";
import { VoucherRulesContainer } from "./voucher-rules.container";
import { VoucherStatisticContainer } from "./voucher-statistic.container";

export const Voucher = (props) => {
    return <div>
        <Route path="/console/vouchers" exact={true}>
            <Redirect to="/console/vouchers/list"/>
        </Route>
        <Route path="/console/vouchers/list" exact={true} component={VoucherContainer}/>
        <Route path="/console/vouchers/statistic" exact={true} component={VoucherStatisticContainer}/>
        <Route path="/console/vouchers/list/:voucherId" exact={true} component={VoucherRulesContainer}/>
    </div>
}
