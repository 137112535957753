import { Form, Input, Button, message as Message, Breadcrumb, Checkbox } from 'antd';
import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { SubHeader } from '../../components/sub-header';
import { useDispatch } from 'react-redux';
import { NotificationAction } from '../../redux';
import './create-message.container.less';
import { handleHtmlContent } from "../../helpers/editor.helper";
import { plugins, scriptSrc, toolbar } from "../../constants/tinymce.constants";

export const CreateMessageContainer = (props) => {
    const [form] = Form.useForm();
    const formLayout = {
        labelCol: { span: 2 },
        wrapperCol: { span: 20 },
    }
    const [content, updateContent] = useState<string>('');
    const dispatch = useDispatch();

    const submit = (values) => {
        dispatch(NotificationAction.createMessage(
            {
                ...values,
                message: handleHtmlContent(content),
                method: values.method.join(','),
            },
            () => {
                form.resetFields();
                Message.info('创建成功');
                pushState('/console/notification/messages');
            })
        );
    };

    const pushState = (url: string) => {
        window.location.hash = url;
    };

    return <div className="create-message">
        <SubHeader title="创建消息"/>
        <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => pushState('/console/notification/messages')}>
                <span className="breadcrumb-item">返回上一级</span>
            </Breadcrumb.Item>
        </Breadcrumb>
        <Form{...formLayout} form={form} onFinish={(values) => submit(values)} className="form">
            <Form.Item label="标题" name="title" rules={[{ required: true }]}>
                <Input/>
            </Form.Item>
            <Form.Item label="分类" name="type" initialValue="网站通知">
                <Input/>
            </Form.Item>
            <Form.Item label="推送方式" name="method" initialValue={['message']}>
                <Checkbox.Group>
                    <Checkbox value="message" style={{ lineHeight: '32px' }}>
                        消息
                    </Checkbox>
                    <Checkbox value="email" style={{ lineHeight: '32px' }}>
                        邮件
                    </Checkbox>
                </Checkbox.Group>
            </Form.Item>
            <Form.Item label="内容">
                <Editor
                    initialValue=""
                    tinymceScriptSrc={scriptSrc}
                    init={{
                        height: 500,
                        menubar: false,
                        plugins,
                        toolbar,
                    }}
                    onEditorChange={(val) => updateContent(val)}
                />
            </Form.Item>
            <Form.Item label colon={false}>
                <Button type="primary" onClick={() => form.submit()}>提 交</Button>
            </Form.Item>
        </Form>
    </div>
}
