import { ADD, DELETE, INIT, PUT, UPDATE } from './constants/verb.constants';

export class Action {
    constructor(
        private readonly type: string,
    ) {
    }

    init<T extends any>(value: T[]) {
        return {
            type: this.type,
            verb: INIT,
            value,
        }
    }

    add<T extends any>(value: T) {
        return {
            type: this.type,
            verb: ADD,
            value,
        }
    }

    delete<T extends any>(key: string | number) {
        return {
            type: this.type,
            verb: DELETE,
            key,
        }
    }

    put<T extends any>(key: string | number, value: T) {
        return {
            type: this.type,
            verb: PUT,
            key,
            value,
        }
    }

    update<T extends any>(key: string | number, path: string, value: T) {
        return {
            type: this.type,
            verb: UPDATE,
            key,
            path,
            value,
        }
    }
}
