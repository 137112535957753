export const GET_REGIONS = 'get_regions';
export const CREATE_REGION = 'create_region';
export const UPDATE_REGION = 'update_region';
export const DELETE_REGION = 'delete_region';

export const GET_CLUSTERS = 'get_clusters';
export const CREATE_CLUSTER = 'create_cluster';
export const UPDATE_CLUSTER = 'update_cluster';
export const DELETE_CLUSTER = 'delete_cluster';

export const GET_PRODUCTS = 'get_products';
export const GET_PRODUCT = 'get_product';
export const CREATE_PRODUCT = 'create_product';
export const UPDATE_PRODUCT = 'update_product';
export const DELETE_PRODUCT = 'delete_product';

export const GET_BILLING_ITEMS = 'get_billing_items';
export const CREATE_BILLING_ITEM = 'create_billing_item';

export const GET_VOUCHERS = 'get_vouchers';
export const GET_VOUCHER = 'get_voucher';
export const CREATE_VOUCHER = 'create_voucher';
export const UPDATE_VOUCHER = 'update_voucher';
export const GET_RULES = 'get_rules';
export const SET_VOUCHER_RULES = 'set_voucher_rules';
export const DELETE_VOUCHER = 'delete_voucher';
export const VERIFYING_VOUCHER = 'verifying_voucher';
export const CREATE_VOUCHER_INSTANCE = 'create_voucher_instance';
export const GET_VOUCHER_INSTANCES = 'get_voucher_instances';

export const GET_BILLS = 'get_bills';
export const GET_ORDERS = 'get_orders';
export const GET_ORDER = 'get_order';

export const LOGIN = 'login';
export const GET_SESSION = 'get_session';

export const GET_ACCOUNTS = 'get_accounts';
export const UPDATE_ACCOUNT_STATUS = 'update_account_status';
export const UPDATE_ACCOUNT_PASSWORD = 'update_account_password';

export const GET_MESSAGES = 'get_messages';
export const CREATE_MESSAGE = 'create_message';
export const DELETE_MESSAGE = 'delete_message';
export const PUBLISH_MESSAGE = 'publish_message';

export const GET_NOTIFICATION_TEMPLATES = 'get_notification_templates';
export const ENABLE_NOTIFICATION_TEMPLATE = 'enable_notification_template';
export const DISABLE_NOTIFICATION_TEMPLATE = 'disable_notification_template';
export const UPDATE_NOTIFICATION_TEMPLATE = 'update_notification_template';
export const SEND_TEST_EMAIL = 'send_test_email';
export const SEND_TEST_SMS = 'send_test_sms';

export const GET_BROADCASTS = 'get_broadcasts';
export const CREATE_BROADCAST = 'create_broadcast';
export const DELETE_BROADCAST = 'delete_broadcast';
export const PUBLISH_BROADCAST = 'publish_broadcast';
export const GET_INSTANCES = 'get_instances';
export const GET_INSTANCE = 'get_instance';
export const UPDATE_INSTANCE = 'update_instance';
export const UPDATE_ARN = 'udpate_arn';
export const UPDATE_INSTANCE_ITEM = 'update_instance_item';
export const UPDATE_INSTANCE_META = 'update_instance_meta';

export const GET_TICKET_CATEGORIES = 'get_ticket_categories';
export const GET_TICKET_TEMPLATES = 'get_ticket_templates';
export const CREATE_TICKET_CATEGORY = 'create_ticket_category';
export const DELETE_TICKET_CATEGORY = 'delete_ticket_category';
export const GET_RESOLVER_GROUPS = 'get_resolver_groups';
export const GET_RESOLVERS = 'get_resolvers';
export const GET_CURRENT_RESOLVERS = 'get_current_resolvers';
export const CREATE_RESOLVER_GROUP = 'create_resolver_group';
export const CREATE_RESOLVER = 'create_resolver';
export const DELETE_RESOLVER_GROUP = 'delete_resolver_group';
export const DELETE_RESOLVER = 'delete_resolver';
export const ENABLE_RESOLVER = 'enable_resolver';
export const DISABLE_RESOLVER = 'disable_resolver';
export const CREATE_TEMPLATE = 'create_template';
export const ENABLE_TEMPLATE = 'enable_template';
export const DISABLE_TEMPLATE = 'disable_template';
export const DELETE_TEMPLATE = 'delete_template';
export const UPDATE_TEMPLATE = 'update_template';

export const GET_TICKETS = 'get_tickets';
export const GET_TICKET = 'get_ticket';
export const REPLY_TICKET = 'reply_ticket';
