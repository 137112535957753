import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { GET_ORDER, OrderAction } from "../../redux";
import { Fetch, State } from "../../redux";
import { Breadcrumb, Card, Table } from "antd";
import { SubHeader } from "../../components/sub-header";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { prettyDate } from "../../helpers/date.helper";
import { OrderStatus } from "../../enums/order-status.enum";
import { OrderType } from "../../enums/order-type.enum";
import { OrderPayment } from "../../enums/order-payment.enum";

export const OrderContainer = (props) => {
    const dispatch = useDispatch();
    const orderId = props?.match?.params?.orderId;
    const order = useSelector<State, any>(state => state.bill.order);
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_ORDER] ?? {} as any);

    useEffect(() => {
        dispatch(OrderAction.getOrder(orderId));
    }, [orderId]);

    return <div>
        <SubHeader title={<Breadcrumb>
            <Breadcrumb.Item><Link to="/console/orders">订单管理</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link
                to={`console/orders/${orderId}`}>{order?.id ?? orderId}</Link></Breadcrumb.Item>
        </Breadcrumb>}/>
        <Card title={order?.id ?? orderId} bodyStyle={{ padding: 20 }} style={{ marginTop: 20 }}>
            <ul className="list">
                <li><span className="title">订单类型</span><span>{OrderType[order?.type]}</span></li>
                <li><span className="title">产品类型</span><span>{order?.product?.description}</span></li>
                <li><span className="title">账号</span><span>{order?.email}</span></li>
                <li>
                    <span className="title">价格</span>
                    <span>原价({order?.price}) 应付({order?.amountDue})</span>
                </li>
                <li><span className="title">状态</span><span>{OrderStatus[order?.status]}</span></li>
                <li><span className="title">创建时间</span><span>{prettyDate(order?.createdAt)}</span></li>
                <li><span className="title">支付时间</span><span>{order?.paidAt}</span></li>
            </ul>
        </Card>
        <Table
            loading={fetching}
            dataSource={order.payments}
            pagination={false}>
            <Table.Column title="支付渠道" key="method" dataIndex="method" render={text => OrderPayment[text]}/>
            <Table.Column title="扣款费用" key="fee" dataIndex="fee"/>
            <Table.Column title="扣款时间" key="time" dataIndex="time" render={(text) => prettyDate(text)}/>
            <Table.Column title="流水编号" key="sn" dataIndex="sn"/>
            <Table.Column<any>
                title="支付账号"
                key="account"
                dataIndex="account"
                render={(text, record) => record.email ?? text}/>
        </Table>
        <Table
            loading={fetching}
            dataSource={order.details}
            pagination={false}>
            <Table.Column<any>
                title="产品"
                key="productType"
                dataIndex="productType"
                render={(text, record) => {
                    return <>
                        <div>{order?.product?.name}</div>
                        <div>实例：</div>
                        {record.instances.map(instance => <div key={instance.id}>{instance.name}</div>)}
                    </>
                }}/>
            <Table.Column<any>
                title="数量"
                key="amount"
                dataIndex="amount"
                render={(text, record) => record?.amount}/>
            <Table.Column<any>
                title="具体配置"
                key="configs"
                dataIndex="configs"
                render={(text, record) => {
                    return <>
                        <div>区域：{record?.region?.platformName} / {record?.region?.name}</div>
                        {record.items.map(item => {
                            return <div key={item.billingItem}>{item?.billingItemObj?.name}：{item.to} {item.unit}</div>;
                        })}
                    </>
                }}/>
            <Table.Column<any>
                title="付款方式"
                key="period"
                dataIndex="period"
                render={(text, record) => record?.billingMode === 'prepay' ? `${record?.period} ${record?.periodUnit}` : '按量'}/>
            <Table.Column<any>
                title="起止时间"
                key="time"
                dataIndex="time"
                render={(text, record) => {
                    return `${prettyDate(record?.start)} - ${prettyDate(record?.end)}`
                }}/>
            <Table.Column title="金额" key="tradeAmount" dataIndex="tradeAmount"/>
        </Table>
    </div>
}
