import * as React from 'react';
import { Alert, Form, Input, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Fetch, State } from "../../redux/interfaces";
import { UPDATE_VOUCHER_MODAL } from "../../redux/constants/modal.constants";
import { ModalAction, VoucherAction } from "../../redux/actions";
import { FetchAction } from "../../redux/actions";
import { UPDATE_VOUCHER } from "../../redux";
import { FormInstance } from "antd/lib/form";
import { useEffect } from "react";

export const UpdateVoucherModal = (props) => {
    const { visible, name, description, id } = useSelector<State, { [key: string]: any }>(
        state => state.modal[UPDATE_VOUCHER_MODAL] ?? {} as any,
    );
    const { fetching, error, message } = useSelector<State, Fetch>(
        state => state.fetch[UPDATE_VOUCHER] ?? {} as any,
    );
    const errorMessage = error ? <Alert message={message} type="error" closable/> : null;
    const dispatch = useDispatch();

    const formRef = React.createRef<FormInstance>();
    useEffect(() => {
        if (visible && formRef.current && !(formRef.current as any).getFieldValue('name')) {
            (formRef.current as any).setFieldsValue({ name, description });
        }
    }, [visible, name, description, formRef]);
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    const submit = values => {
        dispatch(VoucherAction.updateVoucher(id, values.name, values.description));
    }
    const confirm = () => {
        (formRef.current as any).submit();
    }
    return <Modal
        title="编辑代金券"
        visible={visible}
        onOk={() => confirm()}
        confirmLoading={fetching}
        width={460}
        onCancel={() => {
            dispatch(ModalAction.close(UPDATE_VOUCHER_MODAL));
            dispatch(FetchAction.clear(UPDATE_VOUCHER));
            (formRef.current as any).resetFields();
        }}>
        {errorMessage}
        <Form {...layout} ref={formRef} name="control-ref" onFinish={values => submit(values)}>
            <Form.Item name="name" label="名称" rules={[{ required: true }]}>
                <Input/>
            </Form.Item>
            <Form.Item name="description" label="描述">
                <Input multiple={true} aria-rowcount={4}/>
            </Form.Item>
        </Form>
    </Modal>;
}
