import * as React from 'react';
import { Alert, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_TEMPLATE_MODAL } from "../../redux/constants/modal.constants";
import {
    FetchAction,
    Fetch,
    State,
    ModalAction,
    TicketAction,
    DELETE_TEMPLATE
} from "../../redux";

export const DeleteTemplateModel = (props) => {
    const { visible, name, id } = useSelector<State, { [key: string]: any }>(
        state => state.modal[DELETE_TEMPLATE_MODAL] ?? {} as any,
    );
    const { fetching, error, message } = useSelector<State, Fetch>(
        state => state.fetch[DELETE_TEMPLATE] ?? {} as any,
    );

    const errorMessage = error ? <Alert message={message} type="error" closable/> : null;
    const dispatch = useDispatch();

    const confirm = () => {
        dispatch(TicketAction.deleteTemplate(id));
    }
    return <Modal
        title="删除模板"
        visible={visible}
        onOk={() => confirm()}
        confirmLoading={fetching}
        width={460}
        onCancel={() => {
            dispatch(ModalAction.close(DELETE_TEMPLATE_MODAL));
            dispatch(FetchAction.clear(DELETE_TEMPLATE));
        }}>
        {errorMessage}
        确认删除模板 {name}?
    </Modal>;
}
