import * as React from 'react';
import { Alert, Form, Input, InputNumber, Modal, Select, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Fetch, ListModel, Region, State } from "../../redux/interfaces";
import { CREATE_BILLING_ITEM_MODAL } from "../../redux/constants/modal.constants";
import { ModalAction, ProductAction } from "../../redux/actions";
import { FetchAction, RegionAction } from "../../redux/actions";
import { CREATE_BILLING_ITEM } from "../../redux";
import { useEffect, useState } from "react";

type BillingItemType = { key: string, value: string, unit: string, min: number, max: number, threshold: number, step: number };

export const CreateBillingItemModal = (props) => {
    const [billingType, updateBillingType] = useState<string>();
    const [billingMode, updateBillingMode] = useState<string>();
    const { visible, productId, product } = useSelector<State, { [key: string]: any }>(
        state => state.modal[CREATE_BILLING_ITEM_MODAL] ?? {},
    );
    const { results, entities } = useSelector<State, ListModel<Region>>(state => ({
        results: state.region.results,
        entities: state.region.entities,
    }));
    const billingItems = useSelector<State, BillingItemType[]>(
        state => state.support.billingItems,
    );
    const regions = results.map(id => entities[id]);
    const { fetching, error, message } = useSelector<State, Fetch>(
        state => state.fetch[CREATE_BILLING_ITEM] ?? {} as any,
    );
    const errorMessage = error ? <Alert message={message} type="error" closable/> : null;
    const dispatch = useDispatch();

    useEffect(() => {
        if (visible && product) {
            dispatch(RegionAction.getRegions());
            dispatch(ProductAction.getSupportBillingItems(product))
        }
    }, [visible, product])

    const [form] = Form.useForm();
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    const submit = values => {
        const billingItem = billingItems.filter(item => item.key === values.type)[0];
        if (!billingItem) {
            return;
        }
        const unit = billingItem.unit;
        const name = billingItem.value;
        dispatch(ProductAction.createBillingItem(
            productId,
            { ...values, name, unit },
            () => props.refresh(),
        ));
    }
    const confirm = () => {
        form.submit();
    }

    const billingItem = billingItems.filter(item => item.key === billingType)[0];

    useEffect(() => {
        if (billingItem && form) {
            form.setFieldsValue({
                min: billingItem.min,
                max: billingItem.max,
                threshold: billingItem.threshold,
                step: billingItem.step,
            });
        }
    }, [billingItem, form]);

    const handleFormChange = (changed) => {
        const { type, billingMode } = form.getFieldsValue();
        if ((type && billingMode) && (changed.type || changed.billingMode)) {
            let description = '';
            const extra = billingMode === 'prepay' ? '(包年包月)' : '(按量付费)'
            switch (type) {
                case 'fs.capacity.size':
                    description = `容量型文件存储${extra}`;
                    break;
                case 'fs.performance.size':
                    description = `性能型文件存储容量${extra}`;
                    break;
                case 'fs.performance.bandwidth':
                    description = `性能型文件存储带宽${extra}`;
                    break;
                case 'fs.fused.size':
                    description = `性能+容量型文件存储容量${extra}`;
                    break;
                case 'fs.fused.bandwidth':
                    description = `性能+容量型文件存储带宽${extra}`;
                    break;
            }
            form.setFieldsValue({ description });
        }
    };

    return <Modal
        title="创建计费项"
        visible={visible}
        onOk={() => confirm()}
        destroyOnClose={true}
        confirmLoading={fetching}
        width={460}
        onCancel={() => {
            dispatch(ModalAction.close(CREATE_BILLING_ITEM_MODAL));
            dispatch(FetchAction.clear(CREATE_BILLING_ITEM));
            form.resetFields();
        }}>
        {errorMessage}
        <Form {...layout}
              form={form}
              name="control-ref"
              onFinish={values => submit(values)}
              onValuesChange={handleFormChange}>
            <Form.Item name="type" label="计费项" rules={[{ required: true }]}>
                <Select onChange={val => updateBillingType(val as string)}>
                    {billingItems.map(item => <Select.Option key={item.key} value={item.key}>
                        {item.value}
                    </Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item name="billingMode" label="计费模式" rules={[{ required: true }]}>
                <Select onChange={val => updateBillingMode(val as string)}>
                    <Select.Option value="prepay">预付费</Select.Option>
                    <Select.Option value="afterpay">后付费</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="regionId" label="区域" rules={[{ required: true }]}>
                <Select>
                    {regions.map(region => <Select.Option key={region.id} value={region.id as string}>
                        {region.platformName} / {region.name}
                    </Select.Option>)}
                </Select>
            </Form.Item>
            <div style={{ position: 'relative' }}>
                <Form.Item name="price" label="单价" rules={[{ required: true }]}>
                    <InputNumber/>
                </Form.Item>
                <span style={{ position: 'absolute', left: 200, top: 0, lineHeight: '32px' }}>
                    {billingMode === 'prepay' ? '元每月' : '元每小时'}
                </span>
            </div>
            <Form.Item name="description" label="描述" rules={[{ required: true }]}>
                <Input.TextArea rows={4}/>
            </Form.Item>
            <div style={{ position: 'relative' }}>
                <Form.Item name="step" label="步长" initialValue={billingItem?.step}>
                    <InputNumber/>
                </Form.Item>
                <span style={{ position: 'absolute', left: 200, top: 0, lineHeight: '32px' }}>
                    {billingItem?.unit}
                </span>
            </div>
            <div style={{ position: 'relative' }}>
                <Form.Item name="max" label="最大值" initialValue={billingItem?.max}>
                    <InputNumber/>
                </Form.Item>
                <span style={{ position: 'absolute', left: 200, top: 0, lineHeight: '32px' }}>
                    {billingItem?.unit}
                </span>
            </div>
            <div style={{ position: 'relative' }}>
                <Form.Item name="min" label="最小值" initialValue={billingItem?.min}>
                    <InputNumber/>
                </Form.Item>
                <span style={{ position: 'absolute', left: 200, top: 0, lineHeight: '32px' }}>
                    {billingItem?.unit}
                </span>
            </div>
            <div style={{ position: 'relative' }}>
                <Form.Item name="threshold" label="阈值" initialValue={billingItem?.threshold}>
                    <InputNumber/>
                </Form.Item>
                <span style={{ position: 'absolute', left: 200, top: 0, lineHeight: '32px' }}>
                    {billingItem?.unit}
                </span>
            </div>
            <Form.Item name="soldOut" label="售罄">
                <Switch/>
            </Form.Item>
        </Form>
    </Modal>;
}
