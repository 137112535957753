import { Input, Modal, Select, Form } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_ACCOUNT_MODAL } from '../../redux/constants/modal.constants';
import { State } from '../../redux';
import { AccountAction, FetchAction, ModalAction, RoleAction } from '../../redux';
import { useMount } from 'react-use';
import { filterParams } from '../../helpers/filter-params.helper';

export const UpdateAccountModal = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const formLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    const { visible, email, phone, role, name, company, address, id } = useSelector<State, { [key: string]: any }>(
        state => state.modal[UPDATE_ACCOUNT_MODAL] ?? {},
    );
    const roles = useSelector<State, any>(state => state.role.roles);
    useMount(() => dispatch(RoleAction.getRoles()));
    useEffect(() => {
        if (visible && form && !form.getFieldValue('email')) {
            form.setFieldsValue({ email, phone, roleId: role.id, name, company, address});
        }
    }, [visible, form, email, phone, role, name, company, address]);
    const submit = (values) => {
        const params = {
            ...values,
            phone: values.phone.trim(),
        }
        dispatch(AccountAction.updateAccount(id, filterParams(params) as any));
    }

    const confirm = () => {
        form.submit();
    }

    return <Modal
        title="修改账户"
        visible={visible}
        onCancel={() => {
            dispatch(ModalAction.close(UPDATE_ACCOUNT_MODAL));
            dispatch(FetchAction.clear(UPDATE_ACCOUNT_MODAL));
        }}
        onOk={() => confirm()}
        okText="确定"
        cancelText="取消"
        destroyOnClose={true}
    >
        <Form
            {...formLayout}
            form={form}
            onFinish={(values) => submit(values)}
            preserve={false}
        >
            <Form.Item
                name="email"
                label="邮箱"
            >
                <Input disabled />
            </Form.Item>
            <Form.Item
                name="phone"
                label="手机号"
                rules={[{ required: true }, () => ({
                    validator(rule, value) {
                        const pattern = /^1[3-9]\d{9}$/;
                        if (pattern.test(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject('请输入正确的手机号');
                    }
                })]}
            >
                <Input placeholder="请输入手机号" />
            </Form.Item>
            <Form.Item
                name="roleId"
                label="角色"
                rules={[{ required: true }]}
            >
                <Select placeholder="请选择用户角色">
                    {roles.map((role) => <Select.Option key={role.id} value={role.id}>
                        {role.name}
                    </Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item
                name="name"
                label="姓名"
            >
                <Input placeholder="请输入姓名" />
            </Form.Item>
            <Form.Item
                name="company"
                label="公司"
            >
                <Input placeholder="请输入公司" />
            </Form.Item>
            <Form.Item
                name="address"
                label="地址"
            >
                <Input placeholder="请输入地址" />
            </Form.Item>
        </Form>
    </Modal>
}
