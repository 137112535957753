import * as React from 'react';
import { useMount } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { Fetch, GET_REGIONS, ListModel, Region, State, RegionAction, ModalAction } from "../../redux";
import { Button, Table } from "antd";
import { Tools } from "../../components/tools.components";
import { CreateRegionModal } from "./create-region.modal";
import { CREATE_REGION_MODAL, DELETE_REGION_MODAL, UPDATE_REGION_MODAL } from "../../redux/constants/modal.constants";
import { UpdateRegionModal } from "./update-region.modal";
import { ReloadOutlined } from "@ant-design/icons";
import { DeleteRegionModal } from "./delete-region.modal";
import { SubHeader } from "../../components/sub-header";

export const RegionsContainer = (props) => {
    const dispatch = useDispatch();
    const { results, entities } = useSelector<State, ListModel<Region>>(state => ({
        results: state.region.results,
        entities: state.region.entities,
    }));
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_REGIONS] ?? {} as any);
    const regions = results.map(id => entities[id]);
    const columns = [
        {
            title: '区域名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '云平台名称',
            dataIndex: 'platformName',
            key: 'platformName',
        },
        {
            title: '云平台 KEY',
            dataIndex: 'platform',
            key: 'platform',
        },
        {
            title: '描述',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (text, record) => {
                return <>
                    <Button
                        type="link"
                        onClick={() => dispatch(ModalAction.open(UPDATE_REGION_MODAL, record))}>
                        编辑
                    </Button>
                    <Button
                        type="link"
                        onClick={() => dispatch(RegionAction.updateRegionStatus(
                            record.id,
                            record.status === 'disable' ? 'enable' : 'disable',
                        ))}>
                        {record.status === 'disable' ? '启用' : '禁用'}
                    </Button>
                    <Button
                        danger={true}
                        type="link"
                        onClick={() => dispatch(ModalAction.open(DELETE_REGION_MODAL, record))}>
                        删除
                    </Button>
                </>
            }
        }
    ];
    const refresh = () => dispatch(RegionAction.getRegions());
    useMount(() => dispatch(RegionAction.getRegions()));

    return <div>
        <SubHeader title="区域管理"/>
        <Tools>
            <Button type="primary" onClick={() => dispatch(ModalAction.open(CREATE_REGION_MODAL))}>创建区域</Button>
            <Button type="ghost" icon={<ReloadOutlined/>} onClick={() => refresh()}/>
        </Tools>
        <Table loading={fetching} dataSource={regions} columns={columns} pagination={false}/>
        <CreateRegionModal/>
        <UpdateRegionModal/>
        <DeleteRegionModal/>
    </div>
}
