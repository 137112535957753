import * as React from 'react';
import { Alert, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Fetch, State, ModalAction, FetchAction } from "../../redux";
import { DELETE_CLUSTER_META_MODAL } from "../../redux/constants/modal.constants";
import { UPDATE_CLUSTER } from "../../redux";
import { ClusterAction } from "../../redux/actions/cluster.action";

export const DeleteClusterMetaModal = (props) => {
    const { visible, key, clusterId } = useSelector<State, { [key: string]: any }>(
        state => state.modal[DELETE_CLUSTER_META_MODAL] ?? {} as any,
    );
    const { fetching, error, message } = useSelector<State, Fetch>(
        state => state.fetch[UPDATE_CLUSTER] ?? {} as any,
    );

    const errorMessage = error ? <Alert message={message} type="error" closable/> : null;
    const dispatch = useDispatch();

    const confirm = () => {
        dispatch(ClusterAction.deleteClusterMeta(clusterId, key));
    }
    return <Modal
        title="删除集群元数据"
        visible={visible}
        onOk={() => confirm()}
        confirmLoading={fetching}
        width={460}
        onCancel={() => {
            dispatch(ModalAction.close(DELETE_CLUSTER_META_MODAL));
            dispatch(FetchAction.clear(UPDATE_CLUSTER));
        }}>
        {errorMessage}
        确认删除 {key}?
    </Modal>;
}
