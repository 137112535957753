import { ReloadOutlined } from '@ant-design/icons';
import { Button, Comment, Image, Form, Input, Descriptions, Breadcrumb } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { SubHeader } from '../../components/sub-header';
import { Tools } from '../../components/tools.components';
import { Fetch, GET_TICKET, REPLY_TICKET, State, TicketAction } from '../../redux';
import { prettyDate } from "../../helpers/date.helper";
import { Link } from 'react-router-dom';

const Editor = ({ onReply, onResolve, onChange, submitting, value }) => (
    <>
        <Form.Item>
            <Input.TextArea rows={4} value={value} onChange={onChange}/>
        </Form.Item>
        <Form.Item>
            <Button style={{ marginRight: 10 }} htmlType="submit" loading={submitting} onClick={onReply} type="primary">
                回复
            </Button>
            <Button htmlType="submit" loading={submitting} onClick={onResolve} type="primary">
                解决
            </Button>
        </Form.Item>
    </>
);

export const TicketContainer = ({ match }) => {
    const type = match?.params?.type;
    const ticketId = match?.params?.ticketId;
    const dispatch = useDispatch();

    const ticket = useSelector<State, any>(state => state.ticket.ticket);
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_TICKET] ?? {} as any);
    const { fetching: replyFetching } = useSelector<State, Fetch>(state => state.fetch[REPLY_TICKET] ?? {} as any);
    const { currentResolvers } = useSelector<State, any>(state => state.ticket ?? {} as any);
    const { role } = useSelector<State, any>(state => state.session ?? {} as any);
    const [replyText, updateReplyText] = useState('');

    useMount(() => {
        dispatch(TicketAction.getCurrentResolvers());
        dispatch(TicketAction.getTicket(ticketId));
    });

    const refresh = () => {
        dispatch(TicketAction.getTicket(ticketId));
    };

    const replyTicket = (status: string) => {
        dispatch(TicketAction.replyTicket(ticketId, ticket?.resolver, replyText, status, () => updateReplyText('')));
    };
    return <div>
        <SubHeader title={<Breadcrumb>
            <Breadcrumb.Item><Link to={`/console/ticket/tickets/${type}`}>工单管理</Link></Breadcrumb.Item>
            <Breadcrumb.Item>{ticket?.id}</Breadcrumb.Item>
        </Breadcrumb>}/>
        <Tools>
            <Button
                type="ghost"
                icon={<ReloadOutlined/>}
                onClick={() => refresh()}
            />
        </Tools>
        <div style={{ backgroundColor: 'white', padding: '10px 20px' }}>
            <Descriptions>
                <Descriptions.Item label="工单编号">{ticket?.id}</Descriptions.Item>
                <Descriptions.Item label="状态">{ticket?.status}</Descriptions.Item>
                <Descriptions.Item label="问题类型">{ticket?.template?.category?.name}</Descriptions.Item>
                <Descriptions.Item label="问题标题">{ticket?.template?.name}</Descriptions.Item>
                <Descriptions.Item label="用户手机号">{ticket?.phone}</Descriptions.Item>
                <Descriptions.Item label="用户邮箱">{ticket?.email}</Descriptions.Item>
            </Descriptions>
            <Comment
                author={ticket?.email}
                content={<>
                    <p>{ticket?.content}</p>
                    {ticket?.metas?.map(meta => <p key={meta.id}>{meta.field}: {meta.value}</p>)}
                    {ticket?.attachments?.map(attachment => <Image
                        style={{ marginRight: 10 }}
                        key={attachment.id}
                        width={100}
                        src={`/api/attachments/${attachment.url}`}
                    />)}
                </>}
                datetime={prettyDate(ticket?.createdAt)}
            />
            {ticket?.replies?.map(reply => <Comment
                author={reply?.resolverId}
                content={reply?.content}
                datetime={prettyDate(reply?.createdAt)}
            />)}
            {ticket?.status !== 'closed' && (currentResolvers.indexOf(ticket?.resolver) !== -1 || role === 'admin') ? <Editor
                value={replyText}
                onChange={e => updateReplyText(e.target.value)}
                onReply={() => replyTicket('wait_feedback')}
                onResolve={() => replyTicket('wait_confirm')}
                submitting={replyFetching}/> : ''}
        </div>
    </div>;
}
