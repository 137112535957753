import { ReloadOutlined } from '@ant-design/icons';
import { Button, Table, Radio, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useMount } from 'react-use';
import { SubHeader } from '../../components/sub-header';
import { Tools } from '../../components/tools.components';
import { Fetch, GET_TICKETS, Pagination, State, TicketAction } from '../../redux';
import { TicketStatus } from '../../enums/ticket.enum';

export const TicketsContainer = (props) => {
    const type = props?.match?.params?.type;
    const dispatch = useDispatch();

    const { pagination } = useSelector<State, { pagination: Pagination }>(
        state => ({ pagination: state.pagination.ticket ?? {} as any }),
    );
    const currentResolver = useSelector<State, any>(state => state.session.resolver);
    const [templateId, updateTemplateId] = useState<string>();
    const templates = useSelector<State, any[]>(state => state.ticket.templates);
    const tickets = useSelector<State, any[]>(state => state.ticket.tickets ?? []);
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_TICKETS] ?? {} as any);
    const [processingQuery, updateProcessingQuery] = useState({ orderKey: '', order: '', status: '' });

    useMount(() => {
        dispatch(TicketAction.getTemplates());
        if (type === 'tickets') {
            dispatch(TicketAction.getProcessingTickets({
                page: pagination.page,
                size: pagination.size,
            }));
        } else {
            dispatch(TicketAction.getTickets({
                page: pagination.page,
                size: pagination.size,
            }))
        }
    });

    useEffect(() => {
        if (type === 'tickets') {
            refresh(pagination.page, pagination.size);
        } else {
            dispatch(TicketAction.getTickets({
                page: pagination.page,
                size: pagination.size,
                resolver: type === 'my_tickets' ? currentResolver?.id : undefined
            }))
        }
    }, [type, currentResolver, processingQuery.orderKey, processingQuery.order, processingQuery.status]);

    const onPageChange = (page: number, size: number) => refresh(page, size);
    const handleTableChange = (pagination, filters, sorter) => {
        if (type === 'tickets' && sorter.columnKey) {
            const order = sorter.order === 'ascend' ? 'ASC' : 'DESC';
            updateProcessingQuery({ ...processingQuery, orderKey: sorter.columnKey, order });
        }
    };

    const refresh = (page?: number, size?: number) => {
        if (type === 'tickets') {
            const params = {
                page,
                size,
                ...processingQuery,
                status: processingQuery.status ? processingQuery.status : undefined,
            }
            dispatch(TicketAction.getProcessingTickets({ ...params }));
        } else {
            dispatch(TicketAction.getTickets({
                page,
                size,
            }));
        }
    }

    return <div>
        <SubHeader title="工单管理">
            <Radio.Group
                style={{ marginBottom: 8 }}
                value={type}
                onChange={e => window.location.hash = `#/console/ticket/tickets/${e.target.value}`}>
                <Radio.Button value="tickets">待处理工单</Radio.Button>
                <Radio.Button value="my_tickets">我的工单</Radio.Button>
                <Radio.Button value="all_tickets">所有工单</Radio.Button>
            </Radio.Group>
        </SubHeader>
        <Tools>
            {type === 'all_tickets' ? <Select value={templateId ?? ''} onChange={val => updateTemplateId(val)}>
                <Select.Option value={''}>所有</Select.Option>
                {templates.map(tpl => <Select.Option key={tpl.id} value={tpl.id}>
                    {tpl?.category?.name} / {tpl.name}
                </Select.Option>)}
            </Select> : ''}
            {type === 'tickets' ? <Select onChange={status => updateProcessingQuery({ ...processingQuery, status })} defaultValue={''}>
                <Select.Option value={''}>所有</Select.Option>
                <Select.Option value="processing">处理中</Select.Option>
                <Select.Option value="wait_feedback">待回复</Select.Option>
                <Select.Option value="wait_confirm">待确认</Select.Option>
            </Select> : null}
            <Button
                type="ghost"
                icon={<ReloadOutlined />}
                onClick={() => refresh(pagination.page, pagination.size)}
            />
        </Tools>
        <Table
            rowKey={(record) => record.id}
            loading={fetching}
            dataSource={tickets}
            onChange={handleTableChange}
            pagination={{
                total: pagination.total,
                defaultPageSize: pagination.size,
                pageSizeOptions: ['10', '20', '50', '100'],
                current: pagination.page,
                onShowSizeChange: onPageChange,
                onChange: onPageChange,
                showSizeChanger: true,
            }}>
            <Table.Column
                title="工单号"
                dataIndex="id"
                key="id"
                render={text => <Link to={`/console/ticket/tickets/${type}/${text}`}>{text}</Link>} />
            <Table.Column title="优先级" dataIndex="priority" key="priority" />
            <Table.Column<any>
                title="分类"
                dataIndex="type"
                key="type"
                render={(text, record) => record?.template?.category?.name} />
            <Table.Column<any>
                title="二级分类"
                dataIndex="title"
                key="title"
                render={(text, record) => record?.template?.name} />
            <Table.Column<any>
                title="状态"
                dataIndex="status"
                key="status"
                render={text => TicketStatus[text]}
            />
            <Table.Column<any>
                title="内容"
                dataIndex="content"
                key="content"
            />
            <Table.Column
                title="创建时间"
                dataIndex="createdAt"
                key="createdAt"
                sorter={true}
                render={(text) => moment(text).format('YYYY-MM-DD hh:mm')}
            />
            <Table.Column
                title="更新时间"
                dataIndex="updatedAt"
                key="updatedAt"
                sorter={true}
                render={(text) => moment(text).format('YYYY-MM-DD hh:mm')}
            />
        </Table>
    </div>;
}
