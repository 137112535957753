import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { State } from '../redux';
import { Session } from '../redux/interfaces/session.interface';
import { Roles } from '../enums/roles.enum';

export const RedirectContainer = () => {
    const { role } = useSelector<State, Session>(state => state.session);

    let path = '/console/instances';
    switch (role) {
        case Roles.BUSINESS:
            path = '/console/vouchers';
            break;
        case Roles.SALE:
            path = '/console/vouchers';
            break;
        case Roles.FINANCE:
            path = '/console/bills';
            break;
        default: 
            break;
    }

    return <Redirect to={path} />;
}