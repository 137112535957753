export const toolbar = `
undo redo | formatselect | bold italic backcolor |
alignleft aligncenter alignright alignjustify |
bullist numlist outdent indent | removeformat | code | help
`;

export const plugins = [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount'
];

export const scriptSrc = '/tinymce/tinymce.min.js';
