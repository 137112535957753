export const FETCH = 'fetch';
export const MODAL = 'modal';
export const PAGINATION = 'pagination';
export const REGION = 'region';
export const CLUSTER = 'cluster';
export const PRODUCT = 'product';
export const BILLING_ITEM = 'billing_item';
export const VOUCHER = 'voucher';
export const VOUCHER_INSTANCE = 'voucher_instance';
export const RULE = 'rule';
export const SESSION = 'session';
export const BILL = 'bill';
export const ACCOUNT = 'account';
export const ROLE = 'role';
export const NOTIFICATION = 'notification';
export const INSTANCE = 'instance';
export const TICKET = 'ticket';
export const TICKETS = 'tickets';
export const SUPPORT = 'support';
export const INSTANCE_DETAIL = 'instance_detail';
