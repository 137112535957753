import * as React from 'react';
import { useMount } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import {
    Fetch,
    GET_VOUCHERS,
    ListModel,
    Pagination,
    State,
    VoucherAction,
} from "../../redux";
import { Button, Radio, Table } from "antd";
import { Tools } from "../../components/tools.components";
import { ReloadOutlined } from "@ant-design/icons";
import { SubHeader } from "../../components/sub-header";
import { Link } from "react-router-dom";
import { Voucher } from "../../redux/interfaces/voucher.interface";
import { useState } from "react";
import { CreateVoucherModal } from "./create-voucher.modal";
import { DeleteVoucherModal } from "./delete-voucher.modal";
import { VerifyingVoucherModal } from "./verifying-voucher.modal";
import { UpdateVoucherModal } from "./update-voucher.modal";
import { Session } from "../../redux/interfaces/session.interface";

export const VoucherStatisticContainer = (props) => {
    const dispatch = useDispatch();
    const { results, entities } = useSelector<State, ListModel<Voucher>>(state => ({
        results: state.voucher.results,
        entities: state.voucher.entities,
    }));
    const { pagination } = useSelector<State, { pagination: Pagination }>(state => ({
        pagination: state.pagination.voucher ?? {} as any,
    }));
    const { role } = useSelector<State, Session>(state => state.session);
    const [status] = useState();
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_VOUCHERS] ?? {} as any);
    const vouchers = results.map(id => entities[id]);
    const refresh = () => {
        dispatch(VoucherAction.getVouchers(status, pagination.page, pagination.size));
    };
    useMount(() => dispatch(VoucherAction.getVouchers(status, pagination.page, pagination.size)));
    const onPageChange = (page: number, size: number) => dispatch(VoucherAction.getVouchers(status, page, size));

    return <div>
        <SubHeader title="代金券统计">
            <Radio.Group
                style={{ marginBottom: 8 }}
                value={'statistic'}
                onChange={e => window.location.hash = `#/console/vouchers/${e.target.value}`}>
                <Radio.Button value="list">代金券管理</Radio.Button>
                <Radio.Button value="statistic">代金券统计</Radio.Button>
            </Radio.Group>
        </SubHeader>
        <Tools>
            <Button type="ghost" icon={<ReloadOutlined/>} onClick={() => refresh()}/>
        </Tools>
        <div style={{ overflowY: 'auto' }}>
            <Table
                rowKey={record => record.id}
                loading={fetching}
                dataSource={vouchers}
                pagination={{
                    total: pagination.total,
                    defaultPageSize: pagination.size,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '50', '100'],
                    current: pagination.page,
                    onChange: onPageChange as any,
                }}>
                <Table.Column<Voucher>
                    title="代金券名称"
                    dataIndex="name"
                    key="name"/>
                <Table.Column title="状态" dataIndex="status" key="status"/>
                <Table.Column title="已发行数量" dataIndex="published" key="published"/>
                {['admin', 'business'].includes(role) ? <Table.Column<any>
                    title="总数量"
                    dataIndex="total"
                    key="total"
                    render={(text, record) => {
                        const total = record.statistics ? record.statistics[0]?.total : -1;
                        return total === -1 ? '--' : total;
                    }}/> : ''
                }
                {['admin', 'business'].includes(role) ? <Table.Column<any>
                    title="总额度"
                    dataIndex="amount"
                    key="amount"
                    render={(text, record) => {
                        return record.statistics ? record.statistics[0]?.amount : '--';
                    }}/> : ''
                }
                {['admin', 'business'].includes(role) ? <Table.Column<any>
                    title="已使用额度"
                    dataIndex="usedAmount"
                    key="usedAmount"
                    render={(text, record) => {
                        return record.statistics ? record.statistics[0]?.usedAmount : '--';
                    }}/> : ''
                }
                {['admin', 'business'].includes(role) ? <Table.Column<any>
                    title="已激活额度"
                    dataIndex="activeAmount"
                    key="activeAmount"
                    render={(text, record) => {
                        return record.statistics ? record.statistics[0]?.activeAmount : '--';
                    }}/> : ''
                }
                {['admin', 'business'].includes(role) ? <Table.Column<any>
                    title="剩余额度"
                    dataIndex="balance"
                    key="balance"
                    render={(text, record) => {
                        return record.statistics ? record.statistics[0]?.balance : '--';
                    }}/> : ''
                }
            </Table>
        </div>
        <CreateVoucherModal/>
        <UpdateVoucherModal/>
        <DeleteVoucherModal/>
        <VerifyingVoucherModal/>
    </div>
}
