import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { VoucherAction } from "../../redux/actions";
import { Fetch, GET_VOUCHER, State } from "../../redux";
import { Breadcrumb, Button, Input, InputNumber, Select, Table, DatePicker, Form, Row, Col } from "antd";
import { Tools } from "../../components/tools.components";
import { ReloadOutlined } from "@ant-design/icons";
import { SubHeader } from "../../components/sub-header";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Voucher } from "../../redux/interfaces/voucher.interface";
import { Rule } from "../../redux/interfaces/rule.interface";
import moment from 'moment';
import { Session } from "../../redux/interfaces/session.interface";
import { RBAC } from "../../components/rbac.component";

export const VoucherRulesContainer = (props) => {
    const dispatch = useDispatch();
    const voucherId = props?.match?.params?.voucherId;
    const { voucher } = useSelector<State, { voucher: Voucher }>(state => ({
        voucher: state.voucher.entities[voucherId],
    }));
    const { role } = useSelector<State, Session>(state => state.session);
    const { rules } = useSelector<State, { rules: Rule[] }>(state => ({
        rules: state.rule.results.map(id => state.rule.entities[id])
    }));
    const [editable, updateEditable] = useState(false);
    const [dataSource, updateDataSource] = useState([]);
    const [form] = Form.useForm();

    const init = () => {
        const map = new Map<string, any>();
        voucher?.rules.forEach(rule => {
            if (!map.has(rule.ruleId)) {
                map.set(rule.ruleId, {
                    ruleId: rule.ruleId,
                    params: rule?.rule?.params,
                    options: rule?.rule?.options,
                    name: rule?.rule?.name,
                    rules: {},
                });
            }

            map.get(rule.ruleId).rules[rule.key] = rule.value;
        });

        // @ts-ignore
        updateDataSource([...map.values()]);
        updateEditable(false);
    }

    useEffect(() => {
        init();
    }, [voucher]);

    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_VOUCHER] ?? {} as any);
    const columns = [
        {
            title: '规则ID',
            dataIndex: 'ruleId',
            key: 'ruleId',
            editable: true,
        },
        {
            title: '规则',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return editable ? <Form.Item>
                    <Select value={String(record.ruleId)} onChange={val => {
                        record.ruleId = val;
                        const rule = rules.filter(i => String(i.ruleNo) === String(val))[0];
                        record.params = rule.params;
                        record.options = rule.options;
                        record.name = rule.name;
                        updateDataSource([...dataSource]);
                    }} style={{ width: '100%' }}>
                        {rules.map(rule => <Select.Option
                            disabled={!!dataSource.filter(ds => String(ds.ruleId) === String(rule.ruleNo)).length}
                            key={rule.ruleNo}
                            value={String(rule.ruleNo)}>
                            {rule.name}
                        </Select.Option>)}
                    </Select>
                </Form.Item> : text;
            },
        },
        {
            title: '规则详情',
            dataIndex: 'regions',
            key: 'regions',
            editable: true,
            render: (text, record) => {
                const params = record?.params?.split(',') ?? [];
                return <Row>
                    {
                        params.map(param => {
                            const [key, unit] = param?.split(':');
                            switch (unit) {
                                case 'array':
                                    const values = (record.rules[key] ?? '').split(',').filter(i => i);
                                    const options = (record.options ?? '').split(',').filter(i => i);
                                    if (editable) {
                                        return <Col span={Math.floor(24 / params.length)} key={`${record.ruleId}:${key}:${unit}`} >
                                            <Form.Item
                                                name={`${record.ruleId}:${key}:${unit}`}
                                                rules={[{ required: true, message: '规则详情不能为空'}]}
                                                initialValue={values}
                                            >
                                                <Select
                                                    mode={options.length ? 'multiple' : 'tags'}
                                                    value={values}
                                                    onChange={val => {
                                                        record.rules[key] = val.join(',');
                                                        updateDataSource([...dataSource]);
                                                    }}
                                                    style={{ width: '100%', marginRight: 10 }}>
                                                    {options.map(opt => <Select.Option key={opt} value={opt}>{opt}</Select.Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    }
                                    return <span style={{ marginRight: 10 }} key={key}>{record.rules[key]}</span>
                                case 'string':
                                    if (editable) {
                                        return <Col span={Math.floor(24 / params.length)} key={`${record.ruleId}:${key}:${unit}`} >
                                            <Form.Item
                                                name={`${record.ruleId}:${key}:${unit}`}
                                                rules={[{ required: true, message: '规则详情不能为空'}]}
                                                initialValue={record.rules[key]}
                                            >
                                                <Input
                                                value={record.rules[key]}
                                                onChange={e => {
                                                    record.rules[key] = e.target.value;
                                                    updateDataSource([...dataSource]);
                                                }}
                                                style={{ marginRight: 10 }}/>
                                            </Form.Item>
                                        </Col>
                                    }
                                    return <span style={{ marginRight: 10 }} key={key}>{record.rules[key]}</span>
                                case 'number':
                                    if (editable) {
                                        return <Col span={Math.floor(24 / params.length)} key={`${record.ruleId}:${key}:${unit}`} >
                                            <Form.Item
                                                name={`${record.ruleId}:${key}:${unit}`}
                                                rules={[{ required: true, message: '规则详情不能为空'}]}
                                                initialValue={record.rules[key]}
                                            >
                                                <InputNumber
                                                    min={0.01}
                                                    value={record.rules[key]}
                                                    onChange={val => {
                                                        record.rules[key] = val;
                                                        updateDataSource([...dataSource]);
                                                    }}
                                                style={{ marginRight: 10 }}/>
                                            </Form.Item>
                                        </Col>
                                    }
                                    return <span style={{ marginRight: 10 }} key={key}>{record.rules[key]}</span>
                                case 'date':
                                    const time = record.rules ? record.rules[key] : undefined;
                                    if (editable) {
                                        return <Col span={Math.floor(24 / params.length)} key={`${record.ruleId}:${key}:${unit}`} >
                                            <Form.Item
                                                name={`${record.ruleId}:${key}:${unit}`}
                                                rules={[{ required: true, message: '规则详情不能为空'}]}
                                                initialValue={time ? moment(Number(time)) : undefined}
                                            >
                                                <DatePicker
                                                    value={time ? moment(Number(time)) : undefined}
                                                    format="YYYY-MM-DD HH:mm"
                                                    showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                                                    style={{ marginRight: 10 }}
                                                    onOk={val => {
                                                        record.rules[key] = val.valueOf();
                                                        updateDataSource([...dataSource]);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    }
                                    return <span style={{ marginRight: 10 }} key={key}>
                                        {time ? moment(time).format('YYYY-MM-DD HH:mm') : ''}
                                    </span>
                            }
                            return <div key={key}>{key}: {record.rules[key]}</div>
                        })
                    }
                </Row>
            }
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (text, record) => {
                return <>
                    {editable ? <Button
                        danger={true}
                        type="link"
                        onClick={() => {
                            const newDataSource = dataSource.filter(i => i.ruleId !== record.ruleId);
                            updateDataSource(newDataSource);
                        }}>
                        删除
                    </Button> : ''}
                </>
            }
        }
    ];
    const refresh = () => {
        dispatch(VoucherAction.getVoucher(voucherId));
    };

    const save = async () => {
        await form.validateFields();
        const currentRules = form.getFieldsValue();
        const result = [];
        Object.keys(currentRules).map(item => {
            const [ruleId, key, unit] = item.split(':');
            const value = unit === 'array' ? currentRules[item].join(',') : currentRules[item];
            result.push({
                ruleId,
                key,
                value,
                unit,
            });
        });

        updateEditable(false);
        dispatch(VoucherAction.setVoucherRules(voucherId, result));
    }

    useEffect(() => {
        refresh();
        dispatch(VoucherAction.getRules());
    }, [voucherId]);

    return <div>
        <SubHeader title={<Breadcrumb>
            <Breadcrumb.Item><Link to="/console/vouchers">代金券管理</Link></Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={`/console/vouchers/${voucherId}`}>{voucher?.name ?? voucherId}</Link>
            </Breadcrumb.Item>
        </Breadcrumb>}/>
        <Tools>
            <RBAC roles={['admin', 'business']} role={role}>
                {editable ? <Button
                    onClick={() => init()}>
                    取消
                </Button> : <Button
                    type="primary"
                    disabled={['enable', 'verifying'].includes(voucher.status)}
                    onClick={() => updateEditable(!editable)}>
                    编辑
                </Button>}
                {editable ? <Button
                    disabled={dataSource.length !== dataSource.filter(item => item.ruleId).length}
                    type="primary"
                    onClick={() => updateDataSource([{ ruleId: '', rules: {} }, ...dataSource])}>
                    增加
                </Button> : ''}
                {editable ? <Button
                    type="primary"
                    onClick={() => save()}>
                    保存
                </Button> : ''}
            </RBAC>
            <Button type="ghost" icon={<ReloadOutlined/>} onClick={() => refresh()}/>
        </Tools>
        <div style={{ overflowY: 'auto' }}>
            <Form form={form}>
                <Table
                    style={{ minWidth: 960 }}
                    loading={fetching}
                    rowKey={record => record.ruleId}
                    rowClassName={() => 'editable-row'}
                    bordered
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                />
            </Form>
        </div>
    </div>
}
