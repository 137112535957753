import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ProductAction, RegionAction } from "../../redux";
import { Fetch, GET_BILLING_ITEMS, ListModel, Pagination, Product, Region, State } from "../../redux";
import { Breadcrumb, Button, Card, Select, Table } from "antd";
import { Tools } from "../../components/tools.components";
import { ModalAction } from "../../redux";
import { CREATE_BILLING_ITEM_MODAL } from "../../redux/constants/modal.constants";
import { ReloadOutlined } from "@ant-design/icons";
import { SubHeader } from "../../components/sub-header";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { BillingItem } from "../../redux/interfaces/billing-item.interface";
import { CreateBillingItemModal } from "./create-billing-item.modal";
import { prettyDate } from "../../helpers/date.helper";
import { BillingMode } from "../../enums/billing-mode.enum";

export const BillingItemsContainer = (props) => {
    const dispatch = useDispatch();
    const productId = props?.match?.params?.productId;
    const { product } = useSelector<State, { product: Product }>(state => ({
        product: state.product.entities[productId],
    }));
    const { pagination } = useSelector<State, { pagination: Pagination }>(state => ({
        pagination: state.pagination.billingItem ?? {} as any,
    }));
    const { regions } = useSelector<State, { regions: Region[] }>(state => ({
        regions: state.region.results.map(id => state.region.entities[id]),
    }));
    const [status, updateStatus] = useState<string | undefined>();
    const [billingMode, updateBillingMode] = useState<string | undefined>();
    const [regionId, updateRegionId] = useState<string | undefined>();

    const { results, entities } = useSelector<State, ListModel<BillingItem>>(state => ({
        results: state.billingItem.results,
        entities: state.billingItem.entities,
    }));
    const billingItems = results.map(id => entities[id]);
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_BILLING_ITEMS] ?? {} as any);
    const columns = [
        {
            title: '计费项',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return <div style={{ position: 'relative' }}>
                    {record.soldOut ? <div style={{
                        position: 'absolute',
                        border: '1px solid #1890ff',
                        height: 24,
                        width: 24,
                        color: '#1890ff',
                        textAlign: 'center',
                        borderRadius: 4,
                        top: '50%',
                        transform: 'translate(0, -50%)'
                    }}>
                        罄
                    </div> : ''}
                    <div style={{ marginLeft: 28 }}>{record.name}({record.type})</div>
                    <div style={{ marginLeft: 28 }}>{record.description}</div>
                </div>;
            }
        },
        {
            title: '计费模式',
            dataIndex: 'billingMode',
            key: 'billingMode',
            render: text => BillingMode[text],
        },
        {
            title: '区域',
            dataIndex: 'regions',
            key: 'regions',
            render: (text, record) => {
                return `${record?.region?.platformName} / ${record?.region?.name}`;
            }
        },
        {
            title: '单价',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: '限制',
            render: record => {
                return <div>
                    <div>最大值：{record.max}</div>
                    <div>最小值：{record.min}</div>
                    <div>阈值：{record.threshold}</div>
                </div>
            },
        },
        {
            title: '单位',
            dataIndex: 'unit',
            key: 'unit',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: '创建/修改时间',
            dataIndex: 'time',
            key: 'time',
            render: (text, record) => {
                return <>
                    <div>{prettyDate(record.createdAt)}</div>
                    <div>{prettyDate(record.updatedAt)}</div>
                </>;
            }
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            fixed: 'right',
            render: (text, record) => {
                return <>
                    {record.status !== 'publish' ? <Button
                        type="link"
                        onClick={() => dispatch(ProductAction.updateBillingItemStatus(
                            productId,
                            record.id,
                            'publish',
                        ))}>
                        上架
                    </Button> : ''}
                    {record.status !== 'publish' ? <Button
                        type="link"
                        danger={true}
                        onClick={() => dispatch(ProductAction.deleteBillingItem(
                            productId,
                            record.id,
                        ))}>
                        删除
                    </Button> : ''}
                    {record.status === 'publish' ? <Button
                        type="link"
                        onClick={() => dispatch(ProductAction.updateBillingItemSoldOut(
                            productId,
                            record.id,
                            !record.soldOut
                        ))}>
                        {record.soldOut ? '取消售罄' : '售罄'}
                    </Button> : ''}
                </>
            }
        }
    ];
    const refresh = (page: number, size: number, obj = {}) => {
        dispatch(ProductAction.getBillingItems(productId, {
            page,
            size,
            regionId,
            status,
            billingMode,
            ...obj,
        }));
    };

    useEffect(() => {
        dispatch(RegionAction.getRegions());
        dispatch(ProductAction.getProduct(productId));
        refresh(pagination.page, pagination.size);
    }, [productId]);

    const onPageChange = (page: number, size: number) => refresh(page, size);

    const onStatusChange = (status) => {
        updateStatus(status ? status : undefined);
        refresh(pagination.page, pagination.size, { status: status ? status : undefined });
    }
    const onBillingModeChange = (billingMode) => {
        updateBillingMode(billingMode ? billingMode : undefined);
        refresh(pagination.page, pagination.size, { billingMode: billingMode ? billingMode : undefined });
    }
    const onRegionChange = (regionId) => {
        updateRegionId(regionId ? regionId : undefined);
        refresh(pagination.page, pagination.size, { regionId: regionId ? regionId : undefined });
    }

    return <div>
        <SubHeader title={<Breadcrumb>
            <Breadcrumb.Item><Link to="/console/products">产品管理</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link
                to={`console/products/${productId}`}>{product?.name ?? productId}</Link></Breadcrumb.Item>
        </Breadcrumb>}/>
        <Card title={product?.name ?? productId} bodyStyle={{ padding: 20 }} style={{ marginTop: 20 }}>
            <ul className="list">
                <li><span className="title">产品名称</span><span>{product?.name}</span></li>
                <li><span className="title">产品类型</span><span>{product?.type}</span></li>
                <li><span className="title">描述</span><span>{product?.description}</span></li>
                <li><span className="title">创建时间</span><span>{prettyDate(product?.createdAt)}</span></li>
                <li><span className="title">修改时间</span><span>{prettyDate(product?.updatedAt)}</span></li>
            </ul>
        </Card>
        <Tools>
            <Select value={regionId ?? ''} onChange={val => onRegionChange(val)}>
                <Select.Option value="">全部</Select.Option>
                {regions.map(region => <Select.Option key={region.id} value={region.id as string}>
                    {region.platformName} / {region.name}
                </Select.Option>)}
            </Select>
            <Select value={billingMode ?? ''} onChange={val => onBillingModeChange(val)}>
                <Select.Option value="">全部</Select.Option>
                <Select.Option value="prepay">预付费</Select.Option>
                <Select.Option value="afterpay">后付费</Select.Option>
            </Select>
            <Select value={status ?? ''} onChange={val => onStatusChange(val)}>
                <Select.Option value="">全部</Select.Option>
                <Select.Option value="publish">上架</Select.Option>
                <Select.Option value="unpublish">下架</Select.Option>
            </Select>
            <Button
                type="primary"
                onClick={() => dispatch(ModalAction.open(CREATE_BILLING_ITEM_MODAL, {
                    productId,
                    product: product?.type,
                }))}>
                创建计费项
            </Button>
            <Button type="ghost" icon={<ReloadOutlined/>} onClick={() => refresh(pagination.page, pagination.size)}/>
        </Tools>
        <Table
            loading={fetching}
            dataSource={billingItems}
            columns={columns as any}
            pagination={{
                total: pagination.total,
                defaultPageSize: pagination.size,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
                current: pagination.page,
                onShowSizeChange: onPageChange,
                onChange: onPageChange,
            }}/>
        <CreateBillingItemModal refresh={() => refresh(pagination.page, pagination.size)}/>
    </div>
}
