import { Client } from "../client";
import { Dispatch } from "redux";
import { Action, GET_SESSION, LOGIN } from "..";
import { encrypt } from "../../helpers/password.helper";
import { SESSION } from "../constants/action-types.constants";

export class AuthAction {
    private static client = new Client();

    public static getCurrent() {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { email, accountId, role, resolver } = await this.client.get('/api/current-account', {
                dispatch,
                fetch: GET_SESSION,
            });
            dispatch(new Action(SESSION).put('email', email));
            dispatch(new Action(SESSION).put('accountId', accountId));
            dispatch(new Action(SESSION).put('role', role));
            dispatch(new Action(SESSION).put('resolver', resolver));
        }
    }

    public static login(email: string, password: string, code: string, fn: Function) {
        return async (dispatch: Dispatch<any>, state: any) => {
            try {
                await this.client.post('/api/sign-in',
                    {
                        email,
                        password: encrypt(password, 'yanrong-tech-web', 'yanrong'),
                        code,
                        type: 'operation_login'
                    },
                    {
                        dispatch,
                        fetch: LOGIN,
                    },
                );
                await dispatch(this.getCurrent());
                window.location.hash = '/console';
            } catch (e) {
            }
            fn();
        }
    }

    public static signOut() {
        return async (dispatch: Dispatch<any>, state: any) => {
            try {
                await this.client.post('/api/sign-out',
                    null,
                    {
                        dispatch,
                    },
                );
                window.location.hash = '/sign-in';
            } catch (e) {
            }
        }
    }
}
