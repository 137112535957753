import React from 'react';
import { Route } from 'react-router-dom';
import { MessageContainer } from './message.container';
import { CreateMessageContainer } from './create-message.container';
import { TemplatesContainer } from "./templates.container";

export const NotificationContainer = (props) => {
    return <div>
        <Route path="/console/notification/messages" exact component={MessageContainer}/>
        <Route path="/console/notification/create-message" exact component={CreateMessageContainer}/>
        <Route path="/console/notification/templates" exact component={TemplatesContainer}/>
    </div>
}
