import { Dispatch } from "redux";
import { GET_BILLS } from "../constants/fetch.constants";
import { Action } from "../action";
import { BILL } from "../constants/action-types.constants";
import { Client } from "../client";
import { BillQuery } from "../interfaces";

export class BillAction {
    private static client = new Client();

    public static getBills(params: BillQuery) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { bills } = await this.client.get('/api/bills', {
                params,
                dispatch,
                fetch: GET_BILLS,
            });
            dispatch(new Action(BILL).put('bills', bills));
        }
    }
}
