import { Dispatch } from 'redux';
import {
    ENABLE_NOTIFICATION_TEMPLATE,
    GET_NOTIFICATION_TEMPLATES, ModalAction,
    PaginationAction, SEND_TEST_EMAIL, SEND_TEST_SMS,
    UPDATE_NOTIFICATION_TEMPLATE
} from '..';
import { Action } from '../action';
import { Client } from '../client';
import { NOTIFICATION } from '../constants/action-types.constants';
import {
    CREATE_MESSAGE,
    GET_MESSAGES,
    GET_BROADCASTS,
    CREATE_BROADCAST,
    PUBLISH_MESSAGE,
    PUBLISH_BROADCAST,
} from '../constants/fetch.constants';
import { CreateBroadcast, CreateMessage } from '../interfaces/notification.interface';
import {
    SEND_TEST_EMAIL_MODAL,
    SEND_TEST_SMS_MODAL, UPDATE_EMAIL_NOTIFY_TPL_MODAL, UPDATE_MESSAGE_NOTIFY_TPL_MODAL,
    UPDATE_NOTIFICATION_TEMPLATE_MODAL, UPDATE_NOTIFY_METHOD_MODAL, UPDATE_SMS_NOTIFY_TPL_MODAL, UPDATE_STYLE_MODAL
} from "../constants/modal.constants";

export class NotificationAction {
    private static client = new Client();

    public static getMessage(id: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const message = await this.client.get(`/api/messages/${id}`);
            dispatch(new Action(NOTIFICATION).put('message', message));
        };
    }

    public static getTemplates() {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { templates } = await this.client.get('/api/notification-templates', {
                dispatch,
                fetch: GET_NOTIFICATION_TEMPLATES,
            });
            dispatch(new Action(NOTIFICATION).put('templates', templates));
        };
    }

    public static enableTemplate(templateId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(
                `/api/notification-templates/${templateId}/actions/enable`,
                null,
                {
                    dispatch,
                    fetch: ENABLE_NOTIFICATION_TEMPLATE,
                },
            );
            dispatch(this.getTemplates());
        };
    }

    public static disableTemplate(templateId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(
                `/api/notification-templates/${templateId}/actions/disable`,
                null,
                {
                    dispatch,
                    fetch: ENABLE_NOTIFICATION_TEMPLATE,
                },
            );
            dispatch(this.getTemplates());
        };
    }

    public static updateTemplate(templateId: string, data) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(
                `/api/notification-templates/${templateId}`,
                data,
                {
                    dispatch,
                    fetch: UPDATE_NOTIFICATION_TEMPLATE,
                },
            );

            dispatch(ModalAction.close(UPDATE_EMAIL_NOTIFY_TPL_MODAL));
            dispatch(ModalAction.close(UPDATE_SMS_NOTIFY_TPL_MODAL));
            dispatch(ModalAction.close(UPDATE_MESSAGE_NOTIFY_TPL_MODAL));
            dispatch(ModalAction.close(UPDATE_NOTIFY_METHOD_MODAL));
            dispatch(ModalAction.close(UPDATE_STYLE_MODAL));
            dispatch(this.getTemplates());
        };
    }

    public static sendTestEmail(templateId: string, email, params?: { [key: string]: string }) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(
                `/api/notification-templates/${templateId}/actions/email-test`,
                { email, params },
                {
                    dispatch,
                    fetch: SEND_TEST_EMAIL,
                    showMessage: false,
                },
            );

            dispatch(ModalAction.close(SEND_TEST_EMAIL_MODAL));
        };
    }

    public static sendTestSms(templateId: string, phone, params?: { [key: string]: string }) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(
                `/api/notification-templates/${templateId}/actions/sms-test`,
                { phone, params },
                {
                    dispatch,
                    fetch: SEND_TEST_SMS,
                    showMessage: false,
                },
            );

            dispatch(ModalAction.close(SEND_TEST_SMS_MODAL));
        };
    }

    public static getMessages(currentPage: number, currentSize: number) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { list, page, size, total } = await this.client.get('/api/messages', {
                params: {
                    page: currentPage,
                    size: currentSize,
                },
                dispatch,
                fetch: GET_MESSAGES,
            });
            dispatch(new Action(NOTIFICATION).put('messages', list));
            dispatch(PaginationAction.update('message', page, size, total, list.length));
        };
    }

    public static createMessage(values: CreateMessage, callback: () => void) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.post('/api/messages', {
                ...values,
            }, {
                dispatch,
                fetch: CREATE_MESSAGE,
            });
            callback();
        };
    }

    public static deleteMessage(messageId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const pagination = state().pagination.message ?? {} as any;
            await this.client.delete(`/api/messages/${messageId}`);
            dispatch(this.getMessages(pagination.page, pagination.size));
        };
    }

    public static publishMessage(messageId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const pagination = state().pagination.message ?? {} as any;
            await this.client.put(`/api/messages/${messageId}/actions/publish`, {}, {
                dispatch,
                fetch: PUBLISH_MESSAGE,
            });
            dispatch(this.getMessages(pagination.page, pagination.size));
        };
    }

    public static getBroadcasts(params) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { list, page, size, total } = await this.client.get('/api/broadcasts', {
                params: {
                    ...params,
                },
                dispatch,
                fetch: GET_BROADCASTS,
            });
            dispatch(new Action(NOTIFICATION).put('broadcasts', list));
            dispatch(PaginationAction.update('broadcast', page, size, total, list.length));
        };
    }

    public static createBroadcast(values: CreateBroadcast, callback: () => void) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.post('/api/broadcasts', {
                ...values,
            }, {
                dispatch,
                fetch: CREATE_BROADCAST,
            });
            callback();
        };
    }

    public static deleteBroadcast(broadcastId: string, refresh: (page: number, size: number) => void) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const pagination = state().pagination.broadcast ?? {} as any;
            await this.client.delete(`/api/broadcasts/${broadcastId}`);
            refresh(pagination.page, pagination.size);
        };
    }

    public static publishBroadcast(broadcastId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const pagination = state().pagination.broadcast ?? {} as any;
            await this.client.put(`/api/broadcasts/${broadcastId}/actions/publish`, {}, {
                dispatch,
                fetch: PUBLISH_BROADCAST,
            });
        };
    }
}
