import * as React from 'react';
import { useMount } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { Fetch, GET_INSTANCES, Instance, InstanceAction, ListModel, Pagination, State } from "../../redux";
import { Button, Input, Table } from "antd";
import { Tools } from "../../components/tools.components";
import { ReloadOutlined } from "@ant-design/icons";
import { SubHeader } from "../../components/sub-header";
import { prettyDate } from "../../helpers/date.helper";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

export const InstancesContainer = (props) => {
    const dispatch = useDispatch();
    const { results, entities } = useSelector<State, ListModel<Instance>>(state => ({
        results: state.instance.results,
        entities: state.instance.entities,
    }));
    const { pagination } = useSelector<State, { pagination: Pagination }>(state => ({
        pagination: state.pagination.instance ?? {} as any,
    }));
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_INSTANCES] ?? {} as any);
    const instances = results.map(id => entities[id]);

    useMount(() => dispatch(InstanceAction.getInstances({})));

    const [instanceId, updateInstanceId] = useState<string | undefined>();
    const [accountId, updateAccountId] = useState<string | undefined>();
    const [status, updateStatus] = useState<string | undefined>();

    useEffect(() => {
        dispatch(InstanceAction.getInstances({
            accountId: accountId || undefined,
            status: status || undefined,
            id: instanceId || undefined,
            page: pagination.page,
            size: pagination.size,
        }));
    }, [status, accountId, instanceId]);

    const refresh = (page: number, size: number) => dispatch(InstanceAction.getInstances({
        page,
        size,
        accountId,
        id: instanceId,
        status,
    }));

    const onPageChange = (page: number, size: number) => refresh(page, size);

    return <div>
        <SubHeader title="实例管理"/>
        <Tools>
            <Input.Search
                defaultValue={instanceId}
                onSearch={val => updateInstanceId(val)}
                onChange={e => {
                    if (e.target.value === '') {
                        updateInstanceId(undefined);
                    }
                }}
                placeholder="实例ID"/>
            <Input.Search
                defaultValue={accountId}
                onSearch={val => updateAccountId(val)}
                onChange={e => {
                    if (e.target.value === '') {
                        updateAccountId(undefined);
                    }
                }}
                placeholder="账户ID / Email"/>
            <Button type="ghost" icon={<ReloadOutlined/>} onClick={() => refresh(pagination.page, pagination.size)}/>
        </Tools>
        <Table
            rowKey={record => record.id as string}
            loading={fetching}
            dataSource={instances}
            pagination={{
                total: pagination.total,
                defaultPageSize: pagination.size,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
                current: pagination.page,
                onShowSizeChange: onPageChange,
                onChange: onPageChange,
            }}>
            <Table.Column title="实例名称" dataIndex="name" key="name" />
            <Table.Column title="实例ID" dataIndex="id" key="id" render={(id) => <Link to={`/console/instances/${id}`}>{id}</Link>} />
            <Table.Column title="账号" dataIndex="email" key="email" />
            <Table.Column<any>
                title="产品"
                dataIndex="type"
                key="type"
                render={(text, record) => record?.product?.name}/>
            <Table.Column<any>
                title="所属集群"
                dataIndex="clusterId"
                key="clusterId"
                render={(text, record) => record?.cluster?.name}/>
            <Table.Column<any>
                title="所属区域"
                dataIndex="regionId"
                key="regionId"
                render={(text, record) => `${record?.region?.platformName}/${record?.region?.name}`}/>
            <Table.Column title="付费模式" dataIndex="billingMode" key="billingMode"/>
            <Table.Column title="状态" dataIndex="status" key="status"/>
            <Table.Column title="过期时间" dataIndex="expireTime" key="expireTime" render={text => prettyDate(text)}/>
        </Table>
    </div>
}
