import { Alert, Form, Modal, Checkbox } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Fetch,
    FetchAction,
    ModalAction,
    NotificationAction,
    State,
    UPDATE_NOTIFICATION_TEMPLATE,
} from '../../redux';
import './create-message.container.less';
import { UPDATE_NOTIFY_METHOD_MODAL } from "../../redux/constants/modal.constants";

export const UpdateNotifyMethodModal = (props) => {
    const { visible, notifyTypes, id, supports } = useSelector<State, { [key: string]: any }>(
        state => state.modal[UPDATE_NOTIFY_METHOD_MODAL] ?? {},
    );
    const { fetching, error, message } = useSelector<State, Fetch>(
        state => state.fetch[UPDATE_NOTIFICATION_TEMPLATE] ?? {} as any,
    );
    const errorMessage = error ? <Alert message={message} type="error" closable/> : null;
    const [form] = Form.useForm();
    const formLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
    }

    useEffect(() => {
        form.setFieldsValue({ notifyTypes: (notifyTypes ?? '').split(',') });
    }, [notifyTypes]);

    const dispatch = useDispatch();

    const submit = (values) => {
        dispatch(NotificationAction.updateTemplate(id, { notifyTypes: values.notifyTypes.join(',') }));
    };

    const enables = (supports ?? '').split(',');

    return <Modal
        title="编辑短信通知模板"
        visible={visible}
        onOk={() => form.submit()}
        confirmLoading={fetching}
        destroyOnClose={true}
        width={460}
        onCancel={() => {
            dispatch(ModalAction.close(UPDATE_NOTIFY_METHOD_MODAL));
            dispatch(FetchAction.clear(UPDATE_NOTIFICATION_TEMPLATE));
            form.resetFields();
        }}>
        {errorMessage}
        <Form
            {...formLayout}
            form={form}
            onFinish={(values) => submit(values)}
            className="form">
            <Form.Item
                label="通知方式"
                name="notifyTypes"
                initialValue={(notifyTypes ?? '').split(',')}>
                <Checkbox.Group>
                    <Checkbox disabled={!enables.includes('sms')} value="sms" style={{ lineHeight: '32px' }}>
                        短信
                    </Checkbox>
                    <Checkbox disabled={!enables.includes('email')} value="email" style={{ lineHeight: '32px' }}>
                        邮件
                    </Checkbox>
                    <Checkbox disabled={!enables.includes('message')} value="message" style={{ lineHeight: '32px' }}>
                        消息
                    </Checkbox>
                </Checkbox.Group>
            </Form.Item>
        </Form>
    </Modal>
}
