import { Dispatch } from "redux";
import { Client } from "../client";
import { Action } from "../action";
import { CLUSTER } from "../constants/action-types.constants";
import { Cluster, CREATE_CLUSTER, DELETE_CLUSTER, GET_CLUSTERS, ModalAction, UPDATE_CLUSTER } from "..";
import {
    CREATE_CLUSTER_META_MODAL,
    CREATE_CLUSTER_MODAL, DELETE_CLUSTER_META_MODAL,
    DELETE_CLUSTER_MODAL,
    UPDATE_CLUSTER_MODAL
} from "../constants/modal.constants";

export class ClusterAction {
    private static client = new Client();

    public static getClusters() {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { clusters } = await this.client.get('/api/clusters', {
                dispatch,
                fetch: GET_CLUSTERS,
            });
            dispatch(new Action(CLUSTER).init(clusters));
        }
    }

    public static createCluster(cluster: Cluster) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.post('/api/clusters', {
                ...cluster,
                metas: [{ key: 'private_link', value: cluster.privateLink }]
            }, {
                dispatch,
                fetch: CREATE_CLUSTER,
            });
            dispatch(this.getClusters());
            dispatch(ModalAction.close(CREATE_CLUSTER_MODAL));
        }
    }

    public static updateCluster(clusterId: string, cluster: Cluster) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(`/api/clusters/${clusterId}`, cluster, {
                dispatch,
                fetch: UPDATE_CLUSTER,
            });
            dispatch(this.getClusters());
            dispatch(ModalAction.close(UPDATE_CLUSTER_MODAL));
        }
    }

    public static updateClusterMeta(clusterId: string, key: string, value: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(`/api/clusters/${clusterId}/metas/${key}`, { value }, {
                dispatch,
                fetch: UPDATE_CLUSTER,
            });
            dispatch(ModalAction.close(CREATE_CLUSTER_META_MODAL))
            dispatch(this.getClusters());
            dispatch(ModalAction.close(UPDATE_CLUSTER_MODAL));
        }
    }

    public static deleteClusterMeta(clusterId: string, key: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.delete(`/api/clusters/${clusterId}/metas/${key}`, {
                dispatch,
                fetch: UPDATE_CLUSTER,
            });
            dispatch(ModalAction.close(DELETE_CLUSTER_META_MODAL))
            dispatch(this.getClusters());
        }
    }

    public static deleteCluster(clusterId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.delete(`/api/clusters/${clusterId}`, {
                dispatch,
                fetch: DELETE_CLUSTER,
            });
            dispatch(new Action(CLUSTER).delete(clusterId));
            dispatch(ModalAction.close(DELETE_CLUSTER_MODAL));
        }
    }

    public static updateClusterStatus(clusterId: string, status: 'enable' | 'disable') {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(`/api/clusters/${clusterId}/actions/${status}`, null, {
                dispatch,
            });
            dispatch(new Action(CLUSTER).update(clusterId, 'status', status));
        }
    }
}
