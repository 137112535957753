import * as React from 'react';
import { Alert, Button, Form, Input, InputNumber, Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CREATE_TEMPLATE_MODAL } from "../../redux/constants/modal.constants";
import {
    Fetch,
    State,
    ModalAction,
    FetchAction,
    TicketAction,
    CREATE_TEMPLATE
} from "../../redux";
import { useEffect } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import styles from './create-template.modal.m.less';

export const CreateTemplateModal = (props) => {
    const { visible, categoryId } = useSelector<State, { [key: string]: any }>(
        state => state.modal[CREATE_TEMPLATE_MODAL] ?? {} as any,
    );
    const groups = useSelector<State, any[]>(state => state.ticket.groups);
    const { fetching, error, message } = useSelector<State, Fetch>(
        state => state.fetch[CREATE_TEMPLATE] ?? {} as any,
    );
    const errorMessage = error ? <Alert message={message} type="error" closable/> : null;
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        if (visible) {
            dispatch(TicketAction.getGroups());
            form.setFieldsValue({ priority: 0 });
        }
    }, [visible]);


    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    const submit = values => {
        console.log(values);
        dispatch(TicketAction.createTemplate(categoryId, values));
    }
    const confirm = () => {
        form.submit();
    }
    return <Modal
        title="创建模板"
        visible={visible}
        onOk={() => confirm()}
        confirmLoading={fetching}
        width={460}
        onCancel={() => {
            dispatch(ModalAction.close(CREATE_TEMPLATE_MODAL));
            dispatch(FetchAction.clear(CREATE_TEMPLATE));
            form.resetFields();
        }}>
        {errorMessage}
        <Form {...layout} form={form} name="control-ref" onFinish={values => submit(values)}>
            <Form.Item name="name" label="名称" rules={[{ required: true }]}>
                <Input/>
            </Form.Item>
            <Form.Item name="groupId" label="客服组" rules={[{ required: true }]}>
                <Select>
                    {groups.map(group => <Select.Option key={group.id} value={group.id}>
                        {group.name}
                    </Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item name="priority" label="优先级" rules={[{ required: true }]}>
                <InputNumber max={100} min={0}/>
            </Form.Item>
            <Form.List name="fields">
                {(fields, { add, remove }) => (
                    <div className={styles.fields}>
                        {fields.map(field => (
                            <div className={styles.field} key={field.name}>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, curValues) =>
                                        prevValues.field !== curValues.field ||
                                        prevValues.description !== curValues.description
                                    }>
                                    {() => (
                                        <Form.Item
                                            {...field}
                                            label="字段"
                                            name={[field.name, 'field']}
                                            fieldKey={[field.fieldKey, 'field']}
                                            rules={[{ required: true }]}>
                                            <Input/>
                                        </Form.Item>
                                    )}
                                </Form.Item>
                                <Form.Item
                                    {...field}
                                    label="描述"
                                    name={[field.name, 'description']}
                                    fieldKey={[field.fieldKey, 'description']}>
                                    <Input/>
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(field.name)} className={styles.cancel}/>
                            </div>
                        ))}

                        <Form.Item wrapperCol={{ span: 24 }}>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                添加自定义字段
                            </Button>
                        </Form.Item>
                    </div>
                )}
            </Form.List>
        </Form>
    </Modal>;
}
