import * as React from 'react';
import styles from './sub-header.m.less';

interface Props {
    title?: string | React.ReactNode;
    children?: any;
}

export const SubHeader = ({ title, children }: Props) => {
    return <div className={styles.header}>
        <h3>{title}</h3>
        <div className={styles.content}>{children}</div>
    </div>
}
