import * as React from 'react';
import { Redirect, Route } from "react-router-dom";
import { TemplatesContainer } from "./templates.container";
import { ResolversContainer } from "./resolvers.container";
import { TicketsContainer } from "./tickets.container";
import { TicketContainer } from "./ticket.container";

export const Ticket = (props) => {
    return <div style={{ height: '100%' }}>
        <Route path="/console/ticket/tickets" exact={true}>
            <Redirect to="/console/ticket/tickets/tickets"/>
        </Route>
        <Route path="/console/ticket/templates" exact={true} component={TemplatesContainer}/>
        <Route path="/console/ticket/resolvers" exact={true} component={ResolversContainer}/>
        <Route path="/console/ticket/tickets/:type" exact={true} component={TicketsContainer}/>
        <Route path="/console/ticket/tickets/:type/:ticketId" exact={true} component={TicketContainer}/>
    </div>
}
