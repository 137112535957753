import * as React from 'react';
import { Alert, Form, Input, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CREATE_RESOLVER_GROUP_MODAL } from "../../redux/constants/modal.constants";
import {
    Fetch,
    State,
    ModalAction,
    FetchAction,
    TicketAction, CREATE_RESOLVER_GROUP
} from "../../redux";
import { FormInstance } from "antd/lib/form";

export const CreateGroupModal = (props) => {
    const { visible } = useSelector<State, { [key: string]: any }>(
        state => state.modal[CREATE_RESOLVER_GROUP_MODAL] ?? {} as any,
    );
    const { fetching, error, message } = useSelector<State, Fetch>(
        state => state.fetch[CREATE_RESOLVER_GROUP] ?? {} as any,
    );
    const errorMessage = error ? <Alert message={message} type="error" closable/> : null;
    const dispatch = useDispatch();

    const formRef = React.createRef<FormInstance>();
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    const submit = values => {
        dispatch(TicketAction.createGroup(values.name));
    }
    const confirm = () => {
        (formRef.current as any).submit();
    }
    return <Modal
        title="创建客服组"
        visible={visible}
        onOk={() => confirm()}
        confirmLoading={fetching}
        width={460}
        onCancel={() => {
            dispatch(ModalAction.close(CREATE_RESOLVER_GROUP_MODAL));
            dispatch(FetchAction.clear(CREATE_RESOLVER_GROUP));
            (formRef.current as any).resetFields();
        }}>
        {errorMessage}
        <Form {...layout} ref={formRef} name="control-ref" onFinish={values => submit(values)}>
            <Form.Item name="name" label="名称" rules={[{ required: true }]}>
                <Input/>
            </Form.Item>
        </Form>
    </Modal>;
}
