import React, { useEffect } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../redux';
import { CREATE_ACCOUNT_MODAL } from '../../redux/constants/modal.constants';
import { AccountAction, FetchAction, ModalAction, RoleAction } from '../../redux';

export const CreateAccountModal = (props) => {
    const dispatch = useDispatch();
    const { visible } = useSelector<State, { [key: string]: any }>(
        state => state.modal[CREATE_ACCOUNT_MODAL] ?? {}
    );
    const roles = useSelector<State, any>(state => state.role.roles);
    useEffect(() => {
        if (visible) {
            dispatch(RoleAction.getRoles());
        }
    }, [visible]);

    const [form] = Form.useForm();
    const formLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    const submit = values => {
        const params = {
            ...values,
            email: values.email.trim(),
            phone: values.phone.trim(),
        }
        dispatch(AccountAction.createAccount(params));
    }

    const confirm = () => {
        form.submit();
    }

    return (<>
        <Modal
            title="添加账户"
            visible={visible}
            onCancel={() => {
                dispatch(ModalAction.close(CREATE_ACCOUNT_MODAL));
                dispatch(FetchAction.clear(CREATE_ACCOUNT_MODAL));
            }}
            onOk={() => confirm()}
            okText="确定"
            cancelText="取消"
            destroyOnClose={true}
        >
            <Form
                {...formLayout}
                form={form}
                onFinish={values => submit(values)}
                preserve={false}
            >
                <Form.Item
                    name="email"
                    label="邮箱"
                    rules={[{ required: true }, { type: 'email', message: '请输入正确的邮箱' }]}
                >
                    <Input placeholder="请输入邮箱"/>
                </Form.Item>
                <Form.Item
                    name="phone"
                    label="手机号"
                    rules={[{ required: true }, () => ({
                        validator(rule, value) {
                            const pattern = /^1[3-9]\d{9}$/;
                            if (pattern.test(value)) {
                                return Promise.resolve();
                            }
                            return Promise.reject('请输入正确的手机号');
                        }
                    })]}
                >
                    <Input maxLength={11} placeholder="请输入手机号"/>
                </Form.Item>
                <Form.Item
                    name="roleId"
                    label="角色"
                    rules={[{ required: true }]}
                >
                    <Select placeholder="请选择用户角色">
                        {roles.map((role) => <Select.Option key={role.id} value={String(role.id)}>
                            {role.name}
                        </Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="name"
                    label="姓名"
                >
                    <Input placeholder="请输入姓名"/>
                </Form.Item>
                <Form.Item
                    name="company"
                    label="公司"
                >
                    <Input placeholder="请输入公司"/>
                </Form.Item>
                <Form.Item
                    name="address"
                    label="地址"
                >
                    <Input placeholder="请输入地址"/>
                </Form.Item>
            </Form>
        </Modal>
    </>)
}
