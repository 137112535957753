import * as React from 'react';
import { Alert, Form, Input, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Fetch, State, UPDATE_CLUSTER } from "../../redux";
import { CREATE_CLUSTER_META_MODAL } from "../../redux/constants/modal.constants";
import { ModalAction } from "../../redux";
import { FetchAction } from "../../redux";
import { CREATE_CLUSTER } from "../../redux";
import { FormInstance } from "antd/lib/form";
import { ClusterAction } from "../../redux/actions/cluster.action";

export const CreateClusterMetaModal = (props) => {
    const { visible, clusterId, key, value } = useSelector<State, { [key: string]: any }>(
        state => state.modal[CREATE_CLUSTER_META_MODAL] ?? {},
    );
    const { fetching, error, message } = useSelector<State, Fetch>(
        state => state.fetch[CREATE_CLUSTER] ?? {} as any,
    );
    const errorMessage = error ? <Alert message={message} type="error" closable/> : null;
    const dispatch = useDispatch();

    const formRef = React.createRef<FormInstance>();
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    const submit = values => {
        const key = values.key.trim();
        const value = values.value.trim();
        dispatch(ClusterAction.updateClusterMeta(clusterId, key, value));
    }
    const confirm = () => {
        (formRef.current as any).submit();
    }

    return <Modal
        title="创建集群元数据"
        visible={visible}
        onOk={() => confirm()}
        confirmLoading={fetching}
        width={460}
        destroyOnClose={true}
        onCancel={() => {
            dispatch(ModalAction.close(CREATE_CLUSTER_META_MODAL));
            dispatch(FetchAction.clear(UPDATE_CLUSTER));
            (formRef.current as any).resetFields();
        }}>
        {errorMessage}
        <Form {...layout} ref={formRef} name="control-ref" onFinish={values => submit(values)}>
            <Form.Item name="key" label="Key" rules={[{ required: true }]} initialValue={key}>
                <Input/>
            </Form.Item>
            <Form.Item name="value" label="Value" rules={[{ required: true }]} initialValue={value}>
                <Input/>
            </Form.Item>
        </Form>
    </Modal>;
}
