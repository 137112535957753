import * as React from 'react';
import { useMount } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { Cluster, Fetch, GET_CLUSTERS, ListModel, State } from "../../redux";
import { Breadcrumb, Button, Table } from "antd";
import { Tools } from "../../components/tools.components";
import { ModalAction } from "../../redux";
import { CREATE_CLUSTER_META_MODAL, DELETE_CLUSTER_META_MODAL } from "../../redux/constants/modal.constants";
import { ReloadOutlined } from "@ant-design/icons";
import { ClusterAction } from "../../redux/actions/cluster.action";
import { SubHeader } from "../../components/sub-header";
import { Link } from "react-router-dom";
import { prettyDate } from "../../helpers/date.helper";
import { CreateClusterMetaModal } from "./create-cluster-meta.modal";
import { DeleteClusterMetaModal } from "./delete-cluster-meta.modal";

export const ClusterContainer = (props) => {
    const clusterId = props?.match?.params?.clusterId;
    const dispatch = useDispatch();
    const { entities } = useSelector<State, ListModel<Cluster>>(state => ({
        results: state.cluster.results,
        entities: state.cluster.entities,
    }));
    const cluster = entities[clusterId];
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_CLUSTERS] ?? {} as any);

    const refresh = () => dispatch(ClusterAction.getClusters());
    useMount(() => dispatch(ClusterAction.getClusters()));

    return <div>
        <SubHeader title={<Breadcrumb>
            <Breadcrumb.Item><Link to="/console/management/clusters">集群管理</Link></Breadcrumb.Item>
            <Breadcrumb.Item>{cluster?.name ?? clusterId}</Breadcrumb.Item>
        </Breadcrumb>}/>
        <Tools>
            <Button
                type="primary"
                onClick={() => dispatch(ModalAction.open(CREATE_CLUSTER_META_MODAL, { clusterId }))}>
                新建
            </Button>
            <Button type="ghost" icon={<ReloadOutlined/>} onClick={() => refresh()}/>
        </Tools>
        <Table
            rowKey={record => record.id as string}
            loading={fetching}
            dataSource={cluster?.metas ?? []}
            pagination={false}>
            <Table.Column key="key" dataIndex="key" title="Key"/>
            <Table.Column key="value" dataIndex="value" title="Value"/>
            <Table.Column key="createdAt" title="创建时间" render={record => prettyDate(record.createdAt)}/>
            <Table.Column key="updatedAt" title="修改时间" render={record => prettyDate(record.updatedAt)}/>
            <Table.Column key="operation" render={record => {
                return <>
                    <Button
                        type="link"
                        onClick={() => dispatch(ModalAction.open(CREATE_CLUSTER_META_MODAL, { clusterId, ...record }))}>
                        编辑
                    </Button>
                    <Button
                        danger={true}
                        type="link"
                        onClick={() => dispatch(ModalAction.open(DELETE_CLUSTER_META_MODAL, record))}>
                        删除
                    </Button>
                </>
            }}/>
        </Table>
        <CreateClusterMetaModal/>
        <DeleteClusterMetaModal/>
    </div>
}
