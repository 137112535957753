import * as React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Fetch, State, GET_ORDERS, OrderAction, Pagination } from "../../redux";
import { Button, Input, Select, Table } from "antd";
import { Tools } from "../../components/tools.components";
import { ReloadOutlined } from "@ant-design/icons";
import { SubHeader } from "../../components/sub-header";
import { useEffect, useState } from "react";
import { OrderType } from "../../enums/order-type.enum";
import { OrderStatus } from "../../enums/order-status.enum";
import { prettyDate } from "../../helpers/date.helper";

export const OrdersContainer = (props) => {
    const dispatch = useDispatch();
    const orders = useSelector<State, any[]>(state => state.bill.orders);
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_ORDERS] ?? {} as any);
    const [status, updateStatus] = useState<string>();
    const [accountId, updateAccountId] = useState<string>();
    const [orderId, updateOrderId] = useState<string>();

    const { pagination } = useSelector<State, { pagination: Pagination }>(state => ({
        pagination: state.pagination.order ?? {} as any,
    }));

    useEffect(() => {
        dispatch(OrderAction.getOrders({
            accountId,
            status,
            orderId,
            page: pagination.page,
            size: pagination.size,
        }));
    }, [status, accountId, orderId]);

    const refresh = (page: number, size: number) => dispatch(OrderAction.getOrders({
        accountId,
        status,
        orderId,
        page,
        size,
    }));

    const onPageChange = (page: number, size: number) => refresh(page, size);

    return <div>
        <SubHeader title="订单管理"/>
        <Tools>
            <Select value={status} onChange={val => updateStatus(val)}>
                <Select.Option value="pending">待支付</Select.Option>
                <Select.Option value="paid">已支付</Select.Option>
                <Select.Option value="canceled">已取消</Select.Option>
            </Select>
            <Input.Search
                defaultValue={orderId}
                onSearch={val => updateOrderId(val)}
                placeholder="订单ID"/>
            <Input.Search
                defaultValue={accountId}
                onSearch={val => updateAccountId(val)}
                placeholder="账户ID / Email"/>
            <Button type="ghost" icon={<ReloadOutlined/>} onClick={() => refresh(pagination.page, pagination.size)}/>
        </Tools>
        <Table
            loading={fetching}
            dataSource={orders}
            rowKey={(record, index) => index}
            pagination={{
                total: pagination.total,
                defaultPageSize: pagination.size,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
                current: pagination.page,
                onShowSizeChange: onPageChange,
                onChange: onPageChange,
            }}>
            <Table.Column
                title="订单号"
                dataIndex="id" key="id"
                render={(text, record) => <Link to={`/console/orders/${text}`}>{text}</Link>}/>
            <Table.Column title="账户" dataIndex="email" key="email"/>
            <Table.Column title="订单类型" dataIndex="type" key="type" render={text => OrderType[text]}/>
            <Table.Column<any>
                title="产品"
                dataIndex="productType"
                key="productType"
                render={(text, record) => record?.product?.description}/>
            <Table.Column title="原价" dataIndex="price" key="price"/>
            <Table.Column title="应付" dataIndex="amountDue" key="amountDue"/>
            <Table.Column title="状态" dataIndex="status" key="status" render={text => OrderStatus[text]}/>
            <Table.Column title="创建时间" dataIndex="createdAt" key="createdAt" render={text => prettyDate(text)}/>
        </Table>
    </div>
}
