import { combineReducers } from "../combine-reducers";
import { RegionReducer } from "./region.reducer";
import { ModalReducer } from "./modal.reducer";
import { FetchReducer } from "./fetch.reducer";
import { ClusterReducer } from "./cluster.reducer";
import { ProductReducer } from "./product.reducer";
import { BillingItemReducer } from "./billing-item.reducer";
import { PaginationReducer } from "./pagination.reducer";
import { VoucherReducer } from "./voucher.reducer";
import { RuleReducer } from "./rule.reducer";
import { SessionReducer } from "./session.reducer";
import { VoucherInstanceReducer } from "./voucher-instance.reducer";
import { BillReducer } from "./bill.reducer";
import { AccountReducer } from './account.reducer';
import { RoleReducer } from './role.reducer';
import { NotificationReducer } from './notification.reducer';
import { InstanceReducer } from "./instance.reducer";
import { TicketReducer } from "./ticket.reducer";
import { SupportReducer } from "./support.reducer";
import { InstanceDetailReducer } from './instance-detail.reducer';

export default combineReducers({
    modal: new ModalReducer(),
    fetch: new FetchReducer(),
    pagination: new PaginationReducer(),
    region: new RegionReducer(),
    cluster: new ClusterReducer(),
    product: new ProductReducer(),
    billingItem: new BillingItemReducer(),
    voucher: new VoucherReducer(),
    rule: new RuleReducer(),
    session: new SessionReducer(),
    voucherInstance: new VoucherInstanceReducer(),
    bill: new BillReducer(),
    account: new AccountReducer(),
    role: new RoleReducer(),
    notification: new NotificationReducer(),
    instance: new InstanceReducer(),
    ticket: new TicketReducer(),
    support: new SupportReducer(),
    instanceDetail: new InstanceDetailReducer(),
});
