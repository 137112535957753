import { ReloadOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useMount } from 'react-use';
import { SubHeader } from '../../components/sub-header';
import { Tools } from '../../components/tools.components';
import { Fetch, GET_MESSAGES, NotificationAction, Pagination, State } from '../../redux';
import { Message } from '../../redux/interfaces/notification.interface';

export const MessageContainer = (props) => {
    const dispatch = useDispatch();

    const { pagination } = useSelector<State, { pagination: Pagination }>(
        state => ({ pagination: state.pagination.message ?? {} as any }),
    );
    const messages = useSelector<State, Message[]>(
        state => state.notification.messages ?? [],
    );
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_MESSAGES] ?? {} as any);

    useMount(() => dispatch(NotificationAction.getMessages(pagination.page, pagination.size)));

    const pushState = (url: string) => {
        window.location.hash = url;
    };
    const refresh = (page: number, size: number) => {
        dispatch(NotificationAction.getMessages(page, size));
    };
    const onPageChange = (page: number, size: number) => refresh(page, size);

    return <div>
        <SubHeader title="个人消息"/>
        <Tools>
            <Button type="primary" onClick={() => pushState('/console/notification/create-message')}>
                新增消息
            </Button>
            <Button type="ghost" icon={<ReloadOutlined/>} onClick={() => refresh(pagination.page, pagination.size)}/>
        </Tools>
        <Table
            loading={fetching}
            dataSource={messages}
            pagination={{
                total: pagination.total,
                defaultPageSize: pagination.size,
                pageSizeOptions: ['10', '20', '50', '100'],
                current: pagination.page,
                onShowSizeChange: onPageChange,
                onChange: onPageChange,
                showSizeChanger: true,
            }}>
            <Table.Column title="标题" dataIndex="title" key="title"/>
            <Table.Column title="分类" dataIndex="type" key="type"/>
            <Table.Column title="已发布人数" dataIndex="published" key="published"/>
            <Table.Column title="已阅人数" dataIndex="read" key="read"/>
            <Table.Column
                title="创建时间"
                dataIndex="createdAt"
                key="createdAt"
                render={(text) => moment(text).format('YYYY-MM-DD hh:mm')}
            />
            <Table.Column title="操作" key="operation" render={(record) => {
                return <span>
                    <Button
                        disabled={record.isPublished}
                        type="primary"
                        style={{ marginRight: 12 }}
                        onClick={() => dispatch(NotificationAction.publishMessage(record.id))}>
                        {
                            record.isPublished ? '已发布' : '发布'
                        }
                    </Button>
                    <Popconfirm
                        title="确定删除吗？"
                        onConfirm={() => dispatch(NotificationAction.deleteMessage(record.id))}>
                        <Button danger>删除</Button>
                    </Popconfirm>
                </span>
            }}/>
        </Table>
    </div>;
}
