import { ADD, DELETE, INIT, PUT, UPDATE } from "../constants/verb.constants";
import { Action } from "../action.interface";
import { get, set } from 'lodash';

export class ListReducer<M = any> {
    public model = { results: [], entities: {} as { [key: string]: M } };

    constructor(
        public readonly type: string,
        public readonly idKey: string,
    ) {
    }

    [INIT](state: { results: string[], entities: { [key: string]: M } } = {
        results: [],
        entities: {}
    }, action: Action<M[]>) {
        const list = action.value;
        const results: string[] = [];
        const entities = {};
        list?.forEach(item => {
            const id = get(item, this.idKey);
            results.push(id);
            entities[id] = item;
        });
        return { results, entities };
    }

    [ADD](state: { results: string[], entities: { [key: string]: M } } = {
        results: [],
        entities: {}
    }, action: Action<M[]>) {
        const id = get(action.value, this.idKey);
        if (state.results.includes(id)) {
            return this[PUT](state, { ...action, key: id });
        }
        const newState = { ...state };
        newState.entities[id] = action.value as any;
        newState.results.push(id);
        return newState;
    }

    [UPDATE](state: { results: string[], entities: { [key: string]: M } } = {
        results: [],
        entities: {}
    }, action: Action<M[]>) {
        const newState: { results: string[], entities: { [key: string]: M } } = { ...state };
        if (action.path) {
            // @ts-ignore
            set(newState.entities[action.key], action.path, action.value);
        } else {
            // @ts-ignore
            newState.entities[action.key] = action.value;
        }
        return newState;
    }

    [PUT](state: { results: string[], entities: { [key: string]: M } } = {
        results: [],
        entities: {}
    }, action: Action<M[]>) {
        const newState: any = { ...state };
        // @ts-ignore
        newState.entities[action.key] = action.value;
        return newState;
    }

    [DELETE](state: { results: string[], entities: { [key: string]: M } } = {
        results: [],
        entities: {}
    }, action: Action<M[]>) {
        const newState: any = { ...state };
        if (newState.results.includes(action.key)) {
            newState.results = newState.results.filter(i => i !== action.key);
            // @ts-ignore
            delete newState.entities[action.key];
        }
        return newState;
    }
}
