import { Alert, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Fetch,
    FetchAction,
    ModalAction,
    NotificationAction,
    State,
    UPDATE_NOTIFICATION_TEMPLATE,
} from '../../redux';
import './create-message.container.less';
import { UPDATE_EMAIL_NOTIFY_TPL_MODAL } from "../../redux/constants/modal.constants";
import { handleHtmlContent } from "../../helpers/editor.helper";
import { plugins, scriptSrc, toolbar } from "../../constants/tinymce.constants";

export const UpdateEmailTemplateModal = (props) => {
    const { visible, emailTitle, emailContent, id, style } = useSelector<State, { [key: string]: any }>(
        state => state.modal[UPDATE_EMAIL_NOTIFY_TPL_MODAL] ?? {},
    );
    const { fetching, error, message } = useSelector<State, Fetch>(
        state => state.fetch[UPDATE_NOTIFICATION_TEMPLATE] ?? {} as any,
    );
    const [content, updateContent] = useState<string>(emailContent);
    const errorMessage = error ? <Alert message={message} type="error" closable/> : null;
    const [form] = Form.useForm();
    const formLayout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 20 },
    }

    const dispatch = useDispatch();

    useEffect(() => {
        form.setFieldsValue({ emailTitle });
    }, [emailTitle]);

    const submit = (values) => {
        dispatch(NotificationAction.updateTemplate(id, { emailContent: handleHtmlContent(content), ...values }));
    };

    return <Modal
        title="编辑邮件通知模板"
        visible={visible}
        onOk={() => form.submit()}
        confirmLoading={fetching}
        destroyOnClose={true}
        width={800}
        onCancel={() => {
            dispatch(ModalAction.close(UPDATE_EMAIL_NOTIFY_TPL_MODAL));
            dispatch(FetchAction.clear(UPDATE_NOTIFICATION_TEMPLATE));
            form.resetFields();
        }}>
        <style dangerouslySetInnerHTML={{ __html: style }}/>
        {errorMessage}
        <Form
            {...formLayout}
            form={form}
            onFinish={(values) => submit(values)}
            className="form">
            <Form.Item label="标题" name="emailTitle" initialValue={emailTitle}>
                <Input/>
            </Form.Item>
            <Form.Item label="内容">
                <Editor
                    initialValue={emailContent}
                    tinymceScriptSrc={scriptSrc}
                    init={{
                        height: 500,
                        menubar: false,
                        plugins,
                        toolbar,
                    }}
                    onEditorChange={(val) => updateContent(val)}
                />
            </Form.Item>
        </Form>
    </Modal>
}
