import * as React from 'react';
import { Route } from "react-router-dom";
import { ProductsContainer } from "./products.container";
import { BillingItemsContainer } from "./billing-items.container";

export const Product = (props) => {
    return <div>
        <Route path="/console/products" exact={true} component={ProductsContainer}/>
        <Route path="/console/products/:productId" exact={true} component={BillingItemsContainer}/>
    </div>
}
