import * as React from 'react';
import { Alert, Form, Input, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { SEND_TEST_EMAIL_MODAL } from "../../redux/constants/modal.constants";
import { FetchAction, Fetch, State, ModalAction, NotificationAction, SEND_TEST_EMAIL } from "../../redux";
import { FormInstance } from "antd/lib/form";
import { useEffect, useState } from "react";

export const TestEmailModal = (props) => {
    const { visible, id } = useSelector<State, { [key: string]: any }>(
        state => state.modal[SEND_TEST_EMAIL_MODAL] ?? {} as any,
    );
    const { fetching, error, message } = useSelector<State, Fetch>(
        state => state.fetch[SEND_TEST_EMAIL] ?? {} as any,
    );

    const [errors, updateErrors] = useState({ error: false, message: '' });

    const errorMessage = errors.error ? <Alert message={errors.message} type="error" closable/> : null;
    const dispatch = useDispatch();

    useEffect(() => {
        updateErrors({ error, message });
    }, [error, message])

    const formRef = React.createRef<FormInstance>();
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    const submit = values => {
        try {
            dispatch(NotificationAction.sendTestEmail(
                id,
                values.email,
                values.params ? JSON.parse(values.params) : null,
            ));
        } catch (e) {
            updateErrors({ error: true, message: e.message });
        }
    }
    const confirm = () => {
        (formRef.current as any).submit();
    }
    return <Modal
        title="测试邮件模板"
        visible={visible}
        onOk={() => confirm()}
        confirmLoading={fetching}
        width={460}
        onCancel={() => {
            dispatch(ModalAction.close(SEND_TEST_EMAIL_MODAL));
            dispatch(FetchAction.clear(SEND_TEST_EMAIL));
            (formRef.current as any).resetFields();
        }}>
        {errorMessage}
        <Form {...layout} ref={formRef} name="control-ref" onFinish={values => submit(values)}>
            <Form.Item name="email" label="邮箱" rules={[{ required: true }]}>
                <Input/>
            </Form.Item>
            <Form.Item name="params" label="参数">
                <Input.TextArea rows={4} placeholder={`JSON格式，例如：{"code": "3743"}`}/>
            </Form.Item>
        </Form>
    </Modal>;
}
