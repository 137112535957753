import { Dispatch } from 'redux';
import { ModalAction, PaginationAction } from '..';
import { encrypt } from '../../helpers/password.helper';
import { Action } from '../action';
import { Client } from '../client';
import { ACCOUNT } from '../constants/action-types.constants';
import { GET_ACCOUNTS, UPDATE_ACCOUNT_PASSWORD, UPDATE_ACCOUNT_STATUS } from '../constants/fetch.constants';
import { CREATE_ACCOUNT_MODAL, PASSWORD_MODAL, UPDATE_ACCOUNT_MODAL, UPDATE_ACCOUNT_PASSWORD_MODAL } from '../constants/modal.constants';
import { Account } from '../interfaces';
import { PasswordVariable } from '../../enums/password-variable.enum';

export class AccountAction {

    private static client = new Client();

    public static getAccounts(currentPage: number, currentSize: number) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { list, page, size, total } = await this.client.get('/api/accounts', {
                params: {
                    page: currentPage,
                    size: currentSize,
                },
                dispatch,
                fetch: GET_ACCOUNTS,
            });
            dispatch(new Action(ACCOUNT).init(list));
            dispatch(PaginationAction.update('account', page, size, total, list.length));
        }
    }

    public static createAccount(values: Account) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const pagination = state().pagination.account ?? {} as any;
            const { password } = await this.client.post('/api/accounts', {
                ...values,
            }, {})
            dispatch(ModalAction.close(CREATE_ACCOUNT_MODAL));
            dispatch(this.getAccounts(pagination.page, pagination.size));
            dispatch(ModalAction.open(PASSWORD_MODAL, { ...values, password }))
        }
    }

    public static updateAccount(id: string, values: Account) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const pagination = state().pagination.account ?? {} as any;
            await this.client.put(`/api/accounts/${id}`, {
                ...values,
            }, {});
            dispatch(ModalAction.close(UPDATE_ACCOUNT_MODAL));
            dispatch(this.getAccounts(pagination.page, pagination.size));
        }
    }

    public static updateAccountStatus(id: string, status: 'enable' | 'disable') {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(`/api/accounts/${id}/actions/${status}`, {}, {
                dispatch,
                fetch: UPDATE_ACCOUNT_STATUS,
            });
            dispatch(new Action(ACCOUNT).update(id, 'status', status));
        }
    }

    public static deleteAccount(id: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const pagination = state().pagination.account ?? {} as any;
            await this.client.delete(`/api/accounts/${id}`);
            dispatch(this.getAccounts(pagination.page, pagination.size));
        }
    }

    public static updateAccountPassword(id: string, values: { password: string }) {
        const ciphertext = encrypt(values.password, PasswordVariable.KEY, PasswordVariable.IV);
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(`/api/accounts/${id}/password/admin`, {
                ...values,
                password: ciphertext,
            }, {
                dispatch,
                fetch: UPDATE_ACCOUNT_PASSWORD,
            });
            dispatch(ModalAction.close(UPDATE_ACCOUNT_PASSWORD_MODAL));
        }
    }
}
