import { Dispatch } from "redux";
import { Client } from "../client";
import { Action } from "../action";
import { REGION } from "../constants/action-types.constants";
import { Region } from "../interfaces";
import { CREATE_REGION, DELETE_REGION, GET_REGIONS, UPDATE_REGION } from "..";
import { ModalAction } from "./modal.action";
import { CREATE_REGION_MODAL, DELETE_REGION_MODAL, UPDATE_REGION_MODAL } from "../constants/modal.constants";

export class RegionAction {
    private static client = new Client();

    public static getRegions() {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { regions } = await this.client.get('/api/regions', {
                dispatch,
                fetch: GET_REGIONS,
            });
            dispatch(new Action(REGION).init(regions));
        }
    }

    public static createRegion(region: Region) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.post('/api/regions', region, {
                dispatch,
                fetch: CREATE_REGION,
            });
            dispatch(this.getRegions());
            dispatch(ModalAction.close(CREATE_REGION_MODAL));
        }
    }

    public static updateRegion(regionId: string, name: string, platformName: string, description?: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(`/api/regions/${regionId}`, { name, platformName, description }, {
                dispatch,
                fetch: UPDATE_REGION,
            });
            dispatch(new Action(REGION).update(regionId, 'name', name));
            dispatch(new Action(REGION).update(regionId, 'platformName', platformName));
            if (description !== undefined) {
                dispatch(new Action(REGION).update(regionId, 'description', description));
            }
            dispatch(ModalAction.close(UPDATE_REGION_MODAL));
        }
    }

    public static deleteRegion(regionId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.delete(`/api/regions/${regionId}`, {
                dispatch,
                fetch: DELETE_REGION,
            });
            dispatch(new Action(REGION).delete(regionId));
            dispatch(ModalAction.close(DELETE_REGION_MODAL))
        }
    }

    public static updateRegionStatus(regionId: string, status: 'enable' | 'disable') {
        return async (dispatch: Dispatch<any>, state: any) => {
            await this.client.put(`/api/regions/${regionId}/actions/${status}`, null, {
                dispatch,
            });
            dispatch(new Action(REGION).update(regionId, 'status', status));
        }
    }
}
