import { Action } from '../action';
import { Client } from '../client';
import { ROLE } from '../constants/action-types.constants';

export class RoleAction {
    private static client = new Client();

    public static getRoles() {
        return async (dispatch, state) => {
            const roles = await this.client.get('/api/roles');
            
            dispatch(new Action(ROLE).put('roles', roles));
        }
    }
}