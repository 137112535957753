import * as React from 'react';
import { Alert, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Fetch, State } from "../../redux/interfaces";
import { DELETE_CLUSTER_MODAL, DELETE_TICKET_CATEGORY_MODAL } from "../../redux/constants/modal.constants";
import { ModalAction, TicketAction } from "../../redux/actions";
import { FetchAction } from "../../redux/actions";
import { DELETE_CLUSTER, DELETE_TICKET_CATEGORY } from "../../redux";
import { ClusterAction } from "../../redux/actions/cluster.action";

export const DeleteCategoryModal = (props) => {
    const { visible, name, id } = useSelector<State, { [key: string]: any }>(
        state => state.modal[DELETE_TICKET_CATEGORY_MODAL] ?? {} as any,
    );
    const { fetching, error, message } = useSelector<State, Fetch>(
        state => state.fetch[DELETE_TICKET_CATEGORY] ?? {} as any,
    );

    const errorMessage = error ? <Alert message={message} type="error" closable/> : null;
    const dispatch = useDispatch();

    const confirm = () => {
        dispatch(TicketAction.deleteCategory(id, () => props.refresh()));
    }
    return <Modal
        title="删除分类"
        visible={visible}
        onOk={() => confirm()}
        confirmLoading={fetching}
        width={460}
        onCancel={() => {
            dispatch(ModalAction.close(DELETE_TICKET_CATEGORY_MODAL));
            dispatch(FetchAction.clear(DELETE_TICKET_CATEGORY));
        }}>
        {errorMessage}
        确认删除分类 {name}?
    </Modal>;
}
