import * as React from 'react';
import { Alert, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Fetch, State } from "../../redux/interfaces";
import { DELETE_PRODUCT_MODAL } from "../../redux/constants/modal.constants";
import { ModalAction, ProductAction } from "../../redux/actions";
import { FetchAction, RegionAction } from "../../redux/actions";
import { DELETE_PRODUCT } from "../../redux";

export const DeleteProductModal = (props) => {
    const { visible, name, id } = useSelector<State, { [key: string]: any }>(
        state => state.modal[DELETE_PRODUCT_MODAL] ?? {},
    );
    const { fetching, error, message } = useSelector<State, Fetch>(
        state => state.fetch[DELETE_PRODUCT] ?? {} as any,
    );

    const errorMessage = error ? <Alert message={message} type="error" closable/> : null;
    const dispatch = useDispatch();

    const confirm = () => {
        dispatch(ProductAction.deleteProduct(id));
    }
    return <Modal
        title="删除产品"
        visible={visible}
        onOk={() => confirm()}
        confirmLoading={fetching}
        width={460}
        onCancel={() => {
            dispatch(ModalAction.close(DELETE_PRODUCT_MODAL));
            dispatch(FetchAction.clear(DELETE_PRODUCT));
        }}>
        {errorMessage}
        <p>确认删除产品 {name}?</p>
        <p>删除前请确认该产品没有正在使用的实例，否则会导致计费出现异常。</p>
    </Modal>;
}
