import React from 'react';
import { Modal, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AccountAction, ModalAction, State } from '../../redux';
import { UPDATE_ACCOUNT_PASSWORD_MODAL } from '../../redux/constants/modal.constants';

export const UpdatePasswordModal = () => {
    const dispatch = useDispatch();
    const { visible, ...account } = useSelector<State, any>(
        state => state.modal[UPDATE_ACCOUNT_PASSWORD_MODAL] ?? {}
    );
    const [form] = Form.useForm();
    const formLayout = { labelCol: { span: 6 }, wrapperCol: { span: 18 } };

    const submit = (values) => {
        dispatch(AccountAction.updateAccountPassword(account.id, { password: values.password }));
    }

    return <Modal
        visible={visible}
        title="修改密码"
        onCancel={() => dispatch(ModalAction.close(UPDATE_ACCOUNT_PASSWORD_MODAL))}
        onOk={() => form.submit()}
        destroyOnClose={true}
    >
        <Form
            {...formLayout}
            preserve={false}
            form={form}
            onFinish={(values) => submit(values)}
        >
            <Form.Item
                label="新密码"
                name="password"
                required
                rules={[
                    { required: true },
                    { validator: (rule, value) => {
                        if (value.length >= 6 || value.length <= 20) {
                            return Promise.resolve();
                        }
                        return Promise.reject('密码长度6-20');
                    }}
                ]}
            >
                <Input placeholder="请输入密码" />
            </Form.Item>
            <Form.Item
                label="确认密码"
                name="repassword"
                required
                rules={[
                    { required: true, message: '请再次输入密码' },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('两次输入密码不一致');
                        }
                    })
                ]}
            >
                <Input placeholder="请再次输入密码" />
            </Form.Item>
        </Form>
    </Modal>
}