import React from 'react';
import { Alert, Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../redux';
import { PASSWORD_MODAL } from '../../redux/constants/modal.constants';
import { ModalAction } from '../../redux';

export const PasswordModal = (props) => {
    const { visible, email, password } = useSelector<State, any>(
        state => state.modal[PASSWORD_MODAL] ?? {} as any,
    );
    const dispatch = useDispatch();

    return <Modal
        title="创建成功"
        visible={visible}
        footer={<Button type="primary" onClick={() => dispatch(ModalAction.close(PASSWORD_MODAL))}>
            确 定
        </Button>}
    >
        <Alert message="创建成功，请尽快修改初始密码" type="success" showIcon />
        <br/>
        账号：{ email }
        <br/>
        密码： { password }
    </Modal>
}
