import { Alert, Form, Input, Modal } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Fetch,
    FetchAction,
    ModalAction,
    NotificationAction,
    State,
    UPDATE_NOTIFICATION_TEMPLATE,
} from '../../redux';
import './create-message.container.less';
import { UPDATE_STYLE_MODAL } from "../../redux/constants/modal.constants";

export const UpdateStyleModal = (props) => {
    const { visible, style, id } = useSelector<State, { [key: string]: any }>(
        state => state.modal[UPDATE_STYLE_MODAL] ?? {},
    );
    const { fetching, error, message } = useSelector<State, Fetch>(
        state => state.fetch[UPDATE_NOTIFICATION_TEMPLATE] ?? {} as any,
    );
    const errorMessage = error ? <Alert message={message} type="error" closable/> : null;
    const [form] = Form.useForm();
    const formLayout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 20 },
    }

    const dispatch = useDispatch();

    useEffect(() => {
        form.setFieldsValue({ style });
    }, [style]);

    const submit = (values) => {
        dispatch(NotificationAction.updateTemplate(id, { ...values }));
    };

    return <Modal
        title="编辑邮件通知模板"
        visible={visible}
        onOk={() => form.submit()}
        confirmLoading={fetching}
        destroyOnClose={true}
        width={800}
        onCancel={() => {
            dispatch(ModalAction.close(UPDATE_STYLE_MODAL));
            dispatch(FetchAction.clear(UPDATE_NOTIFICATION_TEMPLATE));
            form.resetFields();
        }}>
        {errorMessage}
        <Form
            {...formLayout}
            form={form}
            onFinish={(values) => submit(values)}
            className="form">
            <Form.Item label="样式" name="style">
                <Input.TextArea rows={6}/>
            </Form.Item>
        </Form>
    </Modal>
}
