import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './login.container.less';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Fetch, State } from "../../redux/interfaces";
import { AuthAction, LOGIN } from "../../redux";

export const LoginContainer = () => {
    const dispatch = useDispatch();
    const [time, updateTime] = useState(Date.now);
    const onFinish = values => {
        dispatch(AuthAction.login(values.email, values.password, values.code, () => updateTime(Date.now())));
    };


    const { fetching } = useSelector<State, Fetch>(state => state.fetch[LOGIN] ?? {} as any);

    return (
        <div className="login-container">
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: '请输入邮箱' }]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="邮箱"/>
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: '请输入密码' }]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        type="password"
                        placeholder="密码"
                    />
                </Form.Item>
                <Form.Item style={{ position: 'relative' }}>
                    <Form.Item name="code" noStyle rules={[{ required: true, message: '请输入验证码' }]}>
                        <Input
                            style={{ width: 204 }}
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            placeholder="验证码"
                        />
                    </Form.Item>
                    <img
                        style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            height: 32,
                            cursor: 'pointer',
                        }}
                        onClick={() => updateTime(Date.now())}
                        src={`/apis/captcha?type=operation_login&time=${time}`}/>
                </Form.Item>

                <Form.Item>
                    <Button loading={fetching} type="primary" htmlType="submit" className="login-form-button">
                        登录
                    </Button>
                    <a className="login-form-forgot" href="">
                        Forgot password
                    </a>
                </Form.Item>
            </Form>
        </div>
    );
};
