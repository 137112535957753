import { Pagination, State } from "../interfaces";

export function getPagination(state: State, key: string, page: number, size: number) {
    const pagination = state.pagination[key] ?? {} as Pagination;
    if (page) {
        pagination.page = page;
    }
    if (size) {
        pagination.size = size;
    }

    pagination.size = pagination.size ?? 5;

    return pagination;
}
