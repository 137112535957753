import * as React from 'react';
import { Alert, Form, Input, InputNumber, Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Fetch, ListModel, Region, State } from "../../redux/interfaces";
import { CREATE_CLUSTER_MODAL } from "../../redux/constants/modal.constants";
import { ModalAction } from "../../redux/actions";
import { FetchAction, RegionAction } from "../../redux/actions";
import { CREATE_CLUSTER } from "../../redux";
import { FormInstance } from "antd/lib/form";
import { useEffect } from "react";
import { ClusterAction } from "../../redux/actions/cluster.action";

export const CreateClusterModal = (props) => {
    const { visible } = useSelector<State, { [key: string]: any }>(
        state => state.modal[CREATE_CLUSTER_MODAL] ?? {},
    );
    const { results, entities } = useSelector<State, ListModel<Region>>(state => ({
        results: state.region.results,
        entities: state.region.entities,
    }));
    const regions = results.map(id => entities[id]);
    const { fetching, error, message } = useSelector<State, Fetch>(
        state => state.fetch[CREATE_CLUSTER] ?? {} as any,
    );
    const errorMessage = error ? <Alert message={message} type="error" closable/> : null;
    const dispatch = useDispatch();

    useEffect(() => {
        if (visible) {
            dispatch(RegionAction.getRegions());
        }
    }, [visible])

    const formRef = React.createRef<FormInstance>();
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    const submit = values => {
        const params = {
            ...values,
            address: values.address.trim(),
            cidr: values.cidr.trim(),
            tenantId: values.tenantId.trim(),
            username: values.username.trim(),
            password: values.password.trim(),
        };
        dispatch(ClusterAction.createCluster(params));
    }
    const confirm = () => {
        (formRef.current as any).submit();
    }

    return <Modal
        title="创建集群"
        visible={visible}
        onOk={() => confirm()}
        confirmLoading={fetching}
        width={460}
        onCancel={() => {
            dispatch(ModalAction.close(CREATE_CLUSTER_MODAL));
            dispatch(FetchAction.clear(CREATE_CLUSTER));
            (formRef.current as any).resetFields();
        }}>
        {errorMessage}
        <Form {...layout} ref={formRef} name="control-ref" onFinish={values => submit(values)}>
            <Form.Item name="name" label="集群名称" rules={[{ required: true }]}>
                <Input/>
            </Form.Item>
            <Form.Item name="regionId" label="区域" rules={[{ required: true }]}>
                <Select>
                    {regions.map(region => <Select.Option key={region.id} value={region.id as string}>
                        {region.platformName} / {region.name}
                    </Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item name="address" label="地址" rules={[{ required: true }]}>
                <Input/>
            </Form.Item>
            <Form.Item name="cidr" label="CIDR" rules={[{ required: true }]}>
                <Input/>
            </Form.Item>
            <Form.Item name="tenantId" label="Tenant ID" rules={[{ required: true }]}>
                <Input/>
            </Form.Item>
            <Form.Item name="weight" label="权重" rules={[{ required: true }]} initialValue={100}>
                <InputNumber min={1} max={100}/>
            </Form.Item>
            <Form.Item name="username" label="用户名" rules={[{ required: true }]}>
                <Input/>
            </Form.Item>
            <Form.Item name="password" label="密码" rules={[{ required: true }]}>
                <Input type="password"/>
            </Form.Item>
            <Form.Item name="version" label="版本" rules={[{ required: true }]} initialValue={'6.6.0'}>
                <Input/>
            </Form.Item>
        </Form>
    </Modal>;
}
