import { useDispatch, useSelector } from "react-redux";
import { Fetch, GET_RESOLVERS, ModalAction, State, TicketAction } from "../../redux";
import { SubHeader } from "../../components/sub-header";
import { Tools } from "../../components/tools.components";
import { Button, Card, Table } from "antd";
import {
    CREATE_RESOLVER_GROUP_MODAL,
    CREATE_RESOLVER_MODAL,
    DELETE_RESOLVER_GROUP_MODAL, DELETE_RESOLVER_MODAL,
} from "../../redux/constants/modal.constants";
import { DeleteOutlined, PlusSquareOutlined, ReloadOutlined } from "@ant-design/icons";
import * as React from "react";
import { prettyDate } from "../../helpers/date.helper";
import { useMount } from "react-use";
import styles from './templates.container.m.less';
import { useEffect, useState } from "react";
import { CreateGroupModal } from "./create-group.modal";
import { DeleteGroupModal } from "./delete-group.modal";
import { CreateResolverModal } from "./create-resolver.modal";
import { DeleteResolverModal } from "./delete-resolver.modal";

export const ResolversContainer = (props) => {
    const dispatch = useDispatch();
    const groups = useSelector<State, any[]>(state => state.ticket.groups);
    const resolvers = useSelector<State, any[]>(state => state.ticket.resolvers);
    const { fetching } = useSelector<State, Fetch>(state => state.fetch[GET_RESOLVERS] ?? {} as any);

    const [selected, updateSelected] = useState();

    const refresh = () => {
        dispatch(TicketAction.getResolvers(selected));
    };

    useMount(() => dispatch(TicketAction.getGroups()));

    useEffect(() => {
        if (groups && groups.length > 0 && !selected) {
            updateSelected(groups[0].id);
            dispatch(TicketAction.getResolvers(groups[0].id));
        }
        if (groups.length === 0) {
            updateSelected(undefined);
        }
    }, [groups]);

    useEffect(() => {
        if (selected) {
            dispatch(TicketAction.getResolvers(selected));
        }
    }, [selected])

    return <div style={{ height: '100%' }}>
        <SubHeader title="客服管理"/>
        <Tools>
            <Button
                disabled={groups.length === 0}
                type="primary"
                onClick={() => dispatch(ModalAction.open(CREATE_RESOLVER_MODAL, { groupId: selected }))}>
                创建客服
            </Button>
            <Button type="ghost" icon={<ReloadOutlined/>} onClick={() => refresh()}/>
        </Tools>
        <div className={styles.content}>
            <Card title="客服组"
                  bordered={false}
                  className={styles.category}
                  bodyStyle={{ padding: 0 }}
                  extra={<PlusSquareOutlined
                      className={styles.plus}
                      onClick={() => dispatch(ModalAction.open(CREATE_RESOLVER_GROUP_MODAL))}
                  />}>
                <ul>
                    {groups.map(({ name, id }) =>
                        <li key={id}
                            className={selected === id ? styles.active : ''}
                            onClick={() => updateSelected(id)}>
                            {name}
                            <DeleteOutlined
                                className={styles.delete}
                                onClick={(e) => {
                                    dispatch(ModalAction.open(DELETE_RESOLVER_GROUP_MODAL, { id, name }));
                                    e.stopPropagation();
                                }}/>
                        </li>
                    )}
                </ul>
            </Card>
            <Table
                loading={fetching}
                dataSource={resolvers}
                pagination={false}
                rowKey={record => record.id}
                className={styles.template}>
                <Table.Column<any>
                    title="名称"
                    dataIndex="name" key="name"
                    render={(text, record) => record?.account?.name}/>
                <Table.Column<any>
                    title="邮箱"
                    dataIndex="email"
                    key="email"
                    render={(text, record) => record?.account?.email}/>
                <Table.Column title="状态" dataIndex="status" key="status" render={text => text}/>
                <Table.Column title="创建时间" dataIndex="createdAt" key="createdAt" render={text => prettyDate(text)}/>
                <Table.Column title="修改时间" dataIndex="updatedAt" key="updatedAt" render={text => prettyDate(text)}/>
                <Table.Column<any>
                    title="操作"
                    dataIndex="methods"
                    key="methods"
                    render={(text, record) => {
                        return <>
                            <Button
                                type="link"
                                onClick={() => dispatch(record.status === 'disable' ?
                                    TicketAction.enableResolver(record.id) :
                                    TicketAction.disableResolver(record.id)
                                )}>
                                {record.status === 'disable' ? '启用' : '禁用'}
                            </Button>
                            <Button
                                danger={true}
                                type="link"
                                onClick={() => dispatch(ModalAction.open(DELETE_RESOLVER_MODAL, record))}>
                                删除
                            </Button>
                        </>
                    }}/>
            </Table>
            <CreateGroupModal/>
            <DeleteGroupModal/>
            <CreateResolverModal/>
            <DeleteResolverModal/>
        </div>
    </div>

}
