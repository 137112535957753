import * as React from 'react';
import { Alert, Form, Input, InputNumber, Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Fetch, State } from "../../redux/interfaces";
import { CREATE_PRODUCT_MODAL } from "../../redux/constants/modal.constants";
import { ModalAction, ProductAction } from "../../redux/actions";
import { FetchAction } from "../../redux/actions";
import { CREATE_PRODUCT } from "../../redux";
import { FormInstance } from "antd/lib/form";
import { useEffect } from "react";

export const CreateProductModal = (props) => {
    const { visible } = useSelector<State, { [key: string]: any }>(
        state => state.modal[CREATE_PRODUCT_MODAL] ?? {},
    );
    const { fetching, error, message } = useSelector<State, Fetch>(
        state => state.fetch[CREATE_PRODUCT] ?? {} as any,
    );
    const products = useSelector<State, string[]>(state => state.support.products);
    const errorMessage = error ? <Alert message={message} type="error" closable/> : null;
    const dispatch = useDispatch();
    const formRef = React.createRef<FormInstance>();
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    const submit = values => {
        dispatch(ProductAction.createProduct(values.name, values.type, values.description, values.charges));
    }
    const confirm = () => {
        (formRef.current as any).submit();
    }

    useEffect(() => {
        if (visible) {
            dispatch(ProductAction.getSupportProducts());
        }
    }, [visible])

    return <Modal
        title="创建产品"
        visible={visible}
        onOk={() => confirm()}
        confirmLoading={fetching}
        width={460}
        destroyOnClose={true}
        onCancel={() => {
            dispatch(ModalAction.close(CREATE_PRODUCT_MODAL));
            dispatch(FetchAction.clear(CREATE_PRODUCT));
            (formRef.current as any).resetFields();
        }}>
        {errorMessage}
        <Form {...layout} ref={formRef} name="control-ref" onFinish={values => submit(values)}>
            <Form.Item name="name" label="产品名称" rules={[{ required: true }]}>
                <Input/>
            </Form.Item>
            <Form.Item name="type" label="产品类型" rules={[{ required: true }]}>
                <Select>
                    {products.map(product => <Select.Option key={product} value={product}>{product}</Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item name="description" label="描述" rules={[{ required: true }]}>
                <Input.TextArea rows={4}/>
            </Form.Item>
            <Form.Item name="charges" label="服务费" rules={[{ required: true }]} initialValue={0}>
                <InputNumber/>
            </Form.Item>
        </Form>
    </Modal>;
}
