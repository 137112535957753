import { Dispatch } from "redux";
import { GET_ORDER, GET_ORDERS } from "../constants/fetch.constants";
import { Action } from "../action";
import { BILL } from "../constants/action-types.constants";
import { Client } from "../client";
import { OrderQuery } from "../interfaces";
import { getPagination } from "../helpers/pagination.helper";
import { PaginationAction } from "./pagination.action";

export class OrderAction {
    private static client = new Client();

    public static getOrders(params: OrderQuery) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const pagination = getPagination(state(), 'order', params.page, params.size);
            const { list, total, page, size } = await this.client.get('/api/orders', {
                params: {
                    ...params,
                    page: pagination.page,
                    size: pagination.size,
                },
                dispatch,
                fetch: GET_ORDERS,
            });
            dispatch(new Action(BILL).put('orders', list));
            dispatch(PaginationAction.update('order', page, size, total, list.length));
        }
    }

    public static getOrder(orderId: string) {
        return async (dispatch: Dispatch<any>, state: any) => {
            const { order } = await this.client.get(`/api/orders/${orderId}`, {
                dispatch,
                fetch: GET_ORDER,
            });
            console.log(order);
            dispatch(new Action(BILL).put('order', order));
        }
    }
}
