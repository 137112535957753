import * as React from 'react';

interface Props {
    roles: string[];
    role: string;
    children?: string | React.ReactNode;
}

export const RBAC = ({ roles, role, children }: Props) => {
    return <>
        {roles.includes(role) ? children : null}
    </>;
}
