import * as React from 'react';
import { Alert, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Fetch, State } from "../../redux/interfaces";
import { DELETE_REGION_MODAL } from "../../redux/constants/modal.constants";
import { ModalAction } from "../../redux/actions";
import { FetchAction, RegionAction } from "../../redux/actions";
import { DELETE_REGION } from "../../redux";

export const DeleteRegionModal = (props) => {
    const { visible, name, id } = useSelector<State, { [key: string]: any }>(
        state => state.modal[DELETE_REGION_MODAL] ?? {},
    );
    const { fetching, error, message } = useSelector<State, Fetch>(
        state => state.fetch[DELETE_REGION] ?? {} as any,
    );

    const errorMessage = error ? <Alert message={message} type="error" closable/> : null;
    const dispatch = useDispatch();

    const confirm = () => {
        dispatch(RegionAction.deleteRegion(id));
    }
    return <Modal
        title="删除区域"
        visible={visible}
        onOk={() => confirm()}
        confirmLoading={fetching}
        width={460}
        onCancel={() => {
            dispatch(ModalAction.close(DELETE_REGION_MODAL));
            dispatch(FetchAction.clear(DELETE_REGION));
        }}>
        {errorMessage}
        确认删除区域 {name}?
    </Modal>;
}
