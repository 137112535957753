import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Card, Descriptions, Input, InputNumber, Popconfirm, Select, Skeleton, Table } from 'antd';
import * as _ from 'lodash';
import { SubHeader } from '../../components/sub-header';
import { Link } from 'react-router-dom';
import { useMount } from 'react-use';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Fetch, GET_INSTANCE, InstanceAction, State } from '../../redux';
import { ProductType } from '../../enums/product-type.enum';
import { EditOutlined } from '@ant-design/icons';

const instanceStatus = [
    'normal',
    'creating',
    'create_error',
    'network_error',
    'upgrading',
    'modifying',
    'modify_error',
    'upgrade_error',
    'deleting',
    'delete_error',
    'deleted',
    'expired',
    'paused',
];
const instanceNetworkStatus = ['normal', 'error'];
const awsConfigs = ['arn', 'aws_account_id', 'aws_vpc_id'];

export const InstanceDetailContainer = (props) => {

    const instanceId = props.location.pathname.split('/')[3];
    const dispatch = useDispatch();
    const [bandwidth, updateBandwidth] = useState<number>(0);
    const [size, updateSize] = useState<number>(0);
    const [meta, updateMeta] = useState<string>('');
    const [status, updateStatus] = useState<string>('');
    const [networkStatus, updateNetworkStatus] = useState<string>('');

    const { instance } = useSelector<State, any>(
        state => state.instanceDetail ?? {} as any,
    );
    const { fetching } = useSelector<State, Fetch>(
        state => state.fetch[GET_INSTANCE] ?? {} as any,
    );

    const metas = instance?.metadata?.filter((item) => !awsConfigs.includes(item.key));
    const awsMetas = instance?.metadata?.filter((item) => awsConfigs.includes(item.key));

    useMount(() => {
        dispatch(InstanceAction.getInstance(instanceId));
    });
    useEffect(() => {
        if (instance?.items?.length !== 0) {
            instance?.items?.map((item) => {
                item.type.indexOf('size') === -1 ? updateBandwidth(item.amount as number) : updateSize(item.amount as number)
            });
            updateStatus(instance?.status);
        }
    }, [instance]);

    return <div>
        <SubHeader
            title={<Breadcrumb>
                <Breadcrumb.Item><Link to="/console/instances">实例管理</Link></Breadcrumb.Item>
                <Breadcrumb.Item>实例详情</Breadcrumb.Item>
            </Breadcrumb>}
        />
        <Card bodyStyle={{ padding: 20 }} style={{ marginTop: 20 }}>
            <Skeleton
                active={true}
                title={false}
                paragraph={{ rows: 6, width: [500, 500, 500, 500, 500, 500] }}
                loading={fetching}
            >
                <ul className="list">
                    <li><span className="title">文件系统名称</span><span>{instance?.name ?? '--'}</span></li>
                    <li><span className="title">文件系统ID</span><span>{instance?.id ?? '--'}</span></li>
                    <li><span className="title">文件系统类型</span><span>{ProductType[instance?.type] ?? '--'}</span></li>
                    <li><span className="title">inode数量</span><span>{instance?.extras?.inode_used ?? '--'}</span></li>
                    <li>
                        <span className="title">状态</span>
                        <span>{instance?.status}</span>
                        <Popconfirm icon={false} destroyTooltipOnHide={true}
                            title={() => {
                                return <div style={{ marginLeft: -14 }}>
                                    <p style={{ marginBottom: 6 }}>修改状态</p>
                                    <Select onChange={(value) => updateStatus(value)} defaultValue={instance?.status}>
                                        {instanceStatus.map((item) =>
                                            <Select.Option key={item} value={item}>{item}</Select.Option>)}
                                    </Select>
                                </div>
                            }}
                            onConfirm={() => {
                                dispatch(InstanceAction.updateInstance(instanceId, { status }));
                            }}
                        >
                            <Button type="link" icon={<EditOutlined />} />
                        </Popconfirm>
                    </li>
                    <li>
                        <span className="title">配置状态</span>
                        <span>{instance?.networkStatus}</span>
                        <Popconfirm icon={false} destroyTooltipOnHide={true}
                            title={() => {
                                return <div style={{ marginLeft: -14 }}>
                                    <p style={{ marginBottom: 6 }}>修改配置状态</p>
                                    <Select onChange={(value) => updateNetworkStatus(value)} defaultValue={instance?.networkStatus}>
                                        {instanceNetworkStatus.map((item) =>
                                            <Select.Option key={item} value={item}>{item}</Select.Option>)}
                                    </Select>
                                </div>
                            }}
                            onConfirm={() => dispatch(InstanceAction.updateInstance(instanceId, { networkStatus }))}
                        >
                            <Button type="link" icon={<EditOutlined />} />
                        </Popconfirm>
                    </li>
                    {
                        instance?.items?.map((item) => {
                            return <li>
                                <span className="title">{item.type.indexOf('size') === -1 ? '预置吞吐量' : '容量'}</span>
                                <span>{`${item.amount} ${item.unit}`}</span>
                                <span>
                                    <Popconfirm
                                        icon={false}
                                        destroyTooltipOnHide={true}
                                        title={() => {
                                            return <div style={{ marginLeft: -14 }}>
                                                <p style={{ marginBottom: 6 }}>{item.type.indexOf('size') === -1 ? '预置吞吐量' : '容量'}</p>
                                                <InputNumber
                                                    defaultValue={item.amount}
                                                    onChange={(value) => item.type.indexOf('size') === -1 ?
                                                        updateBandwidth(value as number) : updateSize(value as number)}
                                                />
                                            </div>
                                        }}
                                        onConfirm={() => {
                                            dispatch(InstanceAction.updateInstanceItem(
                                                instanceId,
                                                item.type,
                                                { amount: item.type.indexOf('size') === -1 ? bandwidth : size }
                                            ));
                                        }}
                                    >
                                        <Button
                                            type="link"
                                            icon={<EditOutlined />}
                                        />
                                    </Popconfirm>
                                </span>
                            </li>
                        })
                    }
                    {awsMetas?.map((item) => {
                        return <li>
                            <span className="title">{item.key}</span>
                            <span>{item.value}</span>
                            <Popconfirm
                                onVisibleChange={(visible) => {
                                    if (visible) {
                                        updateMeta(item.value);
                                    }
                                }}
                                icon={false}
                                destroyTooltipOnHide={true}
                                title={() => {
                                    return <div style={{ marginLeft: -14 }}>
                                        <p style={{ marginBottom: 6 }}>{item.key}</p>
                                        <Input
                                            defaultValue={item.value}
                                            onChange={(e) => updateMeta(e.target.value)}
                                        />
                                    </div>
                                }}
                                onConfirm={() => {
                                    dispatch(InstanceAction.updateInstanceMeta(instanceId, item.key, meta.trim()));
                                }}
                            >
                                <Button
                                    type="link"
                                    icon={<EditOutlined />}
                                />
                            </Popconfirm>
                        </li>
                    })}
                    {
                        instance?.billingMode === 'prepay' ?
                            <li>
                                <span className="title">到期时间</span>
                                <span>{instance?.expireTime && (moment(instance?.expireTime).format('YYYY-MM-DD') ?? '--')}</span>
                            </li> : null
                    }
                </ul>
            </Skeleton>
        </Card>
        {
            metas?.length === 0 ? null : <Descriptions title="metas" bordered style={{ marginTop: 24 }}>
                {
                    metas?.map((item) => {
                        return <Descriptions.Item label={item.key} key={item.key} style={{ backgroundColor: '#fff' }}>
                            <span>{item.value}</span>
                            <Popconfirm
                                onVisibleChange={(visible) => {
                                    if (visible) {
                                        updateMeta(item.value);
                                    }
                                }}
                                icon={false}
                                destroyTooltipOnHide={true}
                                title={() => {
                                    return <div style={{ marginLeft: -14 }}>
                                        <p style={{ marginBottom: 6 }}>{item.key}</p>
                                        <Input
                                            defaultValue={item.value}
                                            onChange={(e) => updateMeta(e.target.value)}
                                        />
                                    </div>
                                }}
                                onConfirm={() => {
                                    dispatch(InstanceAction.updateInstanceMeta(instanceId, item.key, meta.trim()));
                                }}
                            >
                                <Button
                                    type="link"
                                    icon={<EditOutlined />}
                                />
                            </Popconfirm>
                        </Descriptions.Item>
                    })
                }
            </Descriptions>
        }
    </div>
}
