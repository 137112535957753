import { useDispatch } from "react-redux";
import { useMount, useUnmount } from "react-use";
import { AuthAction, TicketAction } from "../redux";
import * as io from 'socket.io-client';
import { notification } from 'antd';

let socket;

export const ConsoleContainer = ({ children }) => {
    const dispatch = useDispatch();
    useMount(() => {
        dispatch(AuthAction.getCurrent());
        socketConnect();
    });
    useUnmount(() => {
        console.log('unmount')
        socket?.disconnect();
    });

    const socketConnect = () => {
        socket = io.connect('/events', { transports: ['websocket'], query: {} });
        socket.on('connect', () => {
            console.log('websocket connected');
        });
        socket.on('disconnect', () => {
            console.log('websocket disconnected');
        });
        socket.on('error', (e) => {
            console.log('websocket error: ', e.message);
        });
        socket.on('connect_error', (e) => {
            console.log('websocket connect error: ', e.message);
        });
        socket.on('message', (e) => {
            console.log(e);
            switch (e.type) {
                case "ticket_account_reply_message":
                    dispatch(TicketAction.refreshTicketReplies(e));
                    break;
            }
            if (e.content) {
                notification.info({ message: e.title ?? '系统消息', description: e.content });
            }
        });
    }
    return children;

    // const dispatch = useDispatch();
    // useMount(() => {
    //     const socket = io.connect('/events', { transports: ['websocket'], query: {} });
    //     socket.on('connect', () => {
    //         console.log('websocket connected!')
    //     });
    //     socket.on('disconnect', () => {
    //         console.log('websocket disconnected!')
    //     });
    //     socket.on('error', (e) => {
    //         console.log('websocket connect error', e.message);
    //     });
    //     socket.on('connect_error', (e) => {
    //         console.log('websocket connect error', e.message);
    //     });
        // socket.on('message', (e) => {
        //     console.log(e);
        //     switch (e.type) {
        //         case "ticket_account_reply_message":
        //             dispatch(TicketAction.refreshTicketReplies(e));
        //             break;
        //     }
        //     if (e.content) {
        //         notification.info({ message: e.title ?? '系统消息', description: e.content });
        //     }
        // });
    // });
    // return children;
}
